import React, { FC, useState } from "react";

import { useSelector } from "react-redux";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { TTypeOfAction, TTypeParticipants } from "@interfaces/meet.interface";
import { useSetParticipantStatusMutation } from "@services/meetApi";
import { selectCurrentDate } from "@store/screenDay";
import { userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { toFormatDate } from "@utils/toFormatTime";

import { AttendanceOptions } from "./components/AttendanceOptions";
import { CurrentAttendanceStatus } from "./components/currentAttendenceStatus/CurrentAttendanceStatus";
import { ModalComment } from "./components/ModalComment";
import { IAttendanceConfirmationProps, AttendanceStatuses } from "./types";

export const AttendanceConfirmation: FC<IAttendanceConfirmationProps> = ({
  currentUserAttendanceStatus,
  meetId,
  isSerieOrException,
  participantStatusComment,
  isSeries,
  parentId,
  isModal = true,
}) => {
  const [isStatusSetFailure, setIsStatusSetFailure] = useState(true);
  const [statusToSet, setStatusToSet] = useState<TTypeParticipants>("OWNER");
  const [typeAction, setTypeAction] = useState<TTypeOfAction>("ALL_SERIES");
  const [comment, setComment] = useState("");
  const [isVisibleComment, setisVisibleComment] = useState(false);

  const selectedDay = useSelector(selectCurrentDate);
  const { currentUser } = userSelector();
  const [setParticipantStatus] = useSetParticipantStatusMutation();

  const statusButtonPressHandler = (arg: TTypeParticipants) => {
    if (arg === "ATTENDER") {
      requestStatus("ATTENDER", "");
    } else {
      setStatusToSet(arg);
      setisVisibleComment(true);
    }
  };

  const requestStatus = async (status: TTypeParticipants, comment: string, type: TTypeOfAction = typeAction) => {
    const isSingleEvent = !type && !isSeries;
    const isSerieException = isSeries && type && type === "SINGLE_MEETING";
    const isSerie = type ? type === "ALL_SERIES" && isSeries : isSeries;

    const date = isSingleEvent || !isSerieException || isSerie ? null : toFormatDate(selectedDay);

    const res = await setParticipantStatus({
      id: parentId && type === "ALL_SERIES" ? parentId : meetId,
      status,
      date,
      comment,
      selectedDay: toFormatDate(selectedDay),
      participantId: currentUser?.id ?? "",
    });

    if (Object.prototype.hasOwnProperty.call(res, "data")) {
      setComment("");
      setIsStatusSetFailure(false);
    } else {
      setIsStatusSetFailure(true);
    }
  };

  return (
    <div>
      <RenderWithCondition condition={currentUserAttendanceStatus === null}>
        <StButtonGroupDiv isModal={isModal}>
          <AttendanceOptions onPress={statusButtonPressHandler} />
        </StButtonGroupDiv>
      </RenderWithCondition>

      <RenderWithCondition condition={currentUserAttendanceStatus !== AttendanceStatuses.OWNER && currentUserAttendanceStatus !== null}>
        <CurrentAttendanceStatus
          isSerieOrException={!!isSerieOrException}
          currentStatus={currentUserAttendanceStatus}
          onPress={requestStatus}
          isStatusSet={!isStatusSetFailure}
          typeAction={typeAction}
          setTypeAction={setTypeAction}
          participantStatusComment={participantStatusComment}
        />
      </RenderWithCondition>

      <ModalComment
        value={comment}
        setValue={setComment}
        onClose={() => setisVisibleComment(false)}
        isVisible={isVisibleComment}
        onSend={() => requestStatus(statusToSet, comment)}
      />
    </div>
  );
};

const StButtonGroupDiv = styled.div<{ isModal: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: ${(props) => (props.isModal ? "5px" : "25px")};
  gap: 12px;
  background-color: ${() => Colors.LIGHT.white};
  padding: 12px;
  border-radius: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  width: ${(props) => (props.isModal ? "calc(100% - 26px)" : "32.5%")};
`;
