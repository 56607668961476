import React, { memo } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgTakeBack } from "@assets/takeBack.svg";
import { RenderWithCondition } from "@hoc";
import { InterfaceUser } from "@interfaces";
import { ITask } from "@interfaces/businessGoals.interface";
import { useTakeMeTaskMutation } from "@services/taskApi";
import { DelegatedList } from "@shared";
import { Spacer } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";

interface IModalDelegate {
  close: () => void;
  task: ITask;
  onSave?: (arg: InterfaceUser.IStateUser) => void;
  delegatedToUser?: InterfaceUser.IStateUser | null;
  onTakeMe?: () => void;
  isNeedCloseAfterSave?: boolean;
}

export const ModalDelegate = memo(({ close, task, delegatedToUser, onSave, onTakeMe, isNeedCloseAfterSave = false }: IModalDelegate) => {
  const [takeMe] = useTakeMeTaskMutation();

  const handleTakeMe = () => {
    const takeMeDispatchWrapper = async () => {
      await takeMe({ id: task.id });
    };

    onTakeMe ? onTakeMe() : takeMeDispatchWrapper();
    close && close();
  };

  const renderName = (user: InterfaceUser.IStateUser | null | undefined) => `${user?.lastName} ${user?.firstName}`;

  return (
    <ModalUI isVisible={true} onClose={close}>
      <HeaderModal title="delegate" leftSide={{ isHideCancel: true, onPressClose: close }} />

      <RenderWithCondition condition={delegatedToUser}>
        <StContentWrapDiv>
          <TextFont type="bold" size={16} weight="700" color={Colors.LIGHT.white}>
            Делегировано на:
          </TextFont>
          <Spacer px={4} />
          <StBlockButtonsDiv>
            <StDelegateWrapDiv>
              <TextFont size={14}>{renderName(delegatedToUser)}</TextFont>
            </StDelegateWrapDiv>
            <StDelegateWrapDiv as="button" onClick={handleTakeMe}>
              <SvgTakeBack width={22} height={22} />
              <Spacer px={8} horizontal />
              <TextFont size={14} color={Colors.LIGHT.purple}>
                <FormattedMessage id="takeBack" />
              </TextFont>
            </StDelegateWrapDiv>
          </StBlockButtonsDiv>
        </StContentWrapDiv>
      </RenderWithCondition>

      <DelegatedList task={task} close={close} onSave={onSave} isNeedCloseAfterSave={isNeedCloseAfterSave} />
    </ModalUI>
  );
});

const StContentWrapDiv = styled.div`
  // StContentDiv
  display: flex;
  flex-direction: column;
  padding: 9px 12px 12px 12px;
  background-color: ${Colors.LIGHT.background.darkGreen};
`;
const StBlockButtonsDiv = styled.div`
  display: flex;
  margin-top: 8px;
  gap: 10px;
`;
const StDelegateWrapDiv = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  border-radius: 45px;
  background-color: ${Colors.LIGHT.white};
  padding: 3px 9px;
  height: 34px;
  box-sizing: border-box;
`;
