import React, { useMemo, useEffect, useRef } from "react";

import "intl";
import "intl/locale-data/jsonp/en";
import { isTablet, isIOS, isIPad13 } from "react-device-detect";
import { IntlProvider } from "react-intl";
import { Notifications } from "react-push-notification";
import { Provider, useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";

import { useAppStateChange } from "@hooks/useAppStateChange";
import { IColors } from "@interfaces/theme.interface";
import { AppRouter } from "@navigation/AppRouter";
import emitter from "@services/emitter";
import { checkAuth } from "@store/auth";
import { getSettings } from "@store/configure";
import { initTheme, themeSelector } from "@store/theme";
import { getManagers, getUnreadCountMessages, getUserInfo, initDirectorId, userSelector } from "@store/user";
import { clearMessages } from "@store/user/slice";
import { getPersonalSettings } from "@store/userSettings/api";
import { Colors } from "@theme/colors";
import { ErrorNetwork } from "@ui";
import { setFavicon } from "@utils/setFavicon";
import { LOCALES, messages } from "@utils/translate";

import { RootState, store } from "./store";

import "react-toastify/dist/ReactToastify.css";
import "./dayjs.config";

import "./pushNotification.config";

export default function App() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [locale, setLocale] = React.useState(LOCALES.RUSSIAN);

  setFavicon();

  return (
    <Provider store={store}>
      <IntlProvider messages={messages[locale]} locale={locale} defaultLocale={LOCALES.RUSSIAN}>
        <RenderApp />
      </IntlProvider>
    </Provider>
  );
}

const RenderApp = () => {
  const dispatch = useDispatch<any>();
  const { currentUser, directorId } = userSelector();

  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const { extra, main } = themeSelector();
  const isIPad = useMemo(() => (isTablet && isIOS) || isIPad13, [isTablet, isIOS, isIPad13]);
  const currentUserId = useRef("");
  const currentDirectorId = useRef("");

  useAppStateChange();

  useEffect(() => {
    dispatch(checkAuth());
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getPersonalSettings());
      dispatch(getUserInfo(directorId));
      dispatch(initTheme());
      dispatch(getManagers());
      dispatch(initDirectorId());
    }
  }, [isLoggedIn, directorId]);

  useEffect(() => {
    if (isLoggedIn) {
      setTimeout(() => emitter.emit("login"), 1000);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) return;

    const requestTimeoutId = setTimeout(() => dispatch(getUnreadCountMessages()), 1000);

    return () => {
      clearTimeout(requestTimeoutId);
    };
  }, [directorId, isLoggedIn]);

  useEffect(() => {
    emitter.emit("change_listener_user", directorId ? directorId : currentUser?.id, Boolean(directorId));

    currentUserId.current = currentUser?.id;
    currentDirectorId.current = directorId;

    dispatch(clearMessages());
  }, [directorId]);

  useEffect(() => {
    const handleWindowLoad = () => {
      emitter.emit(
        "change_listener_user",
        currentDirectorId.current ? currentDirectorId.current : currentUserId?.current,
        Boolean(currentDirectorId.current),
      );
    };

    window.addEventListener("load", handleWindowLoad);

    return () => window.removeEventListener("load", handleWindowLoad);
  }, []);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "]" && isIPad) {
        const modalOverlay = document.getElementsByClassName("ReactModal__Overlay");

        if (!modalOverlay.length) return;

        const clickEvent = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        });

        modalOverlay[0].dispatchEvent(clickEvent);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const colors: { main: IColors; extra: IColors } = { main: Colors[main], extra: Colors[extra] };

  return (
    <>
      <Notifications />
      <ErrorNetwork />
      <ThemeProvider theme={colors}>
        <AppRouter />
      </ThemeProvider>
      <ToastContainer style={{ width: "98%", bottom: "2em" }} />
    </>
  );
};
