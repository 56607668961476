import React, { useEffect, useMemo, useState, useRef } from "react";

import dayjs from "dayjs";
import { useDeviceData, useDeviceSelectors } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { NotificationClear } from "@components/ui/notification/NotificationClear";
import { DEVICE_ID } from "@constants/settings";
import { getCodeApi, loginApi } from "@store/auth";
import { RootState } from "@store/index";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui/TextFont";
import { secureStore } from "@utils/secureStore";

import version from "../../../appVersion.json";

import logo from "./assets/logo.png";

const initialTime = dayjs().minute(1).second(0);

const defaultInputValue = "";

export const CheckCode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deviceInfo = useDeviceData(window.navigator.userAgent);

  const [selectors] = useDeviceSelectors(window.navigator.userAgent);

  const { errorMsg, phoneNumber, isLoggedIn, isNoRegistered } = useSelector((state: RootState) => state.auth);

  const [isTimer, setIsTimer] = useState(true);
  const [time, setTime] = useState(initialTime);
  const [isVisible, setIsVisible] = useState(false);
  const [deviceId, setDeviceId] = useState("");

  const [inputFirst, setInputFirst] = useState(defaultInputValue);
  const [inputSecond, setInputSecond] = useState(defaultInputValue);
  const [inputThird, setInputThird] = useState(defaultInputValue);
  const [inputFourth, setInputFourth] = useState(defaultInputValue);

  const refFirst = useRef<HTMLInputElement>(null);
  const refSecond = useRef<HTMLInputElement>(null);
  const refThird = useRef<HTMLInputElement>(null);
  const refFourth = useRef<HTMLInputElement>(null);

  const typeOfDevice = useMemo(() => {
    if (selectors?.isTablet) {
      return "TABLET";
    }
    if (selectors?.isMobile) {
      return "MOBILE";
    }
    if (selectors?.isDesktop) {
      return "DESKTOP";
    }
    return null;
  }, [selectors]);

  useEffect(() => {
    try {
      const id = secureStore.getValue(DEVICE_ID);
      setDeviceId(id);
    } catch (error) {
      const newId = uuidv4();
      setDeviceId(newId);
      secureStore.saveValue(DEVICE_ID, newId);
    }
  }, []);

  useEffect(() => {
    if (isTimer) {
      setTime(initialTime);

      let tempTime = initialTime;
      const intervalTimer = setInterval(() => {
        if (tempTime.minute() === 0 && tempTime.second() === 0) {
          setIsTimer(false);
          clearInterval(intervalTimer);
        }

        tempTime = tempTime.second(tempTime.second() - 1);
        setTime(tempTime);
      }, 1000);
    }
  }, [isTimer]);

  useEffect(() => {
    if (inputFirst && inputSecond && inputThird && inputFourth) {
      const data = {
        phone_number: `${phoneNumber}`,
        sms_code: inputFirst + inputSecond + inputThird + inputFourth,
        grant_type: "sms_code",
        device: {
          device_id: deviceId,
          brand: deviceInfo.device.vendor,
          model: deviceInfo.device.model
            ? `${deviceInfo.device.model} ${deviceInfo.os.version}`
            : `${deviceInfo.browser.name} ${deviceInfo.browser.major}`,
          app_version: `Web ${version.versionName}`,
          operating_system: deviceInfo.os.name,
          device_type: typeOfDevice,
        },
      };

      dispatch(loginApi(data));
    }
  }, [inputFirst, inputSecond, inputThird, inputFourth]);

  useEffect(() => {
    if (isNoRegistered) {
      return navigate("/registration");
    }
    if (isLoggedIn) {
      return navigate("/");
    }
  }, [isLoggedIn, isNoRegistered]);

  const onChangeInputFirst = (value: string) => {
    if (value) {
      refSecond.current?.focus();
    }
    setInputFirst(value);
  };

  const onChangeInputSecond = (value: string) => {
    if (value) {
      refThird.current?.focus();
    } else {
      refFirst.current?.focus();
    }
    setInputSecond(value);
  };

  const onChangeInputThird = (value: string) => {
    if (value) {
      refFourth.current?.focus();
    } else {
      refSecond.current?.focus();
    }
    setInputThird(value);
  };

  const onChangeInputFourth = (value: string) => {
    if (!value) {
      refThird.current?.focus();
    }
    setInputFourth(value);
  };

  const handleRepeatCode = async () => {
    setIsTimer(true);
    await dispatch(getCodeApi(phoneNumber));
  };

  const renderText = useMemo(
    () => (
      <>
        <TextFont size={18} align="center">
          <FormattedMessage id="entercode_1" />
        </TextFont>
        <TextFont size={18} align="center">
          <FormattedMessage id="entercode_2" />
        </TextFont>
        <TextFont size={18} align="center">{`${phoneNumber}`}</TextFont>
      </>
    ),
    [phoneNumber],
  );

  return (
    <>
      <StContentDiv>
        <StLogoWrapDiv>
          <StImg src={logo} />
        </StLogoWrapDiv>
        <StTextWrapDiv>
          <StTextLineDiv>{renderText}</StTextLineDiv>
        </StTextWrapDiv>

        <StCodeLineDiv>
          <StInput
            autoFocus
            isError={!!errorMsg}
            maxLength={1}
            value={inputFirst}
            onChange={(e) => onChangeInputFirst(e.target.value)}
            ref={refFirst}
          />
          <StInput
            isError={!!errorMsg}
            maxLength={1}
            value={inputSecond}
            onChange={(e) => onChangeInputSecond(e.target.value)}
            ref={refSecond}
          />
          <StInput
            isError={!!errorMsg}
            maxLength={1}
            value={inputThird}
            onChange={(e) => onChangeInputThird(e.target.value)}
            ref={refThird}
          />
          <StInput
            isError={!!errorMsg}
            maxLength={1}
            value={inputFourth}
            onChange={(e) => onChangeInputFourth(e.target.value)}
            ref={refFourth}
          />
        </StCodeLineDiv>

        <RepeatBlock>
          <button onClick={handleRepeatCode} disabled={isTimer}>
            <TextFont size={18} color={isTimer ? Colors.LIGHT.grey : Colors.LIGHT.text.accent}>
              <FormattedMessage id="repeatCode" />
            </TextFont>
          </button>
          {isTimer ? (
            <TextFont size={18} color={Colors.LIGHT.grey}>
              <FormattedMessage
                id="through"
                description=""
                defaultMessage="через {time}"
                values={{
                  time: ` ${time.format("mm:ss")}`,
                }}
              />
            </TextFont>
          ) : (
            <button onClick={() => setIsVisible(true)}>
              <TextFont size={18} color={Colors.LIGHT.grey500}>
                <FormattedMessage id="smsDontGet" />
              </TextFont>
            </button>
          )}
        </RepeatBlock>
      </StContentDiv>
      {isVisible && <NotificationClear onOk={() => setIsVisible(false)} phraseId="authHelp" phraseOkId="ok" />}
    </>
  );
};

const StInput = styled.input<{ isError: boolean }>`
  text-align: center;
  font-size: 18px;
  border-radius: 10px;
  border: 1px solid ${Colors.LIGHT.lightGrey250};
  margin-right: 10px;
  padding: 16px;
  width: 56px;
  height: 56px;
  border-color: ${(props) => (props.isError ? Colors.LIGHT.red : Colors.LIGHT.placeholder.main)};
  color: ${(props) => (props.isError ? Colors.LIGHT.red : Colors.LIGHT.text.main)};
  @media screen and (max-width: 440px) {
    width: 24px;
    height: 24px;
  }
`;
const RepeatBlock = styled.div`
  display: flex;
  margin-bottom: 8px;
  @media screen and (max-width: 440px) {
    flex-direction: column;
    align-items: center;
  }
`;
const StContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${() => Colors.LIGHT.background.main};
`;
const StLogoWrapDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;
const StImg = styled.img`
  width: 140px;
`;
const StTextWrapDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
const StTextLineDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const StCodeLineDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;
