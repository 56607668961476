import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { isObject } from "lodash";

import { meetApi } from "@services/meetApi";
import { setCurrentEvent } from "@store/screenDay/slice";

const listenerMiddleware = createListenerMiddleware();

/* Слушаем все запросы getMeetingById.
 * При успешном запросе, обновляем currentEvent
 */
listenerMiddleware.startListening({
  matcher: isAnyOf(meetApi.endpoints.getMeetingById?.matchFulfilled),
  effect: async (action, listenerApi) => {
    if (isObject(action?.payload && !action.meta.arg.originalArgs?.ignoreListenerMiddleware)) {
      listenerApi.dispatch(setCurrentEvent(action.payload));
    }
  },
});

export default listenerMiddleware;
