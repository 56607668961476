import React, { FC, useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { TOneSTaskResolutions } from "@interfaces/businessGoals.interface";
import { useSetOnesTaskResolutionMutation } from "@services/taskApi";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import { AddResolutionCommentModal } from "./AddResolutionCommentModal";
import { OneSResolutions as ButtonTypes, IResoutionButton, OneSResolutions } from "./constants";
import { generateButtonDescriptionsAsset } from "./utils";

interface IProps {
  taskId: string;
  availableResulutions: TOneSTaskResolutions[];
  oneSTaskResolution: TOneSTaskResolutions | null;
}

export const Resolution1CActions: FC<IProps> = ({ taskId, availableResulutions, oneSTaskResolution }) => {
  const [isCommentModalVisible, setIsCommentVisible] = useState(false);
  const [pressedButton, setPressedButton] = useState<IResoutionButton | null>(null);
  const [isButtonsVisible, setIsButtonsVisible] = useState(false);

  const [setOnesTaskResolution, { isError, isLoading }] = useSetOnesTaskResolutionMutation();

  useEffect(() => {
    if (isError) {
      setIsButtonsVisible(true);
    } else {
      setIsButtonsVisible(false);
    }
  }, [isError]);

  useEffect(() => {
    setIsButtonsVisible(!oneSTaskResolution);
  }, []);

  const handleTaskResolution = async (resolution: OneSResolutions) => {
    await setOnesTaskResolution({ id: taskId, resolution });
  };

  const handleResolutionPress = async (button: IResoutionButton) => {
    switch (button.type) {
      case ButtonTypes.COMPLETE_AGREEMENT:
      case ButtonTypes.REGISTER:
      case ButtonTypes.COMPLETE_REGISTRATION:
      case ButtonTypes.CONFIRMED:
      case ButtonTypes.NOT_CONFIRMED:
      case ButtonTypes.SIGN:
      case ButtonTypes.REJECT:
      case ButtonTypes.COMPLETE_CONFIRMATION:
      case ButtonTypes.COMPLETE_SIGNATURE:
        handleTaskResolution(button.type);
        break;
      case ButtonTypes.AGREED:
      case ButtonTypes.EXECUTED:
      case ButtonTypes.AGREED_WITH_REMARKS:
      case ButtonTypes.NOT_AGREED:
      case ButtonTypes.REPEAT_AGREEMENT:
      case ButtonTypes.DO_NOT_REGISTER:
      case ButtonTypes.REPEAT_REGISTRATION:
      case ButtonTypes.REPEAT_CONFIRMATION:
      case ButtonTypes.REPEAT_SIGNATURE:
      case ButtonTypes.RETURN:
      case ButtonTypes.COMPLETE:
      case ButtonTypes.FAMILIARIZED:
        setPressedButton(button);
        setIsCommentVisible(true);
        break;
      default:
        break;
    }
  };

  const generateButtons = () => {
    const buttonsAsset = generateButtonDescriptionsAsset(availableResulutions);

    return (
      <StContentWrapDiv>
        {buttonsAsset &&
          buttonsAsset.map((button, index) => {
            const isFullWidthBtn = index === 0 && (buttonsAsset.length === 1 || buttonsAsset.length === 3);
            const isSingleBtn = buttonsAsset.length === 1;

            return (
              <StButtonResolutionBtn
                isAccent={button.isAccent}
                isFullWidthBtn={isFullWidthBtn}
                isSingleBtn={isSingleBtn}
                onClick={() => handleResolutionPress(button)}
                key={button.type}
                disabled={isLoading}
              >
                <TextFont
                  size={16}
                  align="center"
                  color={button.isAccent ? Colors.LIGHT.white : Colors.LIGHT.text.main}
                  weight={button.isAccent ? "700" : "400"}
                >
                  <FormattedMessage id={button.textId} defaultMessage={button.defaultMessage} />
                </TextFont>
              </StButtonResolutionBtn>
            );
          })}
      </StContentWrapDiv>
    );
  };

  return (
    <>
      <RenderWithCondition condition={isButtonsVisible}>
        <>{generateButtons()}</>
      </RenderWithCondition>
      <ModalRenderWithCondition condition={isCommentModalVisible}>
        <AddResolutionCommentModal
          isVisible={isCommentModalVisible}
          setIsVisible={setIsCommentVisible}
          buttonMeta={pressedButton}
          taskId={taskId}
        />
      </ModalRenderWithCondition>
    </>
  );
};

const StContentWrapDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const StButtonResolutionBtn = styled.button<{ isAccent: boolean; isFullWidthBtn: boolean; isSingleBtn: boolean }>`
  width: 49%;
  margin-bottom: 10px;
  padding: 10px 5px;
  min-height: 44px;
  border-radius: 9px;
  background-color: ${Colors.LIGHT.lighGrey};
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isAccent &&
    `
  background-color: ${Colors.LIGHT.background.green};`}
  ${(props) =>
    props.isFullWidthBtn &&
    `
  width: 100%;
  `}
  ${(props) =>
    props.isSingleBtn &&
    `
  height: 56px;
  `}
`;
