import React, { FC, memo, useMemo, useEffect } from "react";

import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as CloseIcon } from "@assets/close-thin.svg";
import { ReactComponent as DocumentAttachIcon } from "@assets/document-attach.svg";
import { regExpFileType } from "@constants/regExp";
import { LoaderRenderWithCondition, RenderWithCondition } from "@hoc";
import { IFile } from "@interfaces/files.interfaces";
import { modalStyles } from "@screens/day/components/ListTasks/Modal";
import { CommonFields } from "@screens/day/components/ListTasks/Modal/components/ViewNotSave/components/types";
import { fileService } from "@services/file.service";
import { selectFileAttachmentsStore } from "@store/fileAttachments/selectors";
import { commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont, Loader } from "@ui";
import { arePropsEqual } from "@utils/arePropsEqual";
import { convertFileTypeToBlob, TFileExtension } from "@utils/fileTypeToBlob";

interface IFileAttachments extends Partial<CommonFields> {
  allowFilePick?: boolean;
  allowFileDelete?: boolean;
  uploadedFiles: IFile[];
  allFiles: IFile[];
  enableAddButton?: boolean;
  fileIdsExtractor?: (value: string[]) => void;
  onlyButton?: boolean;
  closeOverModal?: () => void;
  disable?: { value: boolean; press: () => void };
  isSaveCalled?: boolean;
  openFilePicker: () => void;
  deleteFile: (id: string) => void;
  handleFileDownload: (id: string, fileName: string) => void;
  handleFileChange: (files: FileList) => void;
}

interface IFileListItem {
  name: string | null;
  allowDeletion?: boolean;
  onDelete: () => void;
  onPress: () => void;
}

const FileListItem: FC<IFileListItem> = ({ name, allowDeletion, onDelete, onPress }) => (
  <button style={commonStyles.billet}>
    <StFileItemPressableDiv onClick={onPress}>
      <DocumentAttachIcon />
      <StFileItemNameContainerDiv>
        <TextFont>{name ?? "<нет имени>"}</TextFont>
      </StFileItemNameContainerDiv>
    </StFileItemPressableDiv>
    {allowDeletion && (
      <button onClick={onDelete}>
        <CloseIcon />
      </button>
    )}
  </button>
);

export const FileAttachments: FC<IFileAttachments> = memo(function FileAttachments({
  handleData,
  fileIdsExtractor,
  allowFilePick,
  allowFileDelete,
  onlyButton = false,
  enableAddButton = true,
  disable,
  openFilePicker,
  allFiles = [],
  uploadedFiles,
  handleFileDownload,
  deleteFile,
  handleFileChange,
}) {
  const [dragActive, setDragActive] = React.useState(false);

  const { isLoading, errorMsg } = useSelector(selectFileAttachmentsStore);

  useEffect(() => {
    fileIdsExtractor && fileIdsExtractor(uploadedFiles.map((file) => file.id));

    if (handleData && allFiles.length) {
      handleData(
        "fileIds",
        allFiles.map((file) => file.id),
      );
    } else {
      handleData && handleData("fileIds", []);
    }
  }, [allFiles, uploadedFiles]);

  const onPress = () => {
    if (disable?.value) {
      return disable.press();
    }

    return openFilePicker();
  };

  const onPressFile = async (id: string, name: string) => {
    const matches = name.match(regExpFileType);

    if (matches) {
      const fileType = matches[0].toLowerCase() as TFileExtension;
      const blobType = convertFileTypeToBlob(fileType);
      return fileService.viewFile(id, blobType);
    }
    return handleFileDownload(id, name);
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement> | React.DragEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileChange(e.dataTransfer.files);
    }
  };

  const isInitialLoaderVisible = useMemo(
    () => Boolean(!allFiles?.length) && isLoading && !errorMsg && !onlyButton,
    [allFiles, isLoading, errorMsg, onlyButton],
  );

  const renderForm = (forList: boolean) => (
    <StUploadForm id="form-file-upload" onDragEnter={handleDrag}>
      <input
        type="file"
        onClick={(e) => {
          e.preventDefault();
          forList ? onPress() : openFilePicker();
        }}
        id="input-file-upload"
        multiple={true}
        style={{ display: "none" }}
      />
      <StFormUploadLabel
        dragActive={dragActive}
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        <div style={{ pointerEvents: "none" }}>
          <p style={{ fontSize: 14 }}>Перетащите сюда файл или нажмите на область</p>
        </div>
      </StFormUploadLabel>
      {dragActive && (
        <StDragFileDiv
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></StDragFileDiv>
      )}
    </StUploadForm>
  );

  return (
    <>
      <RenderWithCondition condition={Boolean(allFiles.length) || enableAddButton}>
        <div style={{ ...(isInitialLoaderVisible && { minHeight: "50px" }) }}>
          <LoaderRenderWithCondition condition={isInitialLoaderVisible}>
            <>
              {Boolean(allFiles.length) && !onlyButton ? (
                <StFileAttachmentsContainerDiv>
                  <TextFont style={modalStyles.mainH1}>Прикрепленные файлы</TextFont>
                  <>
                    {allFiles.map((item) => (
                      <FileListItem
                        key={item.id}
                        name={item.fileName}
                        onDelete={() => deleteFile(item.id)}
                        onPress={() => onPressFile(item.id, item.fileName)}
                        allowDeletion={allowFileDelete}
                      />
                    ))}
                  </>
                  <RenderWithCondition condition={isLoading && !errorMsg}>
                    <div style={{ flex: 1 }}>
                      <Loader />
                    </div>
                  </RenderWithCondition>

                  <RenderWithCondition condition={allowFilePick}>{renderForm(true)}</RenderWithCondition>
                </StFileAttachmentsContainerDiv>
              ) : (
                enableAddButton && <>{renderForm(false)}</>
              )}
            </>
          </LoaderRenderWithCondition>
        </div>
      </RenderWithCondition>
    </>
  );
},
arePropsEqual);

const StFileAttachmentsContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StFileItemPressableDiv = styled.div`
  display: flex;
  width: 90%;
`;
const StFileItemNameContainerDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-self: center;
  padding-left: 10px;
  width: 100%;
`;

const StUploadForm = styled.form`
  height: 5rem;
  max-width: 100%;
  text-align: center;
  position: relative;
  margin-top: 20px;

  :hover {
    color: ${(props) => props.theme.extra.text.main};
    cursor: pointer;
  }
  :active {
    box-shadow: 5px 5px 6px -5px rgba(34, 60, 80, 0.6) inset;
  }
`;

const StFormUploadLabel = styled.label<{ dragActive: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: ${(props) => props.theme.extra.background.green};
  background-color: ${(props) => (props.dragActive ? Colors.LIGHT.white : props.theme.extra.green100)};
  color: ${(props) => (props.dragActive ? props.theme.extra.text.main : Colors.LIGHT.placeholder.main)};
`;

const StDragFileDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
