import styled from "styled-components";

import { TextFont } from "@components/ui";
import { Colors } from "@theme/colors";

export const StHeaderPlaceDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 6px;
`;
export const StListRoomsDiv = styled.div`
  margin-top: 13px;
`;
export const StButtonItemBtn = styled.button`
  background-color: ${Colors.LIGHT.white};
  padding: 12px;
  border-bottom: 1px solid ${Colors.LIGHT.lighGrey};
  outline: none;
  width: 100%;
`;

export const StTextTitlePlaceTF = styled(TextFont)`
  flex: 0.8;
  height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
`;
export const StLineItemsDiv = styled.div`
  display: flex;
  gap: 24px;
`;
export const StLineIconsDiv = styled.div`
  display: flex;
  flex: 0.2;
  gap: 18px;
  align-items: center;
  justify-content: flex-end;
`;
export const StLinksWrapDiv = styled.div`
  display: flex;
  gap: 24px;
`;
export const StLinkItemA = styled.a`
  flex: 0.8;
  font-size: 16px;
  color: ${Colors.LIGHT.text.accent};
  text-align: left;
  line-height: 24px;
  height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
`;
export const StEmptyElementDiv = styled.div`
  flex: 0.2;
`;
