import { createAsyncThunk } from "@reduxjs/toolkit";

import { fileService } from "@services/file.service";
import { getError } from "@store/getError";

export const getFileInfo: any = createAsyncThunk("fileAttachments/getFileInfo", async (data: string, thunkAPI) => {
  try {
    const response = await fileService.getFileInfo(data);
    // @ts-ignore
    return response.data;
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const uploadFile: any = createAsyncThunk("fileAttachments/upload", async (data: FormData, thunkAPI) => {
  try {
    return await fileService.upload(data);
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});
