// @ts-nocheck
import { ICategory } from "../types";

export const defaultCategories: ICategory[] = [
  {
    id: "1",
    description: "Оранжевая категория",
    name: "orange",
    color: "#F09627",
  },
  {
    id: "2",
    description: "Бирюзовая категория",
    name: "blue",
    color: "#5AB8B1",
  },
  {
    id: "3",
    description: "Розовая категория",
    name: "pink",
    color: "#E3848A",
  },
  {
    id: "4",
    description: "Лиловая категория",
    name: "violet",
    color: "#8264FB",
  },
  {
    id: "5",
    description: "Оливковая категория",
    name: "olive",
    color: "#A1B66C",
  },
];

export const COLORS: ICategory[] = [
  {
    name: "pink",
    color: "#E3848A",
    id: "",
    description: "",
  },
  {
    name: "orange",
    color: "#F09627",
    id: "",
    description: "",
  },
  {
    name: "blue",
    color: "#5AB8B1",
    id: "",
    description: "",
  },
  {
    name: "olive",
    color: "#A1B66C",
    id: "",
    description: "",
  },
  {
    name: "lilac",
    color: "#8264FB",
    id: "",
    description: "",
  },
  {
    name: "bluegreen",
    color: "#316167",
    id: "",
    description: "",
  },
  {
    name: "gray",
    color: "#666666",
    id: "",
    description: "",
  },
  {
    name: "red",
    color: "#861F1D",
    id: "",
    description: "",
  },
  {
    name: "brown",
    color: "#92723B",
    id: "",
    description: "",
  },
  {
    name: "purple",
    color: "#792A5C",
    id: "",
    description: "",
  },
];
