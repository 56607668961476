import React, { FC, memo, useState } from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgHeavyPlus } from "@assets/plus-heavy.svg";
import { RenderWithCondition } from "@hoc";
import { IExtendedComment } from "@interfaces/businessGoals.interface";
import { useAddMeetingCommentMutation, useEditMeetingCommentMutation } from "@services/meetApi";
import { useAddTaskCommentMutation, useEditTaskCommentMutation } from "@services/taskApi";
import { TextFont } from "@ui";

import { CommentEditorModal } from "./CommentEditorModals";
import { CommentsList } from "./CommentsList";

export enum EventCommentsTypes {
  Task = "task",
  Meeting = "meeting",
}

interface IProps {
  comments: IExtendedComment[];
  eventId: string;
  eventType: EventCommentsTypes;
  isPersonalTask?: boolean;
  allowCommentAddition?: boolean;
}

export const EventComments: FC<IProps> = memo(function EventComments({
  comments,
  eventType,
  eventId,
  isPersonalTask = false,
  allowCommentAddition = true,
}) {
  const [isVisibleCommentEditor, setIsVisibleCommentEditor] = useState(false);
  const [editCommentId, setEditCommentId] = useState<string>();
  const [editCommentText, setEditCommentText] = useState<string>();

  const [addMeetingComment] = useAddMeetingCommentMutation();
  const [editMeetingComment] = useEditMeetingCommentMutation();
  const [addTaskComment] = useAddTaskCommentMutation();
  const [editTaskComment] = useEditTaskCommentMutation();

  const handlePressSaveComment = (comment: string, commentId?: string) => {
    if (!eventId || !comment) return;

    if (eventType === EventCommentsTypes.Task) {
      if (commentId) {
        editTaskComment({ id: eventId, commentId, comment, isPersonalTask });
      } else {
        addTaskComment({ id: eventId, comment, isPersonalTask });
      }
    } else {
      if (commentId) {
        editMeetingComment({ eventId, commentId, comment });
      } else {
        addMeetingComment({ eventId, comment });
      }
    }
  };

  const handleEditOrSave = (commentId: string, comment: string) => {
    setEditCommentId(commentId);
    setEditCommentText(comment);
    setIsVisibleCommentEditor(true);
  };

  return (
    <>
      <div>
        <div style={styles.header}>
          <TextFont size={18} style={{ fontWeight: "bold" }}>
            <FormattedMessage id={"comments"} />
          </TextFont>
          <RenderWithCondition condition={allowCommentAddition}>
            <div onClick={() => handleEditOrSave("", "")} style={styles.addCommentBtn}>
              <SvgHeavyPlus />
            </div>
          </RenderWithCondition>
        </div>
        <CommentsList
          comments={comments ?? []}
          eventType={eventType}
          isPersonalTask={isPersonalTask}
          eventId={eventId}
          handleEdit={handleEditOrSave}
        />
      </div>

      {isVisibleCommentEditor && (
        <CommentEditorModal
          isVisible={isVisibleCommentEditor}
          setIsVisible={setIsVisibleCommentEditor}
          eventType={eventType}
          onSave={handlePressSaveComment}
          editCommentId={editCommentId}
          editCommentText={editCommentText}
        />
      )}
    </>
  );
});

const styles = {
  header: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  addCommentBtn: {
    cursor: "pointer",
  },
};
