import dayjs, { Dayjs } from "dayjs";

import { Times } from "@constants/calendar";
import { ITask } from "@interfaces/businessGoals.interface";
import { IFilter, IGroupedTasks, TSingleSections } from "@interfaces/businessTask.interface";
import { IPersonalTask } from "@interfaces/personalTasks.interface";
import { TypeReason } from "@screens/day/components/ListTasks/Modal/components/viewSave/assets/constants";
import { OneSResolutions } from "@screens/day/components/ListTasks/Modal/components/viewSave/components";
import { TaskSubtypes } from "@store/businessTask";
import { afterUpdateTask, afterUpdateSingleSection } from "@store/businessTask/utils";
import { store } from "@store/store";
import { showToast } from "@utils/toast";
import { toFormatDate } from "@utils/toFormatTime";
import { intl } from "@utils/translate";

import { api, StoreTagTypes } from "./api";

export interface ITaskListParams {
  date: string;
  total?: number;
  current: number;
  next: number;
  type?: TSingleSections;
}

interface IUpdatePositionParams {
  date: string;
  data: { id: string; position: number }[];
  result: { objectKey: string; resultArray: ITask[] };
  isPersonalTask: boolean;
}

export interface ISingleSectionResponse {
  transferred: { content: ITask[]; total: number };
  delegated: { content: ITask[]; total: number };
  takenBack: { content: ITask[]; total: number };
}

type TTaskListResponse = ISingleSectionResponse & IGroupedTasks;

export interface IAllTaskListResponse {
  filter: IFilter;
  page: {
    content: ITask[];
    total: number;
  };
}

interface ICloseParams {
  id: string;
  comment: string;
  reason: TypeReason;
}

export interface IStatisticResponse {
  totalTasksAmount: number;
  completedTasksAmount: number;
  personalReasonTasksAmount: number;
  externalReasonTasksAmount: number;
  unplannedTasksAmount: number;
}

const getService = (isPersonalTask: boolean) => (isPersonalTask ? "personal-tasks" : "business-tasks");

export const taskApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTaskById: build.query<ITask, { id: string; isPersonalTask: boolean }>({
      query: ({ id, isPersonalTask }) => {
        const url = `/api/api-gateway/v1/${getService(isPersonalTask)}/${id}`;

        return {
          url,
          method: "get",
        };
      },
      providesTags: (_result, _error, arg) => [{ type: StoreTagTypes.Task, id: arg.id }],
      keepUnusedDataFor: Times.Mins_5,
    }),
    getTaskList: build.query<{ tasks: ITask[]; singleSections: ISingleSectionResponse }, { params: ITaskListParams }>({
      query: ({ params }) => {
        const url = `/api/api-gateway/v1/common-tasks/grouped-list?${Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`;

        return {
          url,
          method: "get",
        };
      },
      transformResponse: (response: TTaskListResponse) => ({
        tasks: [...(response?.urgent ?? []), ...(response?.important ?? []), ...(response?.contact ?? [])] as unknown as ITask[],
        singleSections: {
          transferred: {
            content: response?.transferred?.content ?? [],
            total: response?.transferred?.total ?? 0,
          },
          delegated: {
            content: response?.delegated?.content ?? [],
            total: response?.delegated?.total ?? 0,
          },
          takenBack: {
            content: response?.takenBack?.content ?? [],
            total: response?.takenBack?.total ?? 0,
          },
        },
      }),
      keepUnusedDataFor: Times.Mins_5,
      providesTags: () => [{ type: StoreTagTypes.Task, id: "TASK_LIST" }],
    }),
    getAllTaskList: build.query<IAllTaskListResponse, { filter: IFilter }>({
      query: ({ filter }) => ({
        url: "/api/api-gateway/v1/common-tasks/view/all-tasks",
        data: filter,
        method: "post",
      }),
      providesTags: () => [{ type: StoreTagTypes.Task, id: "ALL_TASK_LIST" }],
    }),
    pagingAllTaskList: build.mutation<IAllTaskListResponse, { filter: IFilter }>({
      query: ({ filter }) => ({
        url: "/api/api-gateway/v1/common-tasks/view/all-tasks",
        data: filter,
        method: "post",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;

          dispatch(
            taskApi.util.updateQueryData(
              "getAllTaskList",
              { filter: store.getState().businessTasks.filter },
              (draft: IAllTaskListResponse) => {
                draft.page.content.push(...response.page.content);
              },
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    getListForGoal: build.query<ITask[], { goalId: string }>({
      query: ({ goalId }) => {
        const url = `/api/crud/v1/business-tasks/list?businessGoalId=${goalId}`;

        return {
          url,
          method: "post",
        };
      },
      keepUnusedDataFor: Times.Mins_5,
    }),
    updateTaskPosition: build.mutation<void, IUpdatePositionParams>({
      query: ({ date, data, isPersonalTask }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/list/${date}`,
        data,
        method: "post",
      }),
      async onQueryStarted({ result }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          taskApi.util.updateQueryData(
            "getTaskList",
            { params: { date: toFormatDate(store.getState().screenDay.date), current: 0, next: 15 } },
            (draft: { tasks: ITask[]; singleSections: ISingleSectionResponse }) => {
              draft.tasks[result.objectKey] = result.resultArray;
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patchResult.undo();
        }
      },
    }),
    createTask: build.mutation<
      ITask,
      {
        body: ITask | IPersonalTask;
        isAdd?: boolean;
        isPersonalTask: boolean;
        type: "" | "unplanned" | "control";
      }
    >({
      query: ({ body, isPersonalTask, type }) => ({
        url: `/api/api-gateway/v1/${getService(isPersonalTask)}${type ? `/${type}` : ""}`,
        data: body,
        method: "post",
      }),
      async onQueryStarted({ isAdd, type, body, isPersonalTask }, { dispatch, queryFulfilled }) {
        const { data: response } = await queryFulfilled;

        try {
          const task = {
            ...response,
            ...(!type && { subType: isPersonalTask ? "PERSONAL" : "BUSINESS" }),
          } as ITask;

          dispatch(
            taskApi.util.updateQueryData(
              "getTaskList",
              { params: { date: toFormatDate(store.getState().screenDay.date), current: 0, next: 15 } },
              (draft: { tasks: ITask[]; singleSections: ISingleSectionResponse }) => {
                switch (type) {
                  case "": {
                    if (isAdd && draft.tasks.every((i) => i.id !== task.id)) {
                      draft.tasks = [task, ...draft.tasks];
                    }
                    break;
                  }
                  case "unplanned": {
                    draft.tasks = [task, ...draft.tasks];
                    break;
                  }
                  case "control": {
                    if (toFormatDate(dayjs()) === toFormatDate(dayjs(task?.workDays[0]?.date))) {
                      draft.tasks = [task, ...draft.tasks];
                    }
                  }
                }
              },
            ),
          );

          dispatch(
            taskApi.util.updateQueryData(
              "getAllTaskList",
              { filter: store.getState().businessTasks.filter },
              (draft: IAllTaskListResponse) => {
                draft.page.content = [task, ...draft.page.content];
              },
            ),
          );

          if (body.workDays) {
            dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));
          }
        } catch (e) {
          console.error(e);
        }
      },
    }),
    updateTask: build.mutation<ITask, { id: string; isPersonalTask: boolean; data: Partial<ITask> }>({
      query: ({ isPersonalTask, data, id }) => ({
        url: `/api/api-gateway/v1/${getService(isPersonalTask)}/${id}`,
        data,
        method: "patch",
      }),
      async onQueryStarted({ id, isPersonalTask, data }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;

          dispatch(taskApi.util.updateQueryData("getTaskById", { id, isPersonalTask }, () => response));

          if (data.workDays) {
            dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));
          }
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: [
        { type: StoreTagTypes.Task, id: "TASK_LIST" },
        { type: StoreTagTypes.Task, id: "ALL_TASK_LIST" },
      ],
    }),
    deleteTask: build.mutation<void, { id: string; isPersonalTask: boolean }>({
      query: ({ isPersonalTask, id }) => ({
        url: isPersonalTask ? `/api/crud/v1/personal-tasks/${id}` : `/api/api-gateway/v1/business-tasks/v1/business-tasks/${id}`,
        method: "delete",
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            taskApi.util.updateQueryData(
              "getTaskList",
              { params: { date: toFormatDate(store.getState().screenDay.date), current: 0, next: 15 } },
              (draft: { tasks: ITask[]; singleSections: ISingleSectionResponse }) => {
                draft.tasks = [...draft.tasks].filter((item) => item.id !== id);
              },
            ),
          );

          dispatch(
            taskApi.util.updateQueryData(
              "getAllTaskList",
              { filter: store.getState().businessTasks.filter },
              (draft: IAllTaskListResponse) => {
                if (draft.page.content.findIndex((i) => i.id === id) !== -1) {
                  draft.page = {
                    content: [...draft.page.content].filter((item) => item.id !== id),
                    total: draft.page.total - 1,
                  };
                }
              },
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: [{ type: StoreTagTypes.Meet, id: "LIST" }],
    }),
    completeTask: build.mutation<void, { id: string; isPersonalTask: boolean }>({
      query: ({ isPersonalTask, id }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/complete`,
        method: "post",
        meta: { notifyText: "missionCompleted" },
      }),
      async onQueryStarted({ id, isPersonalTask }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await afterUpdateTask(id, dispatch, isPersonalTask, "all");
          afterUpdateSingleSection(id, dispatch);
        } catch (error) {
          console.error(error);
        }
      },
    }),
    closeTask: build.mutation<void, { params: ICloseParams; isPersonalTask: boolean }>({
      query: ({ isPersonalTask, params: { comment, reason, id } }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/close`,
        data: {
          comment,
          reason,
        },
        method: "post",
      }),
      async onQueryStarted({ params, isPersonalTask }, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          taskApi.util.updateQueryData(
            "getTaskList",
            { params: { date: toFormatDate(store.getState().screenDay.date), current: 0, next: 15 } },
            (draft: { tasks: ITask[]; singleSections: ISingleSectionResponse }) => {
              const task = draft.tasks.filter((i) => i.id === params.id)[0];

              if (task && task.workDays.every((i) => i.status !== "COMPLETED")) {
                draft.tasks = draft.tasks.filter((i) => i.id !== params.id);
              } else {
                draft.tasks = draft.tasks.map((i) => {
                  if (params.id === i.id) {
                    return { ...i, status: "CLOSED", closeComment: params.comment, closeReason: params.reason };
                  }
                  return i;
                });
              }
            },
          ),
        );
        await afterUpdateTask(params.id, dispatch, isPersonalTask, "allTask", {
          task: { status: "CLOSED", closeComment: params.comment, closeReason: params.reason },
        });
        afterUpdateSingleSection(params.id, dispatch);
      },
      invalidatesTags: [{ type: StoreTagTypes.Meet, id: "LIST" }],
    }),
    reopenTask: build.mutation<void, { id: string; isPersonalTask: boolean }>({
      query: ({ isPersonalTask, id }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/reopen`,
        method: "post",
      }),
      async onQueryStarted({ id, isPersonalTask }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        await afterUpdateTask(id, dispatch, isPersonalTask, "all", { task: { status: "OPEN" } });
      },
      invalidatesTags: [{ type: StoreTagTypes.Meet, id: "LIST" }],
    }),
    pauseTask: build.mutation<
      void,
      { id: string; isStatusWorkDay: boolean; isDeadline: boolean; comment: string; isPersonalTask: boolean }
    >({
      query: ({ isPersonalTask, id, comment, isDeadline }) => ({
        url: `/crud/v1/${getService(isPersonalTask)}/${id}/pause`,
        data: { comment },
        method: "post",
        meta: isDeadline ? { notifyText: "deadlineDeleted" } : undefined,
      }),
      async onQueryStarted({ id, isPersonalTask, isStatusWorkDay }, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          taskApi.util.updateQueryData(
            "getTaskList",
            { params: { date: toFormatDate(store.getState().screenDay.date), current: 0, next: 15 } },
            (draft: { tasks: ITask[]; singleSections: ISingleSectionResponse }) => {
              if (isStatusWorkDay) {
                draft.tasks = draft.tasks.map((i) => {
                  if (id === i.id) {
                    return { ...i, status: "PAUSED" };
                  }
                  return i;
                });
              } else {
                draft.tasks = draft.tasks.filter((i) => i.id !== id);
              }
            },
          ),
        );

        await afterUpdateTask(id, dispatch, isPersonalTask, "allTask", { task: { status: "PAUSED" } });
      },
      invalidatesTags: [{ type: StoreTagTypes.Meet, id: "LIST" }],
    }),
    cloneTask: build.mutation<ITask, { id: string; isPersonalTask: boolean }>({
      query: ({ isPersonalTask, id }) => ({
        url: isPersonalTask ? `/api/crud/v1/personal-tasks/${id}/clone` : `/api/api-gateway/v1/business-tasks/${id}/clone`,
        method: "post",
        meta: { notifyText: "taskCopied" },
      }),
      async onQueryStarted({ isPersonalTask }, { dispatch, queryFulfilled }) {
        const { data: response } = await queryFulfilled;
        if (isPersonalTask) response.subType = TaskSubtypes.Personal;

        dispatch(
          taskApi.util.updateQueryData(
            "getTaskList",
            { params: { date: toFormatDate(store.getState().screenDay.date), current: 0, next: 15 } },
            (draft: { tasks: ITask[]; singleSections: ISingleSectionResponse }) => {
              draft.tasks = [response, ...draft.tasks];
            },
          ),
        );
        dispatch(
          taskApi.util.updateQueryData(
            "getAllTaskList",
            { filter: store.getState().businessTasks.filter },
            (draft: IAllTaskListResponse) => {
              draft.page.content = [response, ...draft.page.content];
            },
          ),
        );
      },
      invalidatesTags: [{ type: StoreTagTypes.Meet, id: "LIST" }],
    }),
    moveDateTask: build.mutation<void, { id: string; date: string; newDate: string; isPersonalTask: boolean }>({
      query: ({ isPersonalTask, id, date, newDate }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/work-day/${date}/move-date/${newDate}`,
        method: "post",
      }),
      invalidatesTags: [{ type: StoreTagTypes.Task, id: "TASK_LIST" }],
    }),
    workDayCompleteTask: build.mutation<ITask, { id: string; date: string; isPersonalTask: boolean }>({
      query: ({ isPersonalTask, id, date }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/work-day/complete/${date}`,
        method: "post",
      }),
      async onQueryStarted({ id, isPersonalTask }, { dispatch, queryFulfilled }) {
        const { data: response } = await queryFulfilled;
        await afterUpdateTask(id, dispatch, isPersonalTask, "all", { task: response });
      },
    }),
    workDayNotCompleteTask: build.mutation<
      void,
      { id: string; date: string; isPersonalTask: boolean; body: { comment: string; reason: string } }
    >({
      query: ({ isPersonalTask, id, date, body }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/work-day/not-complete`,
        data: { ...body, date },
        method: "post",
      }),
      async onQueryStarted({ id, isPersonalTask }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        await afterUpdateTask(id, dispatch, isPersonalTask, "all");
      },
    }),
    workDayNeedMoreTimeTask: build.mutation<
      void,
      { id: string; isPersonalTask: boolean; body: { additionalDate: string; currentDate: string } }
    >({
      query: ({ isPersonalTask, id, body }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/work-day/need-more-time`,
        data: body,
        method: "post",
        meta: {
          notifyText: `${intl.getMessage("planningSuccess")} ${dayjs(body.additionalDate).format("DD.MM.YYYY")}`,
        },
      }),
      async onQueryStarted({ id, isPersonalTask, body }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        await afterUpdateTask(id, dispatch, isPersonalTask, "all", {
          workDay: { date: body.currentDate, obj: { status: "NEED_MORE_TIME" } },
        });
      },
    }),
    workDayCancelCompleteTask: build.mutation<void, { id: string; isPersonalTask: boolean; date: string }>({
      query: ({ isPersonalTask, id, date }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/work-day/not-complete/${date}`,
        method: "post",
      }),
      async onQueryStarted({ id, isPersonalTask, date }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        await afterUpdateTask(id, dispatch, isPersonalTask, "all", {
          task: { status: "OPEN" },
          workDay: { date, obj: { status: "NOT_COMPLETED", isCompleted: false } },
        });
      },
    }),
    workDayCommentTask: build.mutation<void, { id: string; isPersonalTask: boolean; body: { date: string; comment: string } }>({
      query: ({ isPersonalTask, id, body }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/work-day/comment`,
        data: body,
        method: "post",
      }),
    }),
    addWorkDayTask: build.mutation<void, { id: string; isPersonalTask: boolean; body: { additionalDate: string; currentDate: string } }>({
      query: ({ isPersonalTask, id, body }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/work-day/add`,
        data: body,
        method: "post",
        meta: {
          notifyText: `${intl.getMessage("planningSuccess")} ${dayjs(body.additionalDate).format("DD.MM.YYYY")}`,
        },
      }),
      async onQueryStarted({ id, isPersonalTask }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        await afterUpdateTask(id, dispatch, isPersonalTask, "all");
        afterUpdateSingleSection(id, dispatch);
      },
      invalidatesTags: [{ type: StoreTagTypes.Meet, id: "LIST" }],
    }),
    markWorkDayTask: build.mutation<void, { id: string; isPersonalTask: boolean }>({
      query: ({ isPersonalTask, id }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/work-day/mark`,
        method: "post",
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        afterUpdateSingleSection(id, dispatch);
      },
    }),
    forgetWorkDayTask: build.mutation<void, { id: string; isPersonalTask: boolean; type: TSingleSections }>({
      query: ({ isPersonalTask, id, type }) => {
        let url;
        switch (type) {
          case "transferred":
            url = `/api/crud/v1/${getService(isPersonalTask)}/${id}/work-day/forget`;
            break;
          case "delegated":
            url = `/api/crud/v1/business-tasks/${id}/delegated/hide`;
            break;
          case "takenBack":
            url = `/api/crud/v1/business-tasks/${id}/taken-back/hide`;
            break;
        }

        return {
          url,
          method: "post",
        };
      },
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        afterUpdateSingleSection(id, dispatch);
      },
    }),
    forgetAllTask: build.mutation<void, { type: TSingleSections }>({
      query: ({ type }) => {
        let url;
        switch (type) {
          case "transferred":
            url = "/api/crud/v1/business-tasks/work-day/forget";
            break;
          case "delegated":
            url = "/api/crud/v1/business-tasks/delegated/hide";
            break;
          case "takenBack":
            url = "/api/crud/v1/business-tasks/taken-back/hide";
            break;
        }

        return {
          url,
          method: "post",
        };
      },
      async onQueryStarted({ type }, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          taskApi.util.updateQueryData(
            "getTaskList",
            { params: { date: toFormatDate(store.getState().screenDay.date), current: 0, next: 15 } },
            (draft: { tasks: ITask[]; singleSections: ISingleSectionResponse }) => {
              draft.singleSections[type].content = [];
            },
          ),
        );
      },
      invalidatesTags: [StoreTagTypes.Calendar],
    }),
    takeToWorkTask: build.mutation<ITask, { id: string; isPersonalTask: boolean; date: string; isAdd: boolean }>({
      query: ({ isPersonalTask, id, date }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/work-day/add/${date}`,
        method: "post",
        meta: { notifyText: `${intl.getMessage("planningSuccess")} ${dayjs(date).format("DD.MM.YYYY")}` },
      }),
      async onQueryStarted({ id, isPersonalTask, isAdd }, { dispatch, queryFulfilled }) {
        const { data: response } = await queryFulfilled;

        if (isAdd) {
          dispatch(
            taskApi.util.updateQueryData(
              "getTaskList",
              { params: { date: toFormatDate(store.getState().screenDay.date), current: 0, next: 15 } },
              (draft: { tasks: ITask[]; singleSections: ISingleSectionResponse }) => {
                draft.tasks = [...draft.tasks, response];
              },
            ),
          );
        }
        await afterUpdateTask(id, dispatch, isPersonalTask, "all");
        afterUpdateSingleSection(id, dispatch);
      },
      invalidatesTags: [{ type: StoreTagTypes.Meet, id: "LIST" }],
    }),
    deleteWorkDayTask: build.mutation<ITask, { id: string; isPersonalTask: boolean; date: string; skippedDate: string | null }>({
      query: ({ isPersonalTask, id, date }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/work-day/${date}`,
        method: "delete",
      }),
      async onQueryStarted({ id, date, isPersonalTask, skippedDate }, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        if (date === toFormatDate(store.getState().screenDay.date)) {
          dispatch(
            taskApi.util.updateQueryData(
              "getTaskList",
              { params: { date: toFormatDate(store.getState().screenDay.date), current: 0, next: 15 } },
              (draft: { tasks: ITask[]; singleSections: ISingleSectionResponse }) => {
                draft.tasks = [...draft.tasks].filter((i) => i.id !== id);
              },
            ),
          );
        }

        if (date === skippedDate) {
          afterUpdateSingleSection(id, dispatch);
        }

        await afterUpdateTask(id, dispatch, isPersonalTask, "all");
      },
      invalidatesTags: [{ type: StoreTagTypes.Meet, id: "LIST" }],
    }),
    delegateTask: build.mutation<ITask, { id: string; userId: string | null; date: Dayjs }>({
      query: ({ id, userId }) => ({
        url: `/api/crud/v1/business-tasks/${id}/delegate/${userId}`,
        method: "post",
      }),
      async onQueryStarted({ id, date }, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        if (toFormatDate(dayjs()) === toFormatDate(date)) {
          dispatch(
            taskApi.util.updateQueryData(
              "getTaskList",
              { params: { date: toFormatDate(store.getState().screenDay.date), current: 0, next: 15 } },
              (draft: { tasks: ITask[]; singleSections: ISingleSectionResponse }) => {
                draft.tasks = [...draft.tasks].filter((i) => i.id !== id);
              },
            ),
          );
        }

        await afterUpdateTask(id, dispatch, false, "all");
        setTimeout(() => showToast("delegateTaskSuccess"), 1000);
      },
      invalidatesTags: [{ type: StoreTagTypes.Meet, id: "LIST" }],
    }),
    takeMeTask: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/api/crud/v1/business-tasks/${id}/take-back`,
        method: "post",
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        const response = await afterUpdateTask(id, dispatch, false, "all");

        if (response?.controlTasks?.length) {
          for (const item of response.controlTasks) {
            dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Task, id: item.id }]));
          }
          showToast("controlTaskIsClosed");
          dispatch(
            api.util.invalidateTags([
              { type: StoreTagTypes.Task, id: "TASK_LIST" },
              { type: StoreTagTypes.Task, id: "ALL_TASK_LIST" },
            ]),
          );
        }
        afterUpdateSingleSection(id, dispatch);
      },
      invalidatesTags: [{ type: StoreTagTypes.Meet, id: "LIST" }],
    }),
    getStatisticTask: build.query<IStatisticResponse, { date: string }>({
      query: ({ date }) => ({
        url: `/api/api-gateway/v1/business-tasks/statistics/day/${date}`,
        method: "get",
      }),
      keepUnusedDataFor: Times.Mins_5,
      providesTags: () => [{ type: StoreTagTypes.Task, id: "GET_STATISTIC" }],
    }),
    addTaskComment: build.mutation<void, { id: string; comment: string; isPersonalTask: boolean }>({
      query: ({ id, comment, isPersonalTask }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/comments`,
        data: { comment },
        method: "post",
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: StoreTagTypes.Task, id: arg.id }],
    }),
    editTaskComment: build.mutation<void, { id: string; commentId: string; comment: string; isPersonalTask: boolean }>({
      query: ({ id, comment, isPersonalTask, commentId }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/comments/${commentId}`,
        data: { comment },
        method: "patch",
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: StoreTagTypes.Task, id: arg.id }],
    }),
    deleteTaskComment: build.mutation<void, { id: string; commentId: string; isPersonalTask: boolean }>({
      query: ({ id, isPersonalTask, commentId }) => ({
        url: `/api/crud/v1/${getService(isPersonalTask)}/${id}/comments/${commentId}`,
        method: "delete",
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: StoreTagTypes.Task, id: arg.id }],
    }),
    setOnesTaskResolution: build.mutation<void, { id: string; resolution: OneSResolutions; comment?: string }>({
      query: ({ id, comment, resolution }) => ({
        url: `/api/crud/v1/business-tasks/one-s/${id}/resolution`,
        data: { comment, resolution },
        method: "post",
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: StoreTagTypes.Task, id: arg.id },
        { type: StoreTagTypes.Task, id: "TASK_LIST" },
        { type: StoreTagTypes.Task, id: "ALL_TASK_LIST" },
      ],
    }),
    changeAuthorTask: build.mutation<void, { id: string; userId: string }>({
      query: ({ id, userId }) => ({
        url: `/api/crud/v1/business-tasks/${id}/assign-author-to/${userId}`,
        method: "patch",
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            taskApi.util.updateQueryData(
              "getTaskList",
              { params: { date: toFormatDate(store.getState().screenDay.date), current: 0, next: 15 } },
              (draft: { tasks: ITask[]; singleSections: ISingleSectionResponse }) => {
                draft.tasks = [...draft.tasks].filter((i) => i.id !== id);
              },
            ),
          );

          dispatch(
            taskApi.util.updateQueryData(
              "getAllTaskList",
              { filter: store.getState().businessTasks.filter },
              (draft: IAllTaskListResponse) => {
                draft.page.content = [...draft.page.content].filter((i) => i.id !== id);
              },
            ),
          );
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const {
  useGetTaskListQuery,
  useGetAllTaskListQuery,
  usePagingAllTaskListMutation,
  useGetListForGoalQuery,
  useGetTaskByIdQuery,
  useUpdateTaskMutation,
  useCreateTaskMutation,
  useDeleteTaskMutation,
  useUpdateTaskPositionMutation,
  useCloseTaskMutation,
  useCompleteTaskMutation,
  useReopenTaskMutation,
  useAddTaskCommentMutation,
  useAddWorkDayTaskMutation,
  useCloneTaskMutation,
  useDelegateTaskMutation,
  useDeleteTaskCommentMutation,
  useDeleteWorkDayTaskMutation,
  useEditTaskCommentMutation,
  useForgetAllTaskMutation,
  useForgetWorkDayTaskMutation,
  useGetStatisticTaskQuery,
  useMarkWorkDayTaskMutation,
  useMoveDateTaskMutation,
  usePauseTaskMutation,
  useSetOnesTaskResolutionMutation,
  useTakeMeTaskMutation,
  useTakeToWorkTaskMutation,
  useWorkDayCancelCompleteTaskMutation,
  useWorkDayCommentTaskMutation,
  useWorkDayCompleteTaskMutation,
  useWorkDayNeedMoreTimeTaskMutation,
  useWorkDayNotCompleteTaskMutation,
  useChangeAuthorTaskMutation,
} = taskApi;
