import React, { useRef } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { NotificationClear } from "@components/ui/notification/NotificationClear";
import { useTask } from "@hooks/useTask";
import { useCreateTaskMutation } from "@services/taskApi";
import { selectCurrentDate } from "@store/screenDay";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { showToast } from "@utils/toast";
import { toFormatDate } from "@utils/toFormatTime";

import { TypeKey } from "./constants";
import { useStartData } from "./useStartData";

interface ICreateTask {
  priority: TypeKey;
  setIsCreating: (arg: TypeKey | undefined) => void;
}

export const CreateTask = ({ priority, setIsCreating }: ICreateTask) => {
  const inputRef = useRef(null);

  const { data } = useStartData(priority);
  const { name, setText, text, onChangeTitle, setIsErrorTitle, isErrorTitle, handleSaveTitle } = useTask({});

  const dateNow = useSelector(selectCurrentDate);

  const [createTask] = useCreateTaskMutation();

  const handleSave = async () => {
    if (!name) {
      return showToast("enterNameTask");
    }

    const tagId = await handleSaveTitle();

    if (tagId === null) return;

    createTask({
      body: { ...data, name, tagId },
      isAdd: toFormatDate(dateNow) === data.startDate,
      isPersonalTask: false,
      type: "",
    });
    setText("");
    setIsErrorTitle(false);
    inputRef.current.focus();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.key === "Enter" && handleSave();
  };

  const placeholder = useIntl().formatMessage({ id: "taskName" });

  return (
    <>
      <Container>
        <Input
          value={text}
          onChange={(e) => onChangeTitle(e.target.value)}
          placeholder={placeholder}
          autoFocus
          onBlur={() => !isErrorTitle && setIsCreating(undefined)}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          style={{ marginBottom: 0 }}
        />
        <StPlaceholderTextFont color={Colors.LIGHT.placeholder.system} type="bold" size={12}>
          <FormattedMessage id="tagtask" />
        </StPlaceholderTextFont>

        <TextFont size={12} color={"#A1B6AD"}>
          <FormattedMessage id="clickToCreate" />
        </TextFont>
      </Container>

      {isErrorTitle && <NotificationClear phraseId="tagDescription" phraseOkId="clear" onOk={() => setIsErrorTitle(false)} />}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 7px;
  background-color: ${Colors.LIGHT.white};
  border-radius: 8px;
  padding: 8px;
  margin-top: 6px;
  justify-content: space-between;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
`;

const Input = styled.input`
  width: 95%;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
`;

const StPlaceholderTextFont = styled(TextFont)`
  margin-bottom: 20px;
  padding-left: 2px;
`;
