import React, { Dispatch, useMemo } from "react";

import dayjs from "dayjs";
import { isObject } from "lodash";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { StTextUserWorkPositionTF } from "@components/screens/day/components/timetable/main/modal/components/participants/components/participantsListItem/styled";
import { RenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { IStateUser } from "@interfaces/user.interface";
import { UserAvatar } from "@shared/userAvatar/UserAvatar";
import { selectUserPermissoins } from "@store/user";
import { ButtonMain, boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont, HeaderModal } from "@ui";

import { TypeTab } from "../../types";

import { IData, data } from "./config";

interface IProps {
  onClose: () => void;
  setTab?: Dispatch<TypeTab>;
  user: IStateUser;
  isClose?: boolean;
}

export const UserInformation = ({ onClose, setTab, user, isClose = true }: IProps) => {
  const { isTablet } = useDimension();

  const permissons = useSelector(selectUserPermissoins);

  const renderText = (element: IData) => {
    // @ts-ignore
    let text: string | undefined = user[element.value];
    if (element.custom) text = customValue[element.value];

    if (isObject(text)) text = undefined;

    if (element.isLink) {
      return text ? (
        <a href={`mailto:${text}`}>
          <TextFont color={Colors.LIGHT.blue}>{text}</TextFont>
        </a>
      ) : (
        <TextFont size={16} lineHeight={24}>
          не заполнено
        </TextFont>
      );
    }
    return (
      <StTextUserWorkPositionTF size={19} lineHeight={24}>
        {text ? text : "не заполнено"}
      </StTextUserWorkPositionTF>
    );
  };

  const pressSend = () => {
    if (setTab) {
      setTab("bugreport");
    }
  };

  const birthDay = useMemo(() => (user.birthDay ? dayjs(user.birthDay).format("DD MMMM") : undefined), [user.birthDay]);
  const gender = useMemo(() => {
    switch (user.gender) {
      case "MALE":
        return "Мужской";
      case "FEMALE":
        return "Женский";
      default:
        return undefined;
    }
  }, [user.gender]);

  const customValue: Partial<Record<keyof IStateUser, string | undefined>> = useMemo(
    () => ({
      birthDay,
      gender,
      headOfUser: user.headOfUser ? `${user.headOfUser?.firstName} ${user.headOfUser?.lastName}` : undefined,
      department: user.department?.name,
    }),
    [birthDay, gender, user.headOfUser, user.department],
  );

  return (
    <section>
      <HeaderModal
        title="detail"
        isEdit={false}
        leftSide={{ onPressClose: onClose }}
        styleContainer={{ backgroundColor: Colors.LIGHT.disabled }}
      />
      <Content>
        <BlockHeader>
          <RenderWithCondition condition={permissons.VIEW_FULL_PROFILE}>
            <UserAvatar avatarFileId={user.avatarFileId} style={avatarStyle} />
          </RenderWithCondition>
          <Title>
            <TextFont size={20} weight="700" lineHeight={28}>
              {`${user?.firstName ?? ""} ${user?.middleName ? user.middleName : " "} ${user?.lastName ?? ""}`}
            </TextFont>
            <TextFont size={16} lineHeight={24}>
              {user?.login}
            </TextFont>
          </Title>
        </BlockHeader>

        {data.map((element) => (
          <ListWrap key={element.title}>
            <TextFont size={14} lineHeight={22} color={Colors.LIGHT.grey500}>
              <FormattedMessage id={element.title} />
            </TextFont>
            {renderText(element)}
          </ListWrap>
        ))}

        <RenderWithCondition condition={setTab}>
          <ButtonMain onClick={pressSend}>
            <TextFont type="bold" size={18} weight="700" color={Colors.LIGHT.white}>
              <FormattedMessage id="Сообщить об ошибке" />
            </TextFont>
          </ButtonMain>
        </RenderWithCondition>
      </Content>
    </section>
  );
};

const Content = styled.div`
  padding: 10px 12px 0 12px;
  overflow: auto;
`;
const BlockHeader = styled.div`
  ${boxCenterCss};
  justify-content: flex-start;
  margin-bottom: 20px;
`;
const Title = styled.div`
  margin-left: 15px;
  max-width: 70%;
`;
const ListWrap = styled.div`
  margin-bottom: 15px;
`;

const avatarStyle = {
  width: 95,
  height: 95,
  marginBottom: 10,
  borderRadius: 95,
  border: `3px solid ${Colors.LIGHT.white}`,
};
