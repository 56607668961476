import { IUnitedGroupItem } from "@components/screens/profile/components/groups/Groups";
import { IStateUser } from "@interfaces/user.interface";
import { IExternalUser } from "@services/meet.service";

export function applyUpdatesFromArr2ToArr1(
  arr1: any,
  arr2: any,
  insertIfNotExistInArr1 = true,
): (IStateUser | IExternalUser)[] | IUnitedGroupItem[] {
  const arr1Map = new Map(arr1.map((item: { id: string }) => [item.id, item] as any));

  arr2.forEach((arr2Item: IStateUser | IExternalUser) => {
    if (arr1Map.has(arr2Item.id)) {
      arr1Map.set(arr2Item.id, { ...(arr1Map.get(arr2Item.id) as any), ...arr2Item });
    } else {
      if (insertIfNotExistInArr1) arr1Map.set(arr2Item.id, arr2Item);
    }
  });

  return Array.from(arr1Map.values()) as unknown as (IStateUser | IExternalUser)[] | IUnitedGroupItem[];
}
