import React, { FC, useState } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { TextareaComment } from "@styles/styles";
import { Colors } from "@theme/colors";
import { ModalUI, HeaderModal, TextFont } from "@ui";

interface IProps {
  close: () => void;
  onSave: (comment: string) => void;
  title?: string;
}

export const ModalComment: FC<IProps> = ({ close, onSave, title = "" }) => {
  const [comment, setComment] = useState("");

  return (
    <ModalUI isVisible={true} onClose={close}>
      <div>
        <HeaderModal
          title={title}
          leftSide={{ onPressClose: close, isHideCancel: true }}
          isEdit={true}
          rightSide={{ onPress: () => onSave(comment) }}
        />
        <StContentDiv>
          <TextareaComment value={comment} onChange={(e) => setComment(e.target.value)} placeholder={"Комментарий"} maxLength={100} />
          <StButtonSaveBtn onClick={() => onSave(comment)}>
            <TextFont size={18} weight="700" color={Colors.LIGHT.white} align="center">
              <FormattedMessage id={"save"} />
            </TextFont>
          </StButtonSaveBtn>
        </StContentDiv>
      </div>
    </ModalUI>
  );
};

const StContentDiv = styled.div`
  padding: 20px 12px 0 12px;
`;

const StButtonSaveBtn = styled.button`
  width: 100%;
  padding: 16px 0;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: ${Colors.LIGHT.text.accent};
`;
