import { isRejectedWithValue, isFulfilled } from "@reduxjs/toolkit";

import { configNotify } from "./constants";
import { IActionNotify } from "./types";

export const buildConfigNotify = (action: IActionNotify) => {
  let notify = null;

  if (isRejectedWithValue(action)) {
    const message = action?.payload?.errorText || action?.payload || action?.payload?.data?.message;
    if (typeof message === "string") {
      notify = configNotify(message, "error");
    }
  }

  if (isFulfilled(action) && (action?.payload?.notifyText || action?.meta?.baseQueryMeta?.notifyText)) {
    notify = configNotify(action?.payload?.notifyText || action?.meta?.baseQueryMeta?.notifyText, "success");
    return notify;
  }

  return notify;
};
