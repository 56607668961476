import { useMemo } from "react";

import { useSelector } from "react-redux";

import { IMeet, IMeetParticipants, IUserMeetPermissions } from "@interfaces/meet.interface";
import { selectUserPermissoins, userSelector } from "@store/user";

export interface IUseRoleModel {
  isRoleModelEnabled: boolean;
  isUserHasEditPermissions: boolean;
  isUserHasInvitePermissions: boolean;
  isUserShowSaveActivity: boolean;
  isUserShowSaveParticipants: boolean;
  isUserShowAttendanceConfirmation: boolean;
  isMeetFromOutlook: boolean;
  isGroupAllSaved: boolean;
  isMeetFromIspring: boolean;
}

export const useRoleModel = (meet: IMeet | null | undefined): IUseRoleModel => {
  const { currentUser, directorId } = userSelector();
  const permissions = useSelector(selectUserPermissoins);

  const checkIsRoleModelEnabled = (participants: IMeetParticipants[]) => {
    for (const user of participants) {
      if (user.permissions?.length) return true;
    }

    return false;
  };

  const getUserMeetingPermissions = (userId: string, participants: IMeetParticipants[]): IUserMeetPermissions => {
    const result = {
      canEditMeeting: false,
      canAddParticipants: false,
    };

    for (const user of participants) {
      if (user?.user?.id === userId) {
        if (user.permissions?.includes("ADD_PARTICIPANTS")) {
          result.canAddParticipants = true;
        }

        if (user.permissions?.includes("EDIT_MEETING")) {
          result.canEditMeeting = true;
        }
      }
    }

    return result;
  };
  const userIdToCheck = directorId || (currentUser?.id ?? "");
  const isRoleModelEnabled = meet ? checkIsRoleModelEnabled(meet.participants ?? []) : false;
  const userMeetPermissions = getUserMeetingPermissions(userIdToCheck, meet?.participants ?? []);
  const isGroupAllSaved = Boolean(meet?.presetGroups?.length && meet?.presetGroups[0]?.type === "ALL_IN_SPACE");
  const isOnlyOwner = meet?.participants?.length === 1 && meet?.participants[0].status === "OWNER" && !meet?.externalUsers?.length;
  const isMeetFromOutlook = Boolean(meet?.externalId && meet?.externalType === "OUTLOOK");
  const isMeetFromIspring = Boolean(meet?.externalId && meet?.externalType === "I_SPRING");

  const isUserHasEditPermissions = Boolean(meet?.createdByCurrentUser) || (isRoleModelEnabled && userMeetPermissions.canEditMeeting);
  const isUserHasInvitePermissions = Boolean(meet?.createdByCurrentUser) || (isRoleModelEnabled && userMeetPermissions.canAddParticipants);
  const isUserShowSaveActivity =
    Number(meet?.participants?.length ?? 0) + Number(meet?.externalUsers?.length ?? 0) > 1 &&
    (isUserHasEditPermissions || isUserHasInvitePermissions) &&
    !meet?.externalId;
  const isUserShowSaveParticipants = (permissions.CAN_ATTEND_MEETING && !isOnlyOwner) || isGroupAllSaved;
  const isUserShowAttendanceConfirmation = Boolean(meet?.currentParticipantStatus !== "OWNER" && meet?.active && !isMeetFromOutlook);

  return useMemo(
    () => ({
      isRoleModelEnabled,
      isUserHasEditPermissions,
      isUserHasInvitePermissions,
      isUserShowSaveActivity,
      isUserShowSaveParticipants,
      isUserShowAttendanceConfirmation,
      isMeetFromOutlook,
      isGroupAllSaved,
      isMeetFromIspring,
    }),
    [
      isRoleModelEnabled,
      isUserHasEditPermissions,
      isUserHasInvitePermissions,
      isUserShowSaveActivity,
      isUserShowSaveParticipants,
      isUserShowAttendanceConfirmation,
      isMeetFromOutlook,
      isGroupAllSaved,
      isMeetFromIspring,
    ],
  );
};
