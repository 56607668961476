import React, { FC, useEffect, useState } from "react";

import { useIntl } from "react-intl";
import styled from "styled-components";

import { ModalRenderWithCondition } from "@hoc";
import { useTask } from "@hooks/useTask";
import { useCreateTaskMutation } from "@services/taskApi";
import { TextareaComment } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont, HeaderModal, ModalUI } from "@ui";
import { Notification } from "@ui/notification/Notification";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { toFormatDate } from "@utils";

import { Project } from "../../../ListTasks/Modal/components/ViewNotSave/components";

import { useStartData } from "./useStartData";

interface IProps {
  close: () => void;
}

export const AdditionalTask: FC<IProps> = ({ close }) => {
  const { data, handleData, isCancel, setIsCancel, dateNow } = useStartData();
  const { handleSaveTitle, onChangeTitle, isErrorTitle, setIsErrorTitle, text, name } = useTask({});

  const [isEdit, setIsEdit] = useState(false);

  const [createUnplanned, { isLoading }] = useCreateTaskMutation();

  useEffect(() => {
    setIsEdit(!!text);
  }, [text]);

  const onSubmit = async () => {
    const tagId = await handleSaveTitle();

    if (tagId === null) return;

    await createUnplanned({
      body: { ...data, name, tagId, startDate: toFormatDate(dateNow) },
      isPersonalTask: false,
      type: "unplanned",
    });
    return close();
  };

  const plhName = useIntl().formatMessage({ id: "tagtask" });
  const plhDesc = useIntl().formatMessage({ id: "taskDescroption" });

  return (
    <ModalUI isVisible={true} onClose={isEdit ? () => setIsCancel(true) : () => close()}>
      <HeaderModal
        title={"additionalTask"}
        isEdit={isEdit}
        rightSide={{ title: "add", onPress: onSubmit, isLoading }}
        leftSide={{ onPressClose: close, onPressCancel: () => setIsCancel(true) }}
        styleContainer={{ borderBottomWidth: 0 }}
      />
      <StContentWrapDiv>
        <StTextDateTF size={16}>
          Дата:
          <TextFont type="bold" weight="700">
            {` ${dateNow.locale("ru").format("D MMMM")}`}
          </TextFont>
        </StTextDateTF>

        <TextareaNameTask value={text} placeholder={plhName} onChange={(e) => onChangeTitle(e.target.value)} maxLength={255} />

        <TextareaDescription
          value={data.description}
          onChange={(e) => handleData("description", e.target.value)}
          placeholder={plhDesc}
          maxLength={255}
        />

        <Project name={data?.project?.name} bg={Colors.LIGHT.white} handleData={handleData} />
      </StContentWrapDiv>

      <ModalRenderWithCondition condition={isErrorTitle}>
        <Notification type="clear" phraseId="tagDescription" phraseOkId="clear" onOk={() => setIsErrorTitle(false)} />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isCancel}>
        <NotificationConfirm phraseId="cancelException" onOk={close} onCancel={() => setIsCancel(false)} />
      </ModalRenderWithCondition>
    </ModalUI>
  );
};

const StContentWrapDiv = styled.div`
  padding: 20px 15px 25px 9px;
`;
const StTextDateTF = styled(TextFont)`
  display: flex;
  margin-bottom: 8px;
  line-height: 24px;
  gap: 3px;
`;
const TextareaNameTask = styled(TextareaComment)`
  margin-bottom: 8px;
  padding: 12px 16px;
  min-height: 72px;
`;
const TextareaDescription = styled(TextareaComment)`
  margin-bottom: 8px;
  padding: 12px 16px;
  min-height: 56px;
`;
