import axios, { CancelTokenSource } from "axios";

import { http } from "@api/httpClient";

interface IGroupedListParams {
  date: string;
  total?: number;
  current?: number;
  next?: number;
}

export class BusinessTasksService {
  source: CancelTokenSource;
  lastRequest: CancelTokenSource | undefined;

  constructor() {
    this.source = axios.CancelToken.source();
  }

  getGroupedList = async (params: IGroupedListParams): Promise<any> => {
    if (this.lastRequest) {
      this.source.cancel();
    }

    this.source = axios.CancelToken.source();

    try {
      const response = await http.get(
        `/api/api-gateway/v1/common-tasks/grouped-list?${Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`,
        {
          cancelToken: this.source.token,
        },
      );

      this.lastRequest = this.source;

      return response;
    } catch (e) {
      return e;
    }
  };

  getPersonal = async (id: string): Promise<any> => await http.get(`/api-gateway/v1/personal-tasks/${id}`);

  get = async (id: string): Promise<any> => await http.get(`/api/api-gateway/v1/business-tasks/${id}`);
}

export const businessTasks = new BusinessTasksService();
