import React, { useMemo, useRef, useState } from "react";

import { isArray, isEqual, isObject, omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { LoaderRenderWithCondition, ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { useSubscribe } from "@hooks/useSubscribe";
import { TTaskSubType } from "@interfaces/businessTask.interface";
import { Layout } from "@layout";
import { SwipeListSections, Modal, SwipeList } from "@screens/day/components/ListTasks";
import { usePagingAllTaskListMutation, useGetAllTaskListQuery } from "@services/taskApi";
import { selectBusinessTasks, setFilter } from "@store/businessTask";
import { defaultFilter } from "@store/businessTask/constants";
import { sortingAllTasks } from "@store/businessTask/utils";
import { selectConfigure } from "@store/configure";
import { selectNotifications, setTaskID } from "@store/notification";
import { Colors } from "@theme/colors";
import { AddCircle, EmptyBusinessTasks, ItemListContentLoader, Loader } from "@ui";
import { showToast } from "@utils";
import { myTracker } from "@utils/myTracker";

import { ButtonsAllTasks } from "../day/components/ListTasks/taskItem/buttons";

import { NotFound } from "./components/notFound";
import { Header } from "./header";

export const Task = () => {
  const dispatch = useDispatch<any>();
  const listInnerRef = useRef();
  const { isTablet } = useDimension();

  const {
    filter: { name, groupByType },
    filter,
  } = useSelector(selectBusinessTasks);
  const { isModalOpen } = useSelector(selectConfigure);
  const notifications = useSelector(selectNotifications);

  const [isVisible, setIsVisible] = useState(false);
  const [id, setId] = useState("");
  const [taskSubType, setTaskSubType] = useState<TTaskSubType>();

  const [paging, { isLoading: isLoadingPaging }] = usePagingAllTaskListMutation();
  const { data: response, isFetching } = useGetAllTaskListQuery({ filter });
  const page = response?.page ?? { content: [], total: 0 };

  useSubscribe(
    "updateTasks",
    () => {
      dispatch(
        setFilter({
          name,
          groupByType,
          pageable: { next: 25, current: 0 },
        }),
      );
    },
    [name, groupByType],
  );

  React.useEffect(() => {
    if (notifications.task) {
      handlePress(notifications.task.id, notifications.task.type);
      dispatch(setTaskID(undefined));
    }
  }, [notifications.task]);

  const handlePress = (id: string, subType?: TTaskSubType) => {
    setId(id);
    setTaskSubType(subType);
    setIsVisible(true);
  };

  const handleResetFilter = () => {
    dispatch(setFilter({ name, groupByType, pageable: { next: 25, current: 0 } }));
  };

  const handleScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        Math.ceil(scrollTop + clientHeight) - 10 === scrollHeight - 10 ||
        Math.floor(scrollTop + clientHeight) - 10 === scrollHeight - 10
      ) {
        return await loadMoreData();
      }
    }
  };

  const loadMoreData = async () => {
    if (page.content.length >= page.total || isFetching) return;

    try {
      await paging({
        filter: {
          ...filter,
          pageable: {
            next: 25,
            current: Math.ceil(page.content.length / 25) * 25,
          },
        },
      });
    } catch (error) {
      return showToast("dataDidNotLoaded", "error");
    }
  };

  const isDefaultFilter = useMemo(() => isEqual(omit(filter, ["pageable"]), omit(defaultFilter, ["pageable"])), [filter]);
  const groupedTasks = useMemo(() => sortingAllTasks(groupByType, page.content), [groupByType, page.content]);

  const renderFooter = (
    <StLoaderWrapDiv>
      <Loader />
    </StLoaderWrapDiv>
  );

  const renderTasks = useMemo(() => {
    const isEmptyArray = !page.content.length;

    if (isEmptyArray && isDefaultFilter) {
      return <EmptyBusinessTasks press={() => handlePress("")} />;
    } else if (isEmptyArray) {
      return <NotFound handleResetFilter={handleResetFilter} />;
    } else if (isArray(groupedTasks)) {
      return (
        <SwipeList
          onScroll={handleScroll}
          listInnerRef={listInnerRef}
          data={groupedTasks}
          press={handlePress}
          buttons={(data) => <ButtonsAllTasks {...data} />}
          footer={isLoadingPaging && renderFooter}
        />
      );
    } else if (groupedTasks && isObject(groupedTasks)) {
      return (
        <SwipeListSections
          onScroll={handleScroll}
          listInnerRef={listInnerRef}
          data={groupedTasks}
          press={handlePress}
          footer={isLoadingPaging && renderFooter}
        />
      );
    }
  }, [groupedTasks, page.content, isLoadingPaging, isDefaultFilter]);

  return (
    <Layout header={<Header />} backgroundColor={Colors.LIGHT.white}>
      <LoaderRenderWithCondition
        condition={isFetching && !isModalOpen}
        loaderComponent={<ItemListContentLoader width={window.innerWidth} height={window.innerHeight} />}
      >
        <StTabletContainerDiv>
          <StContentWrapDiv isTablet={isVisible && isTablet}>{renderTasks}</StContentWrapDiv>
          <ModalRenderWithCondition condition={isVisible}>
            <StModalWrapDiv>
              <Modal isModal={!isTablet} isVisible={isVisible} setIsVisible={setIsVisible} id={id} taskSubType={taskSubType} />
            </StModalWrapDiv>
          </ModalRenderWithCondition>
        </StTabletContainerDiv>
      </LoaderRenderWithCondition>
      <RenderWithCondition condition={page.content.length || !isDefaultFilter}>
        <AddCircle
          press={() => {
            myTracker("CreateTaskOnAllClick");
            handlePress("");
          }}
          bottom={100}
          right={20}
        />
      </RenderWithCondition>
    </Layout>
  );
};

const StTabletContainerDiv = styled.div`
  display: flex;
  height: 97%;
  margin-top: 20px;
`;
const StContentWrapDiv = styled.div<{ isTablet: boolean }>`
  width: ${({ isTablet }) => (isTablet ? "65%" : "100%")};
  transition: width 0.3s;
`;
const StModalWrapDiv = styled.div`
  width: 35%;
  background-color: ${Colors.LIGHT.background.main};
  z-index: 999;
`;
const StLoaderWrapDiv = styled.div`
  position: relative;
  width: 100%;
  height: 10%;
`;
