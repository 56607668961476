import React, { FC, useMemo } from "react";

import dayjs from "dayjs";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { UserAvatar } from "@components/shared/userAvatar/UserAvatar";
import { TextFont } from "@components/ui";
import { IEventsOfDay } from "@interfaces/eventsOfDay.interface";
import { Colors } from "@theme/colors";

import { BORDER_WIDTH, HEIGHT_BLOCK, WIDTH_BLOCK } from "../constants";

interface IProps {
  events: Partial<IEventsOfDay>[];
  isRooms?: boolean;
  setSelectedEvent?: (event: Partial<IEventsOfDay>) => void;
  selectedEvent?: Partial<IEventsOfDay>;
}

export const RowParticipant: FC<IProps> = ({ events, isRooms, setSelectedEvent, selectedEvent }) => (
  <StContentWrapDiv>
    {events.map((event) => (
      <Event key={event?.id} event={event} isRooms={isRooms} setSelectedEvent={setSelectedEvent} selectedEvent={selectedEvent} />
    ))}
  </StContentWrapDiv>
);

const Event = ({
  event,
  setSelectedEvent,
  selectedEvent,
}: {
  event: Partial<IEventsOfDay>;
  isRooms?: boolean;
  setSelectedEvent: (event: Partial<IEventsOfDay>) => void;
  selectedEvent: Partial<IEventsOfDay>;
}) => {
  const params = useMemo(() => {
    const widthMinute = WIDTH_BLOCK / 60;
    const width = widthMinute * dayjs(event.endTime).diff(event.startTime, "minutes");
    const left = dayjs(event.startTime).hour() * WIDTH_BLOCK + widthMinute * dayjs(event.startTime).minute();
    const color = Colors.LIGHT.green500;

    return { width, left, color };
  }, [event.startTime, event.endTime]);

  const shortUserName = useMemo(
    // @ts-ignore
    () => `${selectedEvent?.user?.lastName ?? ""} ${selectedEvent?.user?.firstName[0]?.toUpperCase() ?? ""}. ${
      // @ts-ignore
      selectedEvent?.user?.middleName[0]?.toUpperCase() ?? ""
    }.
      `,
    [selectedEvent],
  );

  return (
    <StEventItemDiv
      left={params.left}
      width={params.width}
      backColor={params.color}
      data-tooltip-id="user-booked-room"
      onMouseOver={() => setSelectedEvent(event)}
      onMouseOut={() => setSelectedEvent(null)}
    >
      <Tooltip id={"user-booked-room"} style={tooltipStyle} place="top-end" delayShow={300}>
        <StInfoIconDiv>
          {/* @ts-ignore */}
          <UserAvatar avatarFileId={(selectedEvent?.user && selectedEvent?.user["avatarFileId"]) ?? null} style={userAvatarStyle} />
          <TextFont size={14} lineHeight={16} color={Colors.LIGHT.text.darkGreen}>
            {shortUserName}
          </TextFont>
        </StInfoIconDiv>
      </Tooltip>
    </StEventItemDiv>
  );
};

const StContentWrapDiv = styled.div`
  display: flex;
  height: ${HEIGHT_BLOCK + BORDER_WIDTH}px;
  width: 100%;
  position: relative;
`;
const StEventItemDiv = styled.div<{ left: number; width: number; backColor: string }>`
  bottom: 0;
  border-radius: 4px;
  position: absolute;
  top: 2px;
  ${({ left, width, backColor }) => `
  left: ${left}px;
  width: ${width}px;
  background-color: ${backColor};
  z-index: 2;
`}
`;

const StInfoIconDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
`;

const userAvatarStyle = {
  width: 27,
  height: 27,
  borderRadius: 32,
  marginRight: 8,
  border: `2px solid ${Colors.LIGHT.lighGrey}`,
};

const tooltipStyle = {
  padding: "10px",
  backgroundColor: Colors.LIGHT.white,
  zIndex: 999,
  borderRadius: "10px",
  boxShadow: `0px 2px 16px ${Colors.LIGHT.shadowTooltip}`,
  width: 180,
};
