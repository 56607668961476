import React, { FC, useState } from "react";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as Close } from "@assets/close-thin.svg";
import { ReactComponent as SvgMeet } from "@assets/meet.svg";
import { RenderWithCondition } from "@hoc";
import { IMeet } from "@interfaces/meet.interface";
import { selectSpecialTag } from "@store/tag";
import { selectTheme } from "@store/theme";
import { Colors } from "@theme/colors";
import { Checkbox, TextFont } from "@ui";

interface IEventItem {
  item: IMeet;
  checkbox?: boolean;
  isChecked?: boolean;
  allowDeletion?: boolean;
  date: string;
  onDelete?: () => void;
  onPress?: (meetingId: string) => void;
  handleCheckedItems?: (id: string, item: IMeet, date: string) => void;
}

export const EventItem: FC<IEventItem> = ({ item, checkbox, isChecked, date, allowDeletion, onDelete, onPress, handleCheckedItems }) => {
  const [isItemChecked, setIsItemChecked] = useState(isChecked);
  const tagName = useSelector(selectSpecialTag(item.tagId ?? ""));
  const theme = selectTheme("extra");

  const startDate = dayjs(item.startTime).format("DD.MM");
  const startTime = dayjs(item.startTime).format("HH:mm");

  const handleItemCheckBox = (event: React.MouseEvent<HTMLButtonElement>, itemId: string, date: string) => {
    event.stopPropagation();
    setIsItemChecked(!isItemChecked);
    handleCheckedItems && handleCheckedItems(itemId, item, date);
  };

  const handleDeleteWrapper = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onDelete();
  };

  return (
    <StContentWrapDiv onClick={() => onPress && onPress(item.id)}>
      {checkbox ? (
        // @ts-ignore
        <StCheckboxCustom isChecked={isItemChecked} onChange={(e) => handleItemCheckBox(e, item.id, date)} />
      ) : (
        <StIconWrapDiv>
          <SvgMeet fill={theme.background.green} />
        </StIconWrapDiv>
      )}
      <StBlockTextDiv>
        <RenderWithCondition condition={tagName}>
          <TextFont weight="700">{tagName?.name}</TextFont>
        </RenderWithCondition>
        <TextFont lineHeight={18}>{item.name}</TextFont>
      </StBlockTextDiv>

      <StBlockDateDiv>
        <StMeetTimeP checkbox={checkbox}>{startDate}</StMeetTimeP>
        <StMeetTimeP checkbox={checkbox}>{startTime}</StMeetTimeP>
      </StBlockDateDiv>
      <RenderWithCondition condition={allowDeletion}>
        <StButtonRemoveBtn onClick={(e) => handleDeleteWrapper(e)}>
          <Close />
        </StButtonRemoveBtn>
      </RenderWithCondition>
    </StContentWrapDiv>
  );
};

const StContentWrapDiv = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 5px 5px 5px 0;
  background-color: ${Colors.LIGHT.white};
  border-radius: 5px;
  cursor: pointer;
`;
const StIconWrapDiv = styled.div`
  padding: 0 5px;
`;
const StBlockDateDiv = styled.div`
  display: flex;
  gap: 5px;
`;
const StBlockTextDiv = styled.div`
  display: flex;
  padding-right: 5px;
  flex: 1;
  max-height: 36px;
  overflow-y: hidden;
  gap: 5px;
`;
const StCheckboxCustom = styled(Checkbox)`
  margin-right: 8px;
  margin-left: 5px;
`;
const StButtonRemoveBtn = styled.button`
  margin-left: 10px;
  padding: 10px 5px;
`;

const StMeetTimeP = styled.p<{ checkbox: boolean }>`
  color: ${(props) => (props.checkbox ? `${Colors.LIGHT.text.accent}` : props.theme.extra.text.accent)};
  line-height: 18px;
  font-size: 14;
  margin-left: auto;
`;
