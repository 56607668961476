import React, { FC, useMemo, useState } from "react";

import { FormattedMessage } from "react-intl";

import { RenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { SwipeList } from "@screens/day/components/ListTasks";
import { ButtonsAllTasks } from "@screens/day/components/ListTasks/taskItem/buttons";
import { ArrowSvg, EmptyBusinessTasks, TextFont } from "@ui";

interface IProps {
  tasks: ITask[];
  press: (arg: unknown) => void;
}

export const Tasks: FC<IProps> = ({ tasks, press }) => {
  const [isDropdown, setIsDropdown] = useState(true);

  const numberOfTasks = useMemo(() => {
    if (!tasks?.length) {
      return "";
    }
    const allTasks = tasks.filter((item) => item.status !== "CLOSED").length;
    const completedTasks = tasks.filter((item) => item.status === "COMPLETED").length;

    if (!allTasks) return "";
    if (!completedTasks) return allTasks;

    return `${completedTasks}/${allTasks}`;
  }, [tasks]);

  return (
    <section style={styles.container}>
      <button onClick={() => setIsDropdown(!isDropdown)} style={styles.header}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: 46 }}>
          <TextFont style={styles.title}>
            <FormattedMessage id="tasksToGoal" />
          </TextFont>
          <RenderWithCondition condition={numberOfTasks}>
            <div style={styles.numTasks}>
              <TextFont>{numberOfTasks}</TextFont>
            </div>
          </RenderWithCondition>
        </div>
        <ArrowSvg type={isDropdown ? "up" : "down"} fill="#B3BEB8" />
      </button>
      <RenderWithCondition condition={isDropdown}>
        {tasks?.length ? (
          <SwipeList data={tasks} press={press} buttons={(data) => <ButtonsAllTasks {...data} />} />
        ) : (
          <div style={{ height: 150 }}>
            <EmptyBusinessTasks press={() => press("")} />
          </div>
        )}
      </RenderWithCondition>
    </section>
  );
};

const styles = {
  container: {
    marginTop: "26px",
    height: "auto",
  },
  header: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    marginRight: "10px",
  },
  numTasks: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    justifyContent: "center",
    width: "28px",
    height: "28px",
    backgroundColor: "#ECF0EF",
    borderRadius: "22px",
  },
};
