import React, { FC, memo, useEffect, useMemo, useState, MouseEvent, useCallback } from "react";

import { styled } from "styled-components";

import { ReactComponent as SvgCheck } from "@assets/check.svg";
import { ReactComponent as SvgEdit } from "@assets/edit_underline.svg";
import { ReactComponent as SvgPeopleWrapper } from "@assets/people_wrapper.svg";
import { RenderWithCondition } from "@hoc";
import { IMeetParticipants } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { LetterAvatar } from "@screens/day/components/timetable/main/modal/components/participants/components";
import { IExternalUser } from "@services/meet.service";
import { UserAvatar } from "@shared/userAvatar/UserAvatar";
import { userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { TextFont, Checkbox } from "@ui";
import { arePropsEqual } from "@utils/arePropsEqual";
import { isExternalUser as isExternal } from "@utils/userTypeChecks";

export type TExtendedOnSave = (users: IStateUser | IExternalUser | (IMeetParticipants | IExternalUser)[], isExternalUser?: boolean) => void;

interface IProps {
  item: IStateUser | IExternalUser;
  setSelectUser?: (arg: IStateUser | undefined) => void;
  onSave?: TExtendedOnSave;
  onPress?: (user: IStateUser | IExternalUser) => void;
  onRightClick?: (arg1: MouseEvent<HTMLButtonElement>, item: IStateUser | IExternalUser) => void;
  setCurrentEditableUser?: React.Dispatch<React.SetStateAction<IExternalUser | undefined>>;
  isRenderImg?: boolean;
  isRenderCheckbox?: boolean;
  isAdded?: boolean | undefined;
  isBusy?: boolean;
  hover?: string;
  isUserHasEditPermissions?: boolean;
  isUserHasInvitePermissions?: boolean;
  meetOwnerId?: string;
  isDeletable: boolean;
  isUncheckable?: boolean;
  meetId?: string;
}

export const Item: FC<IProps> = memo(function Item({
  item,
  setSelectUser,
  hover,
  onSave,
  onPress,
  onRightClick,
  setCurrentEditableUser,
  isBusy = false,
  isRenderImg = true,
  isRenderCheckbox = true,
  isAdded = false,
  meetOwnerId,
  isUserHasEditPermissions = false,
  isDeletable,
  isUncheckable = false,
}) {
  const [isItemChecked, setIsItemChecked] = useState<boolean>(isAdded);
  const [isCheckedByCurrentUser, setIsCheckedByCurrentUser] = useState(false);
  const isExternalUser = isExternal(item);
  const { currentUser, directorId } = userSelector();

  useEffect(() => {
    setIsItemChecked(isAdded);
  }, [isAdded]);

  const isCheckboxDisabled = useMemo(() => {
    const isItMe = item.id === meetOwnerId || (item.id === currentUser?.id && !directorId);
    const isItemAddedByMe = isDeletable || isCheckedByCurrentUser;

    if (isItMe || (!isItemAddedByMe && !isUncheckable && isAdded && !isUserHasEditPermissions)) return true;

    return false;
  }, [
    item.id,
    meetOwnerId,
    currentUser?.id,
    directorId,
    isUserHasEditPermissions,
    isAdded,
    isCheckedByCurrentUser,
    isDeletable,
    isUncheckable,
  ]);
  // @ts-ignore
  const isEditableExternalUser = useMemo(() => item.userId === currentUser?.id && !isDeletable, [item, currentUser?.id, isDeletable]);

  const handleCheckBox = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (isCheckboxDisabled || isGroupAll) return;

      setIsCheckedByCurrentUser((prev) => !prev);

      onPress?.(item);
      onSave?.(item, isExternalUser);
    },
    [isCheckboxDisabled, onPress, item, onSave, isExternalUser],
  );

  const pressImg = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSelectUser?.(item as IStateUser);
  };

  const borderColorStatus = useMemo(() => (!isExternalUser && item?.busy ? Colors.LIGHT.red : Colors.LIGHT.green), [item, isExternalUser]);

  const renderName = (user: IStateUser | IExternalUser | null | undefined) => `${user?.lastName} ${user?.firstName}`;

  // @ts-ignore
  const isGroupAll = item?.type === "ALL_IN_SPACE";

  const handleEditExternalUser = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (isExternalUser && setCurrentEditableUser) {
      setCurrentEditableUser(item);
    }
  };

  const handleOnContextMenu = (e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    if (isCheckboxDisabled || isGroupAll) return;

    isEditableExternalUser && onRightClick(e, item);
  };

  return (
    <StItemButtonBtn hover={hover} onClick={handleCheckBox} onContextMenu={handleOnContextMenu}>
      <StContentWrapDiv>
        {isRenderImg && !isExternalUser && (
          <button onClick={pressImg}>
            <UserAvatar
              avatarFileId={item.avatarFileId}
              style={{
                ...(isAdded && { opacity: 0.5 }),
                ...(isBusy && { border: `2px solid ${borderColorStatus}` }),
                ...(isItemChecked && { opacity: 0.5 }),
              }}
            />
          </button>
        )}

        <RenderWithCondition condition={isExternalUser && !isGroupAll}>
          <LetterAvatar userName={item?.firstName && item?.firstName[0]?.toUpperCase()} translucent={isItemChecked} />
        </RenderWithCondition>
        <RenderWithCondition condition={isGroupAll}>
          <StIconWrapDiv>
            <SvgPeopleWrapper fill={Colors.LIGHT.orange} />
          </StIconWrapDiv>
        </RenderWithCondition>

        <StBlockTextDiv>
          <StTextUserNameTF
            size={16}
            weight="700"
            color={isItemChecked && Colors.LIGHT.grey}
            isLineThrough={(item as IExternalUser).deleted}
          >
            {isGroupAll ? 'Выбрана группа "ВСЕ"' : renderName(item)}
          </StTextUserNameTF>

          <RenderWithCondition condition={isExternalUser ? item.email : item.workPosition}>
            <StTextUserWorkPositionTF size={14} color={Colors.LIGHT.placeholder.main}>
              {isExternalUser ? item.email : item?.workPosition}
            </StTextUserWorkPositionTF>
          </RenderWithCondition>
        </StBlockTextDiv>

        <RenderWithCondition condition={isExternalUser}>
          <button style={{ justifyContent: "center" }} onClick={handleEditExternalUser}>
            <SvgEdit />
          </button>
        </RenderWithCondition>

        <RenderWithCondition condition={isRenderCheckbox && !isGroupAll}>
          <Checkbox onChange={handleCheckBox} isChecked={isItemChecked} disabled={isCheckboxDisabled} />
        </RenderWithCondition>
      </StContentWrapDiv>
      <RenderWithCondition condition={isAdded && !isRenderImg}>
        <SvgCheck />
      </RenderWithCondition>
    </StItemButtonBtn>
  );
},
arePropsEqual);

const StItemButtonBtn = styled.button<{ hover: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: ${Colors.LIGHT.white};
  padding: 8px 12px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 10px;

  &:hover {
    ${(props) => props.hover && { "background-color": props.hover }}
  }
`;
const StContentWrapDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
const StIconWrapDiv = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const StBlockTextDiv = styled.div`
  flex: 1;
  overflow: hidden;
`;

const StTextUserNameTF = styled(TextFont)<{ isLineThrough: boolean }>`
  text-align: left;

  ${({ isLineThrough }) =>
    isLineThrough &&
    `
    text-decoration: line-through;
  `};
`;
const StTextUserWorkPositionTF = styled(TextFont)`
  line-height: 24px;
  height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
`;
