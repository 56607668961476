import React, { memo, FC, useEffect, useState, useMemo, useCallback, useRef } from "react";

import dayjs, { Dayjs } from "dayjs";
import { FormattedMessage } from "react-intl";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ITask } from "@interfaces/businessGoals.interface";
import { IControlTask } from "@interfaces/controlTask.interface";
import { api, StoreTagTypes } from "@services/api";
import { useCreateTaskMutation, useUpdateTaskMutation } from "@services/taskApi";
import { Calendar } from "@shared/planningWork";
import { AttachEventsEventType, attachEvents } from "@store/attachedMeetingTasks/api";
import { selectEventsMeta } from "@store/attachedMeetingTasks/selectors";
import { selectCurrentDate } from "@store/screenDay";
import { ButtonMain, Spacer } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";
import { showToast } from "@utils/toast";

import { generateWorkDays } from "./utils";

interface IModalControlDelegateProps {
  deadline?: string;
  delegatedTaskData: ITask;
  isDelegatedTaskSaved?: boolean;
  isNeedUpdateTask?: boolean;
  close: () => void;
  closeParent: () => void;
  onSave?: () => void;
  delegateTask?: () => void;
}

type TDay = {
  date: string;
};

interface ISelectedDaysListProps {
  days: TDay[];
}

const SelectedDaysList: FC<ISelectedDaysListProps> = ({ days }) => {
  const totalIndexes = days.length - 1;

  const sortedDays = useMemo(
    () =>
      days.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;

        return 0;
      }),
    [days],
  );

  const renderSelectedDays = useMemo(
    () =>
      sortedDays.reduce((accumulator, item, index) => {
        const formattedDate = dayjs(item.date).format("DD.MM");
        return accumulator + formattedDate + (totalIndexes !== index ? ", " : "");
      }, "Контроль "),
    [sortedDays, totalIndexes],
  );

  return (
    <>
      <TextFont size={18} weight="700">
        Что получилось:
      </TextFont>
      <Spacer px={18} />
      <TextFont size={18}>{renderSelectedDays}</TextFont>
    </>
  );
};

export const ModalControlDelegate: FC<IModalControlDelegateProps> = memo(function ModalControlDelegate({
  deadline,
  delegatedTaskData,
  isDelegatedTaskSaved,
  close,
  closeParent,
  isNeedUpdateTask,
  delegateTask,
}: IModalControlDelegateProps) {
  const dispatch = useDispatch();
  const controlTaskData = useRef<IControlTask>();

  const startDays: TDay[] = [];
  const [days, setDays] = useState(startDays ?? []);
  const [isCreatingControlTask, setIsCreatingControlTask] = useState(false);
  // ToDo: выяснить почему тоаст отображается за модалкой, если не использовать рендер через стейт
  const [displayToast, setDisplayToast] = useState(false);

  const currentDay = dayjs(new Date()).toISOString();
  const dateNow = useSelector(selectCurrentDate);
  const attachEventsMeta = useSelector(selectEventsMeta);

  const [create, { data: responseCreate, isLoading: isLoadingTask }] = useCreateTaskMutation();
  const [update] = useUpdateTaskMutation();

  useEffect(() => {
    deadline && setDays([{ date: dayjs(deadline).format("YYYY-MM-DD") }]);
  }, []);

  useEffect(() => {
    controlTaskData.current = controlTaskTemplate;
  }, []);

  useEffect(() => {
    displayToast && showToast("selectDayToControl");
    setDisplayToast(false);
  }, [displayToast]);

  const handleCalendarDayPress = (dateWeek: Dayjs) => {
    const isSelect = !!days.find((i: any) => i.date === dateWeek.format("YYYY-MM-DD"));

    isSelect ? handleRemoveDay(dateWeek) : handleAddDay(dateWeek);
  };

  const handleAddDay = (date: Dayjs) => {
    const index = startDays.findIndex((i) => i.date === date.format("YYYY-MM-DD"));
    if (index !== -1) {
      setDays([...days, startDays[index]]);
    } else {
      setDays([...days, { date: date.format("YYYY-MM-DD") }]);
    }
  };

  const handleRemoveDay = (date: Dayjs) => {
    setDays([...days.filter((i: TDay) => i.date !== date.format("YYYY-MM-DD"))]);
  };

  const createDelegatedTask = async () => {
    try {
      await create({
        body: { ...delegatedTaskData },
        isAdd: dateNow.isSame(delegatedTaskData.startDate, "date"),
        isPersonalTask: false,
        type: "",
      });

      return responseCreate;
    } catch (e) {
      showToast("somethingWentWrong", "error");
    }
  };

  const saveControlDelegatedTask = useCallback(async () => {
    if (!days.length) {
      setDisplayToast(true);
      return;
    }

    setIsCreatingControlTask(true);
    let createDelegatedTaskResponse = delegatedTaskData;

    if (!isDelegatedTaskSaved) {
      createDelegatedTaskResponse = (await createDelegatedTask()) as ITask;
    }

    const controlTask = {
      ...controlTaskData.current,
      name: `Контроль: ${controlTaskData?.current?.name}`,
      type: "CONTROL",
      controlledTaskId: createDelegatedTaskResponse.id,
      workDays: generateWorkDays(days),
    };

    if (isNeedUpdateTask) {
      await update({
        id: delegatedTaskData.id,
        isPersonalTask: delegatedTaskData.subType === "PERSONAL",
        data: delegatedTaskData,
      });
    }

    delegateTask && delegateTask();

    await create({ body: { ...controlTask } as unknown as ITask, type: "control", isPersonalTask: false });

    closeParent && closeParent();
    close();
  }, [controlTaskData.current, days]);

  const skipControlDelegation = async () => {
    if (!isDelegatedTaskSaved) {
      await createDelegatedTask();
    }

    if (attachEventsMeta.length && delegatedTaskData?.id) {
      await dispatch(
        attachEvents({
          eventId: delegatedTaskData.id,
          eventsMeta: attachEventsMeta,
          eventType: AttachEventsEventType.MeetingsToTask,
        }),
      );
      dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Task, id: delegatedTaskData.id }]));
    }

    if (isNeedUpdateTask) {
      await update({
        id: delegatedTaskData.id,
        isPersonalTask: delegatedTaskData.subType === "PERSONAL",
        data: delegatedTaskData,
      });
    }

    delegateTask && delegateTask();

    closeParent && closeParent();
    close();
  };

  const controlTaskTemplate: IControlTask = useMemo(
    () => ({
      controlledTaskId: "",
      name: delegatedTaskData.name,
      priority: delegatedTaskData.priority,
      startDate: delegatedTaskData.startDate,
      plannedEndDate: delegatedTaskData.plannedEndDate,
      projectId: delegatedTaskData.projectId,
      businessGoalId: delegatedTaskData.businessGoalId,
      workDays: delegatedTaskData.workDays,
      tagId: delegatedTaskData?.tagId ?? null,
      delegatedTo: delegatedTaskData.delegatedTo,
      unplanned: delegatedTaskData?.unplanned ?? true,
      attachedMeetings: delegatedTaskData?.attachedMeetings ?? [],
      fileIds: delegatedTaskData?.fileIds ?? [],
    }),
    [delegatedTaskData],
  );

  return (
    <ModalUI isVisible={true} onClose={close}>
      <HeaderModal title="modalControlDelegateTitle" leftSide={{ isHideCancel: true, onPressClose: close }} />
      <StContentWrapDiv>
        <TextFont size={18} weight="700">
          <FormattedMessage id="selectControlDays" defaultMessage="Выберете дни контроля делегированной задачи" />
        </TextFont>

        <StCalendarWrapDiv>
          <Calendar
            days={days}
            currentDay={currentDay}
            startDay={currentDay}
            press={handleCalendarDayPress}
            type={"multituple"}
            highlightedDay={deadline}
          />
        </StCalendarWrapDiv>

        {!!days.length && <SelectedDaysList days={days} />}

        <StButtonMainCustom
          disabled={isLoadingTask}
          isActive={!!days.length}
          isCreatingControlTask={isCreatingControlTask}
          onClick={saveControlDelegatedTask}
        >
          {isCreatingControlTask ? (
            <div>
              <ReactLoading type="spokes" color={Colors.LIGHT.green} height={25} width={25} />
            </div>
          ) : (
            <TextFont size={18} weight="700" color={Colors.LIGHT.white}>
              <FormattedMessage id="createControlTask" defaultMessage={"Создать задачу контроля"} />
            </TextFont>
          )}
        </StButtonMainCustom>

        <StButtonMainCustom isActive={false} onClick={skipControlDelegation} disabled={isCreatingControlTask}>
          {isLoadingTask && !isCreatingControlTask ? (
            <ReactLoading type="spokes" color={Colors.LIGHT.green} height={25} width={25} />
          ) : (
            <TextFont size={18} weight="700" color={Colors.LIGHT.text.grey}>
              <FormattedMessage id="notControlTask" defaultMessage={"Не контролировать делегированную задачу"} />
            </TextFont>
          )}
        </StButtonMainCustom>
      </StContentWrapDiv>
    </ModalUI>
  );
});

const StContentWrapDiv = styled.div`
  flex: 1;
  padding: 9px 12px 12px 12px;
`;
const StCalendarWrapDiv = styled.div`
  margin: 10px 0;
`;
const StButtonMainCustom = styled(ButtonMain)<{ isActive: boolean; isCreatingControlTask?: boolean }>`
  margin: 20px 0 10px;
  width: 100%;

  ${({ isActive }) =>
    !isActive &&
    `
  background-color: ${Colors.LIGHT.lighGrey};
    
  `}
  ${({ isCreatingControlTask }) =>
    isCreatingControlTask &&
    `
  background-color: ${Colors.LIGHT.lighGrey};
  height: 52.5px;
    
  `}
`;
