import React, { useState, MouseEvent } from "react";

import { isEqual } from "lodash";
import { DragDropContext, Droppable, Draggable, DragDropContextProps } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";

import { IGoal } from "@interfaces/businessGoals.interface";
import { updateList } from "@store/businessGoals/api";
import { setOpenContextData } from "@store/screenDay/slice";

import { Buttons } from "./Buttons";
import { GoalItem } from "./GoalItem";

interface IList {
  data: IGoal[];
  setCurrentGoal: (id: string) => void;
}

export const List = ({ data, setCurrentGoal }: IList) => {
  const dispatch = useDispatch();

  const [actionsId, setActionsId] = useState("");
  const [pos, setPos] = useState({ left: 0, top: 0 });

  const handleRightClick = (e: MouseEvent<HTMLButtonElement>, taskId: string) => {
    e.preventDefault();
    setActionsId(taskId);
    setPos({ top: e.clientY, left: e.clientX });
  };

  const onDragEnd = (dragDropProps: DragDropContextProps) => {
    // @ts-ignore
    if (!dragDropProps.destination || !dragDropProps.source) return;

    const result = Array.from(data);
    // @ts-ignore
    const [removed] = result.splice(dragDropProps.source.index, 1);
    // @ts-ignore
    result.splice(dragDropProps.destination.index, 0, removed);

    if (!isEqual(result, data)) {
      dispatch(updateList(result.map((goal, index) => ({ ...goal, position: index }))));
    }
  };

  document.addEventListener("click", (e) => {
    dispatch(setOpenContextData(null));
    setActionsId("");
  });

  return (
    // @ts-ignore
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {data.map((element, index) => (
              <Draggable key={element.id} draggableId={element.id} index={index}>
                {(providedItem, snapshot) => (
                  <>
                    <GoalItem
                      setCurrentGoal={setCurrentGoal}
                      item={element}
                      index={index}
                      onRightClick={handleRightClick}
                      // @ts-ignore
                      innerRef={providedItem.innerRef}
                      provided={providedItem}
                      snapshot={snapshot}
                    />
                    <Buttons goal={element} isVisible={actionsId === element.id} pos={pos} />
                  </>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
