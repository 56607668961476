import React, { useState } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { IFilter, TypeGroupedByTasks } from "@interfaces/businessTask.interface";
import { selectUserPermissoins } from "@store/user";
import { ButtonMain, Spacer, boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont, ModalUI, HeaderModal } from "@ui";

import { GroupingTabs, TypeGroupTab } from "./assets/constants";

interface IProps {
  isVisible: boolean;
  closeEvent: () => void;
  applyEvent: (arg1: Partial<IFilter>, arg2?: () => void) => void;
  groupByType: TypeGroupedByTasks;
}

export const GroupingModal = ({ isVisible, closeEvent, applyEvent, groupByType }: IProps) => {
  const [localGroup, setLocalGroup] = useState(groupByType);
  const isEdit = localGroup !== groupByType;

  const permissions = useSelector(selectUserPermissoins);

  const handleSave = () => {
    applyEvent({ groupByType: localGroup }, closeEvent);
  };

  const isValidGroup = (group: TypeGroupTab) => {
    switch (group) {
      case "PROJECT": {
        return Boolean(permissions.WORK_WITH_SPACE_PROJECTS);
      }
      default: {
        return true;
      }
    }
  };

  return (
    <ModalUI isVisible={isVisible} onClose={closeEvent}>
      <StContentWrapDiv>
        <div>
          <HeaderModal
            title="order"
            leftSide={{ onPressClose: closeEvent, isHideCancel: true }}
            rightSide={{ title: "apply", onPress: handleSave }}
            isEdit={isEdit}
          />
          <StBlockButtonsDiv>
            <TextFont type="bold" size={18} lineHeight={24}>
              <FormattedMessage id={"Сгруппировать"} />
            </TextFont>
            <Spacer px={10} />
            <StLineButtonsDiv>
              {Object.keys(GroupingTabs).map((element, index) => (
                <RenderWithCondition key={element} condition={isValidGroup(GroupingTabs[element])}>
                  <StButtonGroupedBtn
                    key={element + index}
                    onClick={() => setLocalGroup(GroupingTabs[element])}
                    backColor={localGroup === GroupingTabs[element]}
                  >
                    <TextFont>
                      <FormattedMessage id={element} />
                    </TextFont>
                  </StButtonGroupedBtn>
                </RenderWithCondition>
              ))}
            </StLineButtonsDiv>
          </StBlockButtonsDiv>
        </div>
        <StButtonMainCustom onClick={handleSave} disabled={!isEdit}>
          <TextFont size={18} weight="700" color={Colors.LIGHT.white}>
            <FormattedMessage id={"apply"} />
          </TextFont>
        </StButtonMainCustom>
      </StContentWrapDiv>
    </ModalUI>
  );
};

const StContentWrapDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;
const StLineButtonsDiv = styled.div`
  ${boxCenterCss};
  justify-content: space-between;
  flex-wrap: wrap;
`;
const StBlockButtonsDiv = styled.div`
  padding: 0 12px;
  margin-top: 40px;
`;
const StButtonGroupedBtn = styled.button<{ backColor: boolean }>`
  ${boxCenterCss};
  height: 42px;
  width: 48.5%;
  padding: 0 10px;
  margin-bottom: 10px;
  background-color: ${Colors.LIGHT.lighGrey};
  border-radius: 5px;
  border: 1px solid ${Colors.LIGHT.lighGrey};
  border-color: ${({ backColor }) => (backColor ? `${Colors.LIGHT.text.accent}` : `${Colors.LIGHT.lighGrey}`)};
`;
const StButtonMainCustom = styled(ButtonMain)`
  margin: 10px 12px 20px 12px;
  background-color: ${(props) => (props.disabled ? Colors.LIGHT.disabled : Colors.LIGHT.background.green)};
`;
