import React, { useEffect, useMemo, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { useInfoTask } from "@hooks/useInfoTask";
import { ITask } from "@interfaces/businessGoals.interface";
import { useWorkDaysController } from "@screens/day/components/ListTasks/Modal/components/addWorkDaysReminder";
import {
  useAddWorkDayTaskMutation,
  useCompleteTaskMutation,
  useMoveDateTaskMutation,
  useReopenTaskMutation,
  useTakeMeTaskMutation,
  useWorkDayCancelCompleteTaskMutation,
  useWorkDayCompleteTaskMutation,
} from "@services/taskApi";
import { TypeDisabled } from "@shared/calendar";
import { TaskSubtypes } from "@store/businessTask";
import { selectConfigure } from "@store/configure";
import { selectCurrentDate } from "@store/screenDay";
import { selectUserPermissoins } from "@store/user";
import { IButton, KeysButtons, TaskActions } from "@ui/taskActions";
import { toFormatDate, showToast } from "@utils";
import { shouldShowWorkdayReminder } from "@utils/shouldShowWorkdayReminder";

import { Modal } from "../../../header/modal/Modal";
import { ModalDelegate } from "../../components/modalDelegate/ModalDelegate";

interface IButtons {
  item: ITask;
  isVisible: boolean;
  pos: { left: number; top: number };
}

export const Buttons = ({ item, pos, isVisible }: IButtons) => {
  const { workDay } = useInfoTask(item);

  const [isVisibleCalendar, setIsVisibleCalendar] = useState(false);
  const [isMove, setIsMove] = useState(false);
  const [isExtraTime, setIsExtraTime] = useState(false);
  const [isDelegate, setIsDelegate] = useState(false);
  const [isAddWorkDayReminderVisible, setIsWorkDayReminderVisible] = useState(false);
  const isPersonalTask = item.subType === TaskSubtypes.Personal;

  const dateTrue = useSelector(selectCurrentDate);
  const permissions = useSelector(selectUserPermissoins);
  const {
    settings: { isTightMode },
  } = useSelector(selectConfigure);

  const [addWorkDay] = useAddWorkDayTaskMutation();
  const [complete] = useCompleteTaskMutation();
  const [moveDate] = useMoveDateTaskMutation();
  const [reopen] = useReopenTaskMutation();
  const [takeMe] = useTakeMeTaskMutation();
  const [workDayCancelComplete] = useWorkDayCancelCompleteTaskMutation();
  const [workDayComplete] = useWorkDayCompleteTaskMutation();

  useEffect(() => {
    setIsVisibleCalendar(isMove || isExtraTime);
  }, [isMove, isExtraTime]);

  useEffect(() => {
    if (!isVisibleCalendar) {
      setIsMove(false);
      setIsExtraTime(false);
    }
  }, [isVisibleCalendar]);

  const [WorkDaysModals] = useWorkDaysController({
    task: item,
    isPersonalTask,
    isAddWorkDayReminderVisible,
    setIsWorkDayReminderVisible,
  });

  const handleReady = () => {
    complete({ id: item.id, isPersonalTask });
  };

  const handleReturnToWork = () => {
    reopen({ id: item.id, isPersonalTask });
    setIsWorkDayReminderVisible(true);
  };

  const handlePressCalendar = (dateWeek: Dayjs) => {
    if (isMove) {
      return handlePressMove(dateWeek);
    }
    return handlePressExtraTime(dateWeek);
  };

  const handlePressMove = (dateWeek: Dayjs) => {
    moveDate({ id: item.id, date: toFormatDate(dateTrue), newDate: toFormatDate(dateWeek), isPersonalTask });
    setIsMove(false);
  };

  const handlePressTakeBack = () => {
    takeMe({ id: item.id });

    if (shouldShowWorkdayReminder(item.workDays)) {
      setIsWorkDayReminderVisible(true);
    }
  };

  const handlePressDealWith = async () => {
    await workDayComplete({ id: item.id, date: toFormatDate(dateTrue), isPersonalTask });
  };

  const handlePressCancelDealWith = async () => {
    workDayCancelComplete({ id: item.id, date: toFormatDate(dateTrue), isPersonalTask });
  };

  const handlePressExtraTime = async (dateWeek: Dayjs) => {
    const body = { currentDate: toFormatDate(dateTrue), additionalDate: toFormatDate(dateWeek) };
    await addWorkDay({ id: item.id, body, isPersonalTask }).then(() => setIsExtraTime(false));
  };

  const handlePresses = (type: KeysButtons) => {
    const callbacks: Partial<Record<KeysButtons, () => void>> = {
      cancelDealWith: handlePressCancelDealWith,
      dealWith: handlePressDealWith,
      needMoreTime: () => setIsExtraTime(true),
      takeBack: handlePressTakeBack,
      delegate: () => setIsDelegate(true),
      transfer: () => setIsMove(true),
      ready: handleReady,
      returnToWork: handleReturnToWork,
    };

    callbacks[type]();
  };

  const typeDisabled = useMemo((): TypeDisabled => {
    if (isMove) return undefined;
    return "before";
  }, [isMove]);

  const onPressDisabled = () => {
    showToast(msgParentExtraTime);
  };

  const isFutureDay = useMemo(() => dateTrue.isAfter(toFormatDate(dayjs()), "day"), [dateTrue]);

  const leftButton = useMemo((): Partial<Record<KeysButtons, IButton>> => {
    let key: KeysButtons = "dealWith";

    if (item.status === "COMPLETED") {
      key = "returnToWork";
    } else if (workDay?.isCompleted) {
      key = "cancelDealWith";
    } else if (item.workDays.length === 1) {
      key = "ready";
    } else {
      key = "dealWith";
    }

    if (!isTightMode)
      return {
        [key]: { onClick: () => handlePresses(key), disabled: isFutureDay, toast: "futureActionUnavailable" },
      };

    return {};
  }, [isFutureDay, isTightMode, item.status, item.workDays.length, workDay?.isCompleted]);

  const delegatedButton = useMemo(() => {
    const key = item.delegatedToUser ? "takeBack" : "delegate";

    if (permissions.CAN_DELEGATE && item.type === "TASK" && item.subType !== TaskSubtypes.Personal) {
      return { [key]: { onClick: () => handlePresses(key) } };
    }
    return {};
  }, [item.delegatedToUser, permissions.CAN_DELEGATE]);

  const config = useMemo(
    (): Partial<Record<KeysButtons, IButton>> => ({
      ...leftButton,
      needMoreTime: { onClick: () => handlePresses("needMoreTime"), disabled: isFutureDay, toast: "futureActionUnavailable" },
      ...delegatedButton,
      transfer: { onClick: () => handlePresses("transfer") },
    }),
    [delegatedButton, leftButton, isFutureDay],
  );

  const isDelegateVisible = useMemo(() => permissions.CAN_DELEGATE && item.type !== "CONTROL", [item.type, permissions.CAN_DELEGATE]);

  const msgParentExtraTime = useIntl().formatMessage({ id: "pastNeedMoreTime" });

  return (
    <>
      <RenderWithCondition condition={isVisible}>
        <TaskActions pos={pos} configuration={config} />
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isDelegate && isDelegateVisible}>
        <ModalDelegate close={() => setIsDelegate(false)} delegatedToUser={item.delegatedToUser} task={item} />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={isVisibleCalendar}>
        <Modal
          isVisible={isVisibleCalendar}
          setIsVisible={setIsVisibleCalendar}
          press={handlePressCalendar}
          typeDisabled={typeDisabled}
          onPressDisabled={onPressDisabled}
          isOnlyMonthMode
        />
      </ModalRenderWithCondition>
      {WorkDaysModals}
    </>
  );
};
