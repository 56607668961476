import React, { Dispatch, FC, SetStateAction } from "react";

import { FormattedMessage } from "react-intl";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import { ReactComponent as SvgQuestion } from "../../../../assets/question.svg";

interface IPersonalTaskCheckBox {
  onValueChange: Dispatch<SetStateAction<boolean>>;
  isChecked: boolean;
}

export const PersonalTaskCheckbox: FC<IPersonalTaskCheckBox> = ({ isChecked = false, onValueChange }) => (
  <StContentWrapDiv>
    <StContentDiv>
      <StCheckBoxPersonalTaskInput
        type="checkbox"
        onChange={() => onValueChange(!isChecked)}
        checked={isChecked}
        className="input purpleCheckbox"
      />
      <TextFont type="regular" size={16}>
        <FormattedMessage id={"personalTask"} />
      </TextFont>

      <StSvgQuestionCustom data-tooltip-id="tooltip-personal-task" />
    </StContentDiv>

    <Tooltip id="tooltip-personal-task" style={tooltipStyle} place="top-end">
      <TextFont weight="500" size={12}>
        <FormattedMessage id="personalTaskDescription" />
      </TextFont>
    </Tooltip>
  </StContentWrapDiv>
);

const StContentWrapDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  background-color: ${Colors.LIGHT.white};
  border-radius: 11px;
  margin-top: 8px;
  width: auto;
`;
const StContentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StCheckBoxPersonalTaskInput = styled.input`
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border: 1px solid ${Colors.LIGHT.red};
`;
const StSvgQuestionCustom = styled(SvgQuestion)`
  margin-left: 9px;
  margin-top: 3px;
`;
const tooltipStyle = {
  padding: "10px",
  backgroundColor: Colors.LIGHT.white,
  zIndex: 999,
  width: "300px",
  borderRadius: "10px",
  boxShadow: `0 2px 6px ${Colors.LIGHT.shadowTooltip}`,
};
