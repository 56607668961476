import React from "react";

import styled from "styled-components";

import { Projects } from "@shared/projects";
import { ModalUI, HeaderModal } from "@ui";

interface IProps {
  isVisible: boolean;
  closeEvent: () => void;
}

export const ProjectModal = ({ isVisible, closeEvent }: IProps) => (
  <ModalUI isVisible={isVisible} scrollEnable={false} onClose={closeEvent}>
    <HeaderModal title="project" leftSide={{ onPressClose: closeEvent }} />
    <StProjectsCustom />
  </ModalUI>
);

const StProjectsCustom = styled(Projects)`
  padding-bottom: 20px;
`;
