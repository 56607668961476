import { FC, useMemo } from "react";

import { useSelector } from "react-redux";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { TGradientColor, useAnimatedText } from "@hooks/useAnimatedText";
import { useAutoLink } from "@hooks/useAutoLink";
import { selectIsModeAssistant } from "@store/user";
import { Colors } from "@theme/colors";
import { ArrowSvg } from "@ui";

interface ITaskDescription {
  text: string;
  isPersonalTask?: boolean;
}

export const Description: FC<ITaskDescription> = ({ text, isPersonalTask }) => {
  const { ref: refDesc } = useAutoLink({ text });
  const isModeAssistant = useSelector(selectIsModeAssistant);

  const gradientColor = useMemo((): TGradientColor => {
    if (isPersonalTask) return "VIOLET";
    if (isModeAssistant) return "BROWN";
    return "GREEN";
  }, [isModeAssistant, isPersonalTask]);

  const { isDropdown, isOpen, setIsOpen, LinearGradientComponent, stylesTransition } = useAnimatedText({
    ref: refDesc,
    gradientColor,
  });

  return (
    <>
      <RenderWithCondition condition={text}>
        <StContentWrapDiv>
          <StTextDescriptionSpan
            ref={refDesc}
            isDropdown={isDropdown}
            isOpen={isOpen}
            style={{ ...(isDropdown && { ...stylesTransition }) }}
          >
            {text}
          </StTextDescriptionSpan>
          <RenderWithCondition condition={isDropdown && !isOpen}>{LinearGradientComponent}</RenderWithCondition>
          <RenderWithCondition condition={isDropdown}>
            <StButtonDropBtn onClick={() => setIsOpen(!isOpen)}>
              <ArrowSvg type={isOpen ? "up" : "down"} fill={Colors.LIGHT.background.grey} width={26} height={26} />
            </StButtonDropBtn>
          </RenderWithCondition>
        </StContentWrapDiv>
      </RenderWithCondition>
    </>
  );
};

const StContentWrapDiv = styled.div`
  display: flex;
  position: relative;
`;
const StTextDescriptionSpan = styled.span<{ isDropdown: boolean; isOpen: boolean }>`
  color: ${Colors.LIGHT.white};
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
  font-family: Roboto-Regular, sans-serif;
  word-wrap: break-word;
  white-space: pre-line;
  width: ${({ isDropdown }) => (isDropdown ? "90%" : "100%")};
  ${({ isDropdown }) =>
    isDropdown &&
    `

height: 72px;`}
  ${({ isDropdown, isOpen }) =>
    isDropdown &&
    isOpen &&
    `

height:  auto; `}
`;

const StButtonDropBtn = styled.button`
  align-items: flex-end;
  margin-left: 8px;
`;
