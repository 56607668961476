import { IMeetParticipants } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";

export const convertIStateUserIntoMeetParticipant = (user: IStateUser | IMeetParticipants): IMeetParticipants => {
  const userId = (user as unknown as IMeetParticipants)?.user?.id ?? user.id;

  return {
    // @ts-ignore
    user: { ...user, workPosition: (user as IStateUser)?.workPosition ?? null },
    userId,
    isExternalUser: false,
    meetingStatus: null,
    status: (user as unknown as IMeetParticipants)?.status ?? null,
    id: userId,
    statusComment: null,
    statusCommentIsViewed: false,
    permissions: [],
  };
};
