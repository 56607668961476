import React, { useMemo, useState } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { LineHorizontal } from "@components/shared";
import { RenderWithCondition } from "@hoc";
import { ButtonMain, RoundButton, Spacer, TextareaComment } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont, Billet } from "@ui";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";

import { reasonTabs, TypeReason } from "../../Modal/components/viewSave/assets/constants";

interface IProps {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  onSave?: (reason: TypeReason, comment: string) => void;
  tabsReason?: Record<string, TypeReason>;
  defaultReason?: TypeReason;
  isButton?: boolean;
  title?: string;
  bgTab?: string;
  isTight?: boolean;
}

export const ModalClose = ({
  isVisible,
  setIsVisible,
  isButton = true,
  onSave = () => "",
  defaultReason = reasonTabs.notRelevant,
  tabsReason = reasonTabs,
  title = "Закрыть задачу",
  bgTab = Colors.LIGHT.white,
  isTight = false,
}: IProps) => {
  const [isShown, setIsShown] = useState(false);

  const [reason, setReason] = useState<TypeReason>(defaultReason);
  const [comment, setComment] = useState("");

  const handleSelect = (value: TypeReason) => {
    setReason(value);
    setIsShown(false);
  };

  const handleSave = () => {
    if (onSave) {
      onSave(reason, comment);
    }
    setIsVisible(false);
  };

  const validReason = useMemo(() => {
    for (const value in tabsReason) {
      if (tabsReason[value] === reason) return value;
    }
  }, [reason, tabsReason]);

  return (
    <ModalUI isVisible={isVisible} onClose={() => setIsVisible(false)}>
      <div onClick={(e) => e.stopPropagation()}>
        <HeaderModal
          title={title}
          leftSide={{ isHideCancel: true, onPressClose: () => setIsVisible(false) }}
          rightSide={{ onPress: handleSave }}
          styleContainer={{ borderBottomWidth: 0, ...(!isTight && { marginBottom: 20 }) }}
        />
        <StContentDiv>
          <RenderWithCondition condition={!isTight}>
            <TextFont type="bold" size={18} weight="700">
              <FormattedMessage id={"reason"} />
            </TextFont>
          </RenderWithCondition>
          <StBilletCustom
            textId={validReason}
            onPress={() => setIsShown(true)}
            backColor={bgTab}
            isTight={isTight}
            styleText={textButtonStyle}
          />
          <StTextareaCommentCustom
            backColor={bgTab}
            isTight={isTight}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder={"Комментарий"}
            autoFocus={isTight}
            maxLength={100}
          />
          <RenderWithCondition condition={isButton}>
            <StButtonMainCustom onClick={handleSave}>
              <TextFont size={18} weight="700" color={Colors.LIGHT.white}>
                <FormattedMessage id={"save"} />
              </TextFont>
            </StButtonMainCustom>
          </RenderWithCondition>
        </StContentDiv>
      </div>
      {isShown ? (
        <ModalUI isVisible={isShown} onClose={() => setIsShown(false)} className="bottomModal">
          <div onClick={(e) => e.stopPropagation()}>
            <RoundButton top={15} onClick={() => setIsShown(false)}>
              <SvgClose />
            </RoundButton>
            <Spacer px={50} />
            <div>
              {Object.keys(tabsReason).map((element) => (
                <StItemWrapDiv key={element} isActive={reason === tabsReason[element]}>
                  <StButtonItemBtn onClick={() => handleSelect(tabsReason[element])}>
                    <TextFont>
                      <FormattedMessage id={element} />
                    </TextFont>
                  </StButtonItemBtn>
                  <LineHorizontal />
                </StItemWrapDiv>
              ))}
            </div>
          </div>
        </ModalUI>
      ) : (
        ""
      )}
    </ModalUI>
  );
};

const StContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
`;
const StTextareaCommentCustom = styled(TextareaComment)<{ backColor: string; isTight: boolean }>`
  ${({ backColor }) =>
    backColor &&
    `
    background-color: ${backColor};
    `}
  ${({ isTight }) =>
    isTight &&
    `
    margin-bottom: 9px;
    min-height: 48px;
    `}
`;
const StBilletCustom = styled(Billet)<{ backColor: string; isTight: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  border-radius: 11px;
  background-color: ${Colors.LIGHT.white};
  margin-bottom: 20px;
  min-height: 48px;
  ${({ backColor }) =>
    backColor &&
    `
    background-color: ${backColor};
    `}
  ${({ isTight }) =>
    isTight &&
    `
    margin-bottom: 9px;
    min-height: 48px;
    `}
`;
const StButtonMainCustom = styled(ButtonMain)`
  margin: 20px 0 10px;
`;
const StItemWrapDiv = styled.div<{ isActive: boolean }>`
  ${({ isActive }) =>
    isActive &&
    `
    background-color: ${Colors.LIGHT.disabled};
    `}
`;
const StButtonItemBtn = styled.button`
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: center;
  padding: 0 12px;
  width: 100%;
`;

const textButtonStyle = {
  fontWeight: "400",
};
