import React, { FC, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { selectSpecialTag } from "@store/tag";
import { ButtonBillet } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont, ArrowSvg } from "@ui";

import { CommonFields } from "../types";

import { ModalTag } from "./ModalTag";

interface IProps extends CommonFields {
  id: string;
}

export const Tag: FC<IProps> = ({ id, handleData }) => {
  const [isVisibleTag, setIsVisibleTag] = useState(false);

  const currentTag = useSelector(selectSpecialTag(id));

  return (
    <>
      <ButtonBillet onClick={() => setIsVisibleTag(true)}>
        <TextFont size={16}>
          <FormattedMessage id="tag" />
        </TextFont>
        <StIconWrapDiv>
          <TextFont color={Colors.LIGHT.placeholder.main}>{currentTag?.name}</TextFont>
          <ArrowSvg type="right" fill={Colors.LIGHT.placeholder.main} width={26} height={26} />
        </StIconWrapDiv>
      </ButtonBillet>
      <RenderWithCondition condition={isVisibleTag}>
        <ModalTag isVisible={isVisibleTag} setIsVisible={setIsVisibleTag} currentTagId={currentTag?.id} setTag={handleData} />
      </RenderWithCondition>
    </>
  );
};

const StIconWrapDiv = styled.div`
  display: flex;
  align-items: center;
`;
