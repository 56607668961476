import React, { CSSProperties, ChangeEvent, FC, useMemo, useState } from "react";

import { styled } from "styled-components";

import { ReactComponent as SvgCheck } from "@assets/check.svg";
import { RenderWithCondition } from "@hoc";
import { IStateUser } from "@interfaces/user.interface";
import { LetterAvatar } from "@screens/day/components/timetable/main/modal/components/participants/components";
import { IExternalUser } from "@services/meet.service";
import { userAvatarStyles } from "@shared/userAvatar";
import { UserAvatar } from "@shared/userAvatar/UserAvatar";
import { commonStyles } from "@styles/styles";
import { TextFont, Checkbox } from "@ui";
import globalState from "@utils/globalState";

interface IProps {
  item: IStateUser | IExternalUser;
  setSelectUser?: (arg: IStateUser | undefined) => void;
  onSave: (user: (IStateUser | IExternalUser)[], isExternalUser?: boolean) => void;
  onPress?: (user: IStateUser | IExternalUser) => void;
  isRenderImg?: boolean;
  isAdded: boolean | undefined;
  isBusy?: boolean;
  hover?: string;
  styleButton?: CSSProperties;
  isDisabled?: boolean;
  showAttendanceStatus?: boolean;
}

export const Item: FC<IProps> = ({
  item,
  setSelectUser,
  hover,
  styleButton,
  onSave,
  onPress,
  isBusy = false,
  isRenderImg = true,
  isAdded = false,
  showAttendanceStatus = false,
  isDisabled = false,
}) => {
  const [isItemChecked, setIsItemChecked] = useState<boolean>(isAdded || isDisabled);
  // @ts-ignore
  const isExternalUser = !item?.spaceId || !item?.permissions || !item?.roles;

  const handleTaskCheckBox = (item: IStateUser | IExternalUser) => {
    setIsItemChecked(!isItemChecked);
    onPress && onPress(item);

    if (!isRenderImg) {
      // @ts-ignore
      onSave(item, isExternalUser);
    }
  };

  const pressImg = (e: ChangeEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (setSelectUser) {
      // @ts-ignore
      setSelectUser(item);
    }
  };

  // @ts-ignore
  const borderColorStatus = useMemo(() => (item?.busy ? globalState.colorSchema.red : globalState.colorSchema.green), [item?.busy]);

  const renderName = (user: IStateUser | null | undefined) => `${user?.lastName} ${user?.firstName}`;

  if (!item) return null;

  return (
    <Button hover={hover} style={styleButton} onClick={() => handleTaskCheckBox(item)} disabled={isDisabled}>
      <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
        <RenderWithCondition condition={isRenderImg && !isExternalUser}>
          {/* @ts-ignore */}
          <button onClick={pressImg}>
            <UserAvatar
              // @ts-ignore
              avatarFileId={item.avatarFileId}
              style={{
                ...userAvatarStyles.userImage,
                ...(isAdded && { opacity: 0.5 }),
                ...(isBusy && showAttendanceStatus && { border: `2px solid ${borderColorStatus}` }),
                ...(isItemChecked && { opacity: 0.5 }),
              }}
            />
          </button>
        </RenderWithCondition>

        <RenderWithCondition condition={isExternalUser}>
          <LetterAvatar userName={item?.firstName && item?.firstName[0]?.toUpperCase()} translucent={isItemChecked} />
        </RenderWithCondition>

        <div style={{ flex: 1 }}>
          <TextFont style={{ ...userAvatarStyles.userTitle, ...(isItemChecked && { color: globalState.colorSchema.grey }) }}>
            {/* @ts-ignore */}
            {renderName(item)}
          </TextFont>
          {/* @ts-ignore */}
          <RenderWithCondition condition={item?.workPosition || item.email}>
            <TextFont style={{ fontSize: 14, color: "#B3BEB8", ...commonStyles.oneOfLines }}>
              {/* @ts-ignore */}
              {isExternalUser ? item.email : item?.workPosition}
            </TextFont>
          </RenderWithCondition>
        </div>

        <RenderWithCondition condition={isRenderImg && !isDisabled}>
          <Checkbox onChange={() => handleTaskCheckBox(item)} isChecked={isItemChecked} />
        </RenderWithCondition>
      </div>
      <RenderWithCondition condition={isAdded && !isRenderImg}>
        <SvgCheck />
      </RenderWithCondition>
    </Button>
  );
};

const Button = styled.button<{ hover: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  padding: 8px;
  margin-bottom: 10px;
  width: 100%;

  &:hover {
    ${(props) => props.hover && { "background-color": props.hover }}
  }
`;
