import React, { useMemo } from "react";

import dayjs from "dayjs";
import styled from "styled-components";

import { Colors } from "@theme/colors";

import { WIDTH_BLOCK } from "../constants";

export const BlockTime = ({ time }: { time: { startTime: string; endTime: string } }) => {
  const params = useMemo(() => {
    const widthMinute = WIDTH_BLOCK / 60;
    const width = widthMinute * dayjs(time.endTime).diff(time.startTime, "minutes");
    const left = dayjs(time.startTime).hour() * WIDTH_BLOCK + widthMinute * dayjs(time.startTime).minute();
    const color = Colors.LIGHT.grey400;

    return { width, left, color };
  }, [time.endTime, time.startTime]);

  return <StBlockTimeItemDiv width={params.width} left={params.left} backColor={params.color} />;
};

const StBlockTimeItemDiv = styled.div<{ left: number; width: number; backColor: string }>`
  display: flex;
  height: 100%;
  top: 0;
  position: absolute;
  ${({ left, width, backColor }) => `
  left: ${left}px;
  width: ${width}px;
  background-color: ${backColor}; 
  pointer-events: none;
`}
`;
