import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { api } from "@services/api";

import attachTasksReducer from "./attachedMeetingTasks";
import authReducer from "./auth";
import businessGoalsReducer from "./businessGoals";
import businessTasksReducer from "./businessTask";
import calendarReducer from "./calendar";
import configureReducer from "./configure";
import dateReducer from "./date";
import externalUsersSliceReducer from "./externalUsers";
import fileAttachmentReducer from "./fileAttachments";
import { resetRTKQCacheMiddleWare } from "./middlewares";
import listenerMiddleware from "./middlewares/listener.middleware";
import { NotifyMiddleware } from "./middlewares/notify/notify";
import monthGoalReducer from "./monthGoal";
import noteReducer from "./note";
import notificationReducer from "./notification";
import personalGoalsReducer from "./personalGoals";
import projectReducer from "./project";
import screenDaySliceReducer from "./screenDay";
import sessionReducer from "./session";
import tagReducer from "./tag";
import themeReducer from "./theme";
import userReducer from "./user";
import userSettingsReducer from "./userSettings";

export const rootReducer = {
  reducer: {
    [api.reducerPath]: api.reducer,
    user: userReducer,
    date: dateReducer,
    auth: authReducer,
    businessGoals: businessGoalsReducer,
    personalGoals: personalGoalsReducer,
    configure: configureReducer,
    screenDay: screenDaySliceReducer,
    businessTasks: businessTasksReducer,
    monthGoal: monthGoalReducer,
    note: noteReducer,
    tag: tagReducer,
    project: projectReducer,
    session: sessionReducer,
    calendar: calendarReducer,
    attachTasks: attachTasksReducer,
    fileAttachments: fileAttachmentReducer,
    notifications: notificationReducer,
    userSettings: userSettingsReducer,
    externalUsers: externalUsersSliceReducer,
    theme: themeReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
      composeWithDevTools: true,
    }).concat(api.middleware, resetRTKQCacheMiddleWare, NotifyMiddleware, listenerMiddleware.middleware),
  ],
};

export const store = configureStore(rootReducer);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

setupListeners(store.dispatch);
