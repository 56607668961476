import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { LoaderRenderWithCondition, ModalRenderWithCondition } from "@hoc";
import { useGetContainerDimensions } from "@hooks/useGetContainerDimensions";
import { useSubscribe } from "@hooks/useSubscribe";
import { ITask } from "@interfaces/businessGoals.interface";
import { TSingleSections } from "@interfaces/businessTask.interface";
import { TFirstArgPress } from "@interfaces/eventsOfDay.interface";
import { useGetTaskListQuery, useUpdateTaskPositionMutation } from "@services/taskApi";
import { tightModeElements, ListSingleSection } from "@shared";
import { TaskSubtypes } from "@store/businessTask";
import { convertGroupedTasks, sortTasksForOneS } from "@store/businessTask/utils";
import { selectConfigure } from "@store/configure";
import { selectCurrentDate } from "@store/screenDay";
import { userSelector } from "@store/user";
import { ItemListContentLoader } from "@ui";
import { isTablet, toFormatDate } from "@utils";

import { SwipeListSections } from "./index";

interface IListTasks {
  handlePress?: (event: TFirstArgPress, type: "TASK" | "MEETING", isPersonalTask?: boolean) => void;
  style?: React.CSSProperties;
}

export const ListTasks: FC<IListTasks> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { width: containerWidth, height: containerHeight } = useGetContainerDimensions(containerRef);

  const [isVisibleSingleSection, setIsVisibleSingleSection] = useState<TSingleSections | undefined>(undefined);

  const {
    settings: { isTightMode },
  } = useSelector(selectConfigure);
  const dateNow = useSelector(selectCurrentDate);
  const { directorId } = userSelector();

  const { data: tasks, refetch, isFetching } = useGetTaskListQuery({ params: { date: toFormatDate(dateNow), next: 15, current: 0 } });
  const [moveTasks, delegatedTasks, takenBack] = [
    tasks?.singleSections.transferred.content ?? [],
    tasks?.singleSections.delegated.content ?? [],
    tasks?.singleSections.takenBack.content ?? [],
  ];
  const [updatePosition] = useUpdateTaskPositionMutation();

  useSubscribe(
    "updateTasks",
    () => {
      refetch();
    },
    [dateNow, directorId],
  );

  const onDragEnd = useCallback(
    (dragDropProps, objectKey: string) => {
      if (!dragDropProps.destination || !dragDropProps.source) return;

      const resultArray = Array.from(tasks[objectKey]) as ITask[];
      const [removed] = resultArray.splice(dragDropProps.source.index, 1);
      resultArray.splice(dragDropProps.destination.index, 0, removed);

      if (!isEqual(resultArray, tasks[objectKey])) {
        updatePosition({
          date: toFormatDate(dateNow),
          result: { objectKey, resultArray },
          data: resultArray.map((item: ITask, index) => ({ id: item.id, position: index })),
          isPersonalTask: false,
        });
      }
    },
    [dateNow, tasks],
  );

  const handleClick = (id: string, subType?: TaskSubtypes) => {
    props.handlePress({ id }, "TASK", subType === "PERSONAL");
  };

  const closeListSingleSectionModal = useCallback(() => setIsVisibleSingleSection(undefined), []);

  const isTightModeTablet = useMemo(() => isTightMode && isTablet, [isTightMode]);

  return (
    <StContentWrapDiv style={{ ...props.style }} ref={containerRef} {...props}>
      {isTightModeTablet ? (
        <StContentDiv>{tightModeElements.listTasks}</StContentDiv>
      ) : (
        <LoaderRenderWithCondition
          condition={isFetching && !isVisibleSingleSection}
          loaderComponent={<ItemListContentLoader width={containerWidth} height={containerHeight} />}
        >
          <StContentDiv>
            <SwipeListSections
              allowPressIcon
              onDragEnd={onDragEnd}
              press={handleClick}
              data={{
                transferred: [...moveTasks].slice(0, isTablet ? 4 : 2).sort(sortTasksForOneS),
                delegated: [...delegatedTasks].slice(0, isTablet ? 4 : 2),
                takenBack: [...takenBack].slice(0, isTablet ? 4 : 2),
                ...convertGroupedTasks(tasks?.tasks),
              }}
              showAll={{
                transferred: () => setIsVisibleSingleSection("transferred"),
                delegated: () => setIsVisibleSingleSection("delegated"),
                takenBack: () => setIsVisibleSingleSection("takenBack"),
              }}
            />
          </StContentDiv>
        </LoaderRenderWithCondition>
      )}

      <ModalRenderWithCondition condition={!!isVisibleSingleSection}>
        <ListSingleSection type={isVisibleSingleSection as TSingleSections} close={closeListSingleSectionModal} />
      </ModalRenderWithCondition>
    </StContentWrapDiv>
  );
};

const StContentWrapDiv = styled.div`
  height: 86%;
`;
const StContentDiv = styled.div`
  height: 100%;
  position: relative;
`;
