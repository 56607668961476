import dayjs from "dayjs";

import { ITask, WorkDay } from "@interfaces/businessGoals.interface";
import { TSingleSections, TypeGroupedByTasks } from "@interfaces/businessTask.interface";
import { api, StoreTagTypes } from "@services/api";
import { businessTasks } from "@services/businessTasks.service";
import { ISingleSectionResponse, IAllTaskListResponse, taskApi } from "@services/taskApi";
import { store } from "@store/store";
import { toFormatDate } from "@utils/toFormatTime";

interface IChangeTask {
  id: string;
  task?: Partial<ITask>;
  workDay?: {
    date: string;
    obj: Partial<WorkDay>;
  };
  tasks: ITask[];
}

export const changeTask = ({ task, workDay, id, tasks }: IChangeTask) => {
  const tempTasks = [...tasks];
  const taskIndex = tempTasks.findIndex((item) => item.id === id);
  let tempWorkDays, workDayIndex;

  if (taskIndex === -1) {
    return tasks;
  }

  if (workDay) {
    tempWorkDays = [...(tempTasks[taskIndex].workDays ?? [])];
    workDayIndex = tempWorkDays.findIndex((item) => item.date === workDay?.date);
    tempWorkDays.splice(workDayIndex, 1, { ...tempWorkDays[workDayIndex], ...workDay.obj });
  }

  const data = {
    ...tempTasks[taskIndex],
    ...(task ?? {}),
    workDays: tempWorkDays ?? task?.workDays ?? tempTasks[taskIndex].workDays,
  };
  tempTasks.splice(taskIndex, 1, data);

  return tempTasks;
};

export const sortTasksForOneS = (a: ITask, b: ITask) => {
  if (a.isFromOneS && !b.isFromOneS) return 1;
  if (!a.isFromOneS && b.isFromOneS) return -1;
  return 0;
};

export const afterUpdateTask = async (
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: any,
  isPersonal: boolean,
  type: "all" | "task" | "allTask",
  obj?: {
    workDay?: { date: string; obj: Partial<WorkDay> };
    task?: Partial<ITask>;
  },
): Promise<ITask | undefined> => {
  let responseTask: ITask | undefined = undefined;

  if (!obj) {
    const { data } = isPersonal ? await businessTasks.getPersonal(id) : await businessTasks.get(id);
    responseTask = data;
  }

  dispatch(
    taskApi.util.updateQueryData("getTaskById", { id, isPersonalTask: isPersonal }, (draft: ITask) =>
      !responseTask && obj ? changeTask({ tasks: [draft], workDay: obj.workDay, id, task: obj.task })[0] : responseTask,
    ),
  );

  if (type === "all" || type === "task") {
    dispatch(
      taskApi.util.updateQueryData(
        "getTaskList",
        { params: { date: toFormatDate(store.getState().screenDay.date), current: 0, next: 15 } },
        (draft: { tasks: ITask[]; singleSections: ISingleSectionResponse }) => {
          draft.tasks = changeTask({
            tasks: draft.tasks,
            workDay: obj?.workDay,
            id,
            task: responseTask ? responseTask : obj?.task,
          });
        },
      ),
    );
  }

  if (type === "all" || type === "allTask") {
    dispatch(
      taskApi.util.updateQueryData("getAllTaskList", { filter: store.getState().businessTasks.filter }, (draft: IAllTaskListResponse) => {
        draft.page.content = changeTask({
          tasks: draft.page.content,
          workDay: obj?.workDay,
          id,
          task: responseTask ? responseTask : obj?.task,
        });
      }),
    );
  }

  return responseTask;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const afterUpdateSingleSection = (id: string, dispatch: any) => {
  const dateNow = store.getState().screenDay.date;

  dispatch(
    taskApi.util.updateQueryData(
      "getTaskList",
      { params: { date: toFormatDate(dateNow), current: 0, next: 15 } },
      (draft: { tasks: ITask[]; singleSections: ISingleSectionResponse }) => {
        let type: keyof ISingleSectionResponse | undefined;

        if (draft.singleSections.takenBack.content.findIndex((i: ITask) => i.id === id) !== -1) {
          type = "takenBack";
        }

        if (draft.singleSections.delegated.content.findIndex((i: ITask) => i.id === id) !== -1) {
          type = "delegated";
        }

        if (draft.singleSections.transferred.content.findIndex((i: ITask) => i.id === id) !== -1) {
          type = "transferred";
        }

        if (type) {
          draft.singleSections[type] = {
            content: draft.singleSections[type].content.filter((i) => i.id !== id),
            total: draft.singleSections[type].total - 1,
          };

          if (draft.singleSections[type].content.length < 5 && draft.singleSections[type].total > 4)
            dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Task, id: "TASK_LIST" }]));
        }
      },
    ),
  );
};

export const convertSingleSection = (tasks: ITask[], type: TSingleSections) => {
  const result: Record<string, ITask[]> = {};

  tasks.forEach((i) => {
    const skippedDate = type === "transferred" ? i.skippedDate : toFormatDate(dayjs(i.delegatedFromDateTime ?? i.startDate));

    if (skippedDate) {
      if (!result[skippedDate]) {
        result[skippedDate] = [];
      }
      result[skippedDate].push(i);
    }
  });
  return result;
};

export const convertGroupedTasks = (tasks?: ITask[]) => {
  if (!tasks) return { urgent: [], important: [], contact: [] };

  const important = tasks.filter((item: ITask) => item.priority === "IMPORTANT").sort(sortTasksForOneS);
  const urgent = tasks.filter((item: ITask) => item.priority === "URGENT").sort(sortTasksForOneS);
  const contact = tasks.filter((item: ITask) => item.priority === "CONTACT").sort(sortTasksForOneS);

  return { urgent, important, contact };
};

export const sortingAllTasks = (group: TypeGroupedByTasks, tasks?: ITask[]) => {
  if (!tasks) return tasks;

  switch (group) {
    case "PROJECT":
      return tasksOfProject(tasks);
    case "NONE":
      return tasks;
    case "PRIORITY":
      return convertGroupedTasks(tasks);
    default:
      return tasks;
  }
};

export const tasksOfProject = (tasks: ITask[]) => {
  const keyNone = "Без проекта";
  const result: Record<string, ITask[]> = {};

  tasks.forEach((i) => {
    if (i.project?.name) {
      if (!result[i.project?.name]) {
        result[i.project?.name] = [];
      }
      result[i.project?.name].push(i);
    } else {
      if (!result[keyNone]) {
        result[keyNone] = [];
      }
      result[keyNone].push(i);
    }
  });
  return result;
};
