import React, { FC, useEffect, useState } from "react";

import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { Colors } from "@theme/colors";
import { TextFont, TimePicker } from "@ui";
import { EditTimerOptions } from "@ui/TimePicker";
import { toFormatDate, toFormatHHmm } from "@utils";

interface IProps {
  handleTime: (type: EditTimerOptions, arg: any) => string;
  value: string;
  type: EditTimerOptions;
  isVisible: boolean;
  title: string;
}

export const Time: FC<IProps> = ({ handleTime, value, isVisible, type, title }) => {
  const [localTime, setLocalTime] = useState(toFormatHHmm(dayjs(value)));

  useEffect(() => {
    setLocalTime(toFormatHHmm(dayjs(value)));
  }, [value]);

  const onChangeTime = (valueInput: string) => {
    setLocalTime(valueInput);

    if (valueInput.length === 5) {
      return handleTime(type, dayjs(`${toFormatDate(dayjs(value))} ${valueInput}`));
    }
  };

  return (
    <StContentWrapSection>
      <StButtonWrapBtn isVisible={isVisible}>
        <TextFont size={16} weight="700">
          <FormattedMessage id={title} />
        </TextFont>
        <TimePicker onChange={onChangeTime} value={localTime} />
      </StButtonWrapBtn>
    </StContentWrapSection>
  );
};

const StContentWrapSection = styled.section`
  margin-top: 8px;
`;

const StButtonWrapBtn = styled.button<{ isVisible?: boolean }>`
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  background-color: ${Colors.LIGHT.white};
  border-radius: 11px;
  width: 100%;
  display: grid;
  grid-template-columns: 80% 20%;
  ${({ isVisible }) =>
    isVisible &&
    `
  border: 1px solid ${Colors.LIGHT.lighGrey};
`}
`;
