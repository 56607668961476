import React, { FC, useState, useEffect, useMemo } from "react";

import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { ReactComponent as CopyDoubleSvg } from "@assets/copy_double.svg";
import { IMeetParticipants } from "@interfaces/meet.interface";
import { IExternalUser } from "@services/meet.service";
import { isEmail } from "@utils/isEmail";
import { showToast } from "@utils/toast";

interface IProps {
  dogmaUsers?: IMeetParticipants[];
  externalUsers?: IExternalUser[];
}

export const CopyParticipantsEmails: FC<IProps> = ({ dogmaUsers = [], externalUsers = [] }) => {
  const [emailList, setEmailList] = useState("");
  const isDisabled = useMemo(() => dogmaUsers.length === 1 && externalUsers.length === 0, [dogmaUsers, externalUsers]);

  const handleClick = () => {
    navigator.clipboard.writeText(emailList).then(() => {
      showToast("E-mail адреса участников встречи скопированы в буфер");
    });
  };

  const collectEmails = () => {
    let result = "";

    for (const user of dogmaUsers) {
      // @ts-ignore
      const email = user?.user?.email ?? user?.email;

      if (email && isEmail(email) && !user?.user?.roles?.includes("SERVICE_USER")) {
        result += `${email};`;
      }
    }

    for (const user of externalUsers) {
      const email = user?.email;

      if (email && isEmail(email)) {
        result += `${email};`;
      }
    }

    setEmailList(result);
  };

  useEffect(() => {
    collectEmails();
  }, [dogmaUsers, externalUsers]);

  return (
    <>
      <Tooltip id="copy-tooltip" />

      <StItemButtonBtn
        disabled={isDisabled}
        onClick={handleClick}
        $isDisabled={isDisabled}
        data-tooltip-id="copy-tooltip"
        data-tooltip-content="копировать e-mail участников"
        data-tooltip-place="top"
      >
        <CopyDoubleSvg />
      </StItemButtonBtn>
    </>
  );
};

const StItemButtonBtn = styled.button<{ $isDisabled: boolean }>`
  display: flex;
  margin: 0;
  padding: 0;
  opacity: ${(props) => (props.$isDisabled ? 0.5 : 1)};
`;
