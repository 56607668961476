import React, { useEffect, useMemo, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { isEqual } from "lodash";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as CalendarGray } from "@assets/calendar-gray.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IFilter } from "@interfaces/businessTask.interface";
import { InterfaceUser } from "@interfaces/index";
import { ModalProject } from "@screens/day/components/ListTasks/Modal/components/ViewNotSave/components/project/modalProject/ModalProject";
import { ModalTag } from "@screens/day/components/ListTasks/Modal/components/ViewNotSave/components/tag/ModalTag";
import { projectService } from "@services/project.service";
import { defaultFilter } from "@store/businessTask/constants";
import { selectSpecialTag } from "@store/tag";
import { selectUserPermissoins } from "@store/user";
import { ButtonMain, boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont, ArrowSvg, ModalUI } from "@ui";
import { toFormatDate } from "@utils/toFormatTime";
import { intl } from "@utils/translate";

import { ModalTypeTab, ModalDateTabs, ISwitches } from "../../types";
import { DateTab } from "../dateTab";

import { DelegatedTab } from "./components/DelegatedTab";
import { SwitchBlock } from "./components/SwitchBlock";
import { modalTabs, importanceTabs, statusTabs, switchesData } from "./constants";

interface IProps {
  isVisible: boolean;
  closeEvent: () => void;
  applyEvent: (arg1: Partial<IFilter>, arg2?: () => void) => void;
  filter: IFilter;
}

export const FilterModal = ({ isVisible, closeEvent, applyEvent, filter }: IProps) => {
  const [activeModal, setActiveModal] = useState<ModalTypeTab>("");
  const [activeDateModal, setActiveDateModal] = useState<ModalDateTabs>("");
  const [projectName, setProjectName] = useState("");
  const [switches, setSwitches] = useState(switchesData);
  const [localFilter, setLocalFilter] = useState<Partial<IFilter>>(filter);

  const {
    createdDateStartRange,
    createdDateEndRange,
    planedEndDateStartRange,
    planedEndDateEndRange,
    priority,
    tagId,
    status,
    name,
    groupByType,
  } = localFilter;
  const permissons = useSelector(selectUserPermissoins);
  const tagStore = useSelector(selectSpecialTag(filter?.tagId ?? ""));

  useEffect(() => {
    const projectId = filter.projectGroupId ?? filter.projectId;

    if (projectId && !projectName) {
      (async () => {
        const response = await projectService.getProject(projectId);
        setProjectName(response.data.name);
      })();
    }

    setSwitches({
      isImportance: Boolean(priority),
      isCreateDate: Boolean(createdDateStartRange || createdDateEndRange),
      isDeadlineDay: Boolean(planedEndDateStartRange || planedEndDateEndRange),
      isStatus: Boolean(status),
    });
  }, []);

  const handleSwitch = (key: keyof ISwitches, value: boolean) => {
    const tempFilter = { ...filter };

    switch (key) {
      case "isImportance":
        tempFilter.priority = value ? "URGENT" : null;
        break;
      case "isCreateDate":
        tempFilter.createdDateStartRange = value ? toFormatDate(dayjs()) : null;
        tempFilter.createdDateEndRange = value ? toFormatDate(dayjs()) : null;
        break;
      case "isDeadlineDay":
        tempFilter.planedEndDateStartRange = value ? toFormatDate(dayjs()) : null;
        tempFilter.planedEndDateEndRange = value ? toFormatDate(dayjs()) : null;
        break;
      case "isStatus":
        tempFilter.status = value ? "OPEN" : null;
        break;
      default:
        break;
    }

    setSwitches({
      ...switches,
      [key]: value,
    });

    if (!isEqual(filter, tempFilter)) {
      setLocalFilter(tempFilter);
    }
  };

  const handleChange = (key: string, value: boolean | string) => {
    setLocalFilter({ [key]: value });
  };

  const handleDrop = () => {
    setLocalFilter({ ...defaultFilter, name, groupByType });
    setSwitches(switchesData);
    setProjectName("");
  };

  const pressCalendar = (key: string, dateWeek: Dayjs) => {
    const tempDateWeek: string | null = dateWeek.format("YYYY-MM-DD");
    handleChange(key, tempDateWeek);
    setActiveDateModal("");
  };

  const datePrettier = (value: string) => {
    if (!value) return "";
    return value.split("-").reverse().join(".");
  };

  const dateTabs = () => {
    const closeEvent = () => {
      setActiveDateModal("");
    };

    const tabs: Record<string, JSX.Element> = {
      createdDateStartRange: (
        <DateTab
          dateKey={"createdDateStartRange"}
          closeEvent={closeEvent}
          handleDate={pressCalendar}
          currentDay={createdDateStartRange ?? ""}
        />
      ),
      createdDateEndRange: (
        <DateTab
          dateKey={"createdDateEndRange"}
          closeEvent={closeEvent}
          handleDate={pressCalendar}
          currentDay={createdDateEndRange ?? ""}
        />
      ),
      planedEndDateStartRange: (
        <DateTab
          dateKey={"planedEndDateStartRange"}
          closeEvent={closeEvent}
          handleDate={pressCalendar}
          currentDay={planedEndDateStartRange ?? ""}
        />
      ),
      planedEndDateEndRange: (
        <DateTab
          dateKey={"planedEndDateEndRange"}
          closeEvent={closeEvent}
          handleDate={pressCalendar}
          currentDay={planedEndDateEndRange ?? ""}
        />
      ),
    };

    return tabs[activeDateModal];
  };

  const filteredModalTabs = useMemo(
    () =>
      [...modalTabs].map((tab) => {
        if (tab !== "group" || permissons.WORK_WITH_SPACE_PROJECTS) {
          return tab;
        }
      }),
    [permissons.WORK_WITH_SPACE_PROJECTS, modalTabs],
  );

  const delegatedTitle = (array: InterfaceUser.IStateUser[]) =>
    `${array[0].lastName} ${array[0].firstName[0].toUpperCase()}. ${array.length > 1 ? `+ ${array.length - 1}` : ""}`;

  const renderDisplayData = (tab: ModalTypeTab) => {
    switch (tab) {
      case "group": {
        if (filter.projectId || filter.projectGroupId) return projectName;
        break;
      }
      case "tag": {
        if (filter.tagId && tagStore) return tagStore.name;
        break;
      }
      case "delegatedFrom": {
        if (filter.delegatedFromType)
          return intl.getMessage(filter.delegatedFromType === "DELEGATED_FROM_ME" ? "delegatedMe" : "delegatedAny");
        else if (filter.delegatedFromUsers?.length) return delegatedTitle(filter.delegatedFromUsers);
        return "";
      }
      case "delegatedTo": {
        if (filter.delegatedToType) return intl.getMessage(filter.delegatedToType === "DELEGATED_TO_ME" ? "delegatedMe" : "delegatedAny");
        else if (filter.delegatedToUsers?.length) return delegatedTitle(filter.delegatedToUsers);
        return "";
      }
      default: {
        return "";
      }
    }
  };

  return (
    <ModalUI isVisible={isVisible} onClose={closeEvent}>
      <div>
        <StHeaderWrapDiv>
          <StButtonItemBtn onClick={closeEvent}>
            <TextFont size={16} color={Colors.LIGHT.text.accent}>
              <FormattedMessage id="close" />
            </TextFont>
          </StButtonItemBtn>
          <RenderWithCondition condition={!isEqual(filter, defaultFilter)}>
            <StButtonItemBtn onClick={handleDrop}>
              <TextFont size={16} weight="700" color={Colors.LIGHT.text.accent} lineHeight={24}>
                <FormattedMessage id="resetAll" />
              </TextFont>
            </StButtonItemBtn>
          </RenderWithCondition>
          <StTextTitleTF size={16} weight="700" align="center">
            <FormattedMessage id={"filter"} />
          </StTextTitleTF>
        </StHeaderWrapDiv>
        <StContentDiv>
          <StBlockTabsDiv>
            {filteredModalTabs.map((modalTab) => (
              <RenderWithCondition key={modalTab} condition={modalTab}>
                <button onClick={() => setActiveModal(modalTab)}>
                  <StLineTabDiv>
                    <TextFont size={18} lineHeight={24}>
                      <FormattedMessage id={modalTab} />
                    </TextFont>
                    <StIconWrapDiv>
                      <RenderWithCondition condition={renderDisplayData(modalTab)}>
                        <StTextFontWidthTF color={Colors.LIGHT.text.grey400} align="right">
                          {renderDisplayData(modalTab)}
                        </StTextFontWidthTF>
                      </RenderWithCondition>
                      <ArrowSvg type="right" fill={Colors.LIGHT.placeholder.main} width={26} height={26} />
                    </StIconWrapDiv>
                  </StLineTabDiv>
                </button>
              </RenderWithCondition>
            ))}
          </StBlockTabsDiv>
          <div>
            <SwitchBlock title="importance" value={switches.isImportance} onChange={handleSwitch} switchKey="isImportance">
              {Object.keys(importanceTabs).map((element) => (
                <StButtonTabFilterBtn
                  key={element}
                  onClick={() => handleChange("priority", importanceTabs[element])}
                  isActive={priority === importanceTabs[element]}
                >
                  <TextFont size={18} lineHeight={24}>
                    <FormattedMessage id={element} />
                  </TextFont>
                </StButtonTabFilterBtn>
              ))}
            </SwitchBlock>

            <SwitchBlock title="dateOfCreate" value={switches.isCreateDate} onChange={handleSwitch} switchKey="isCreateDate">
              <StCalendarWrapDiv>
                <StButtonDatePickerBtn onClick={() => setActiveDateModal("createdDateStartRange")}>
                  <TextFont size={18} lineHeight={24}>
                    {datePrettier(createdDateStartRange ?? "")}
                  </TextFont>
                  <CalendarGray />
                </StButtonDatePickerBtn>
                <TextFont size={18} lineHeight={24}>
                  -
                </TextFont>
                <StButtonDatePickerBtn onClick={() => setActiveDateModal("createdDateEndRange")}>
                  <TextFont size={18} lineHeight={24}>
                    {datePrettier(createdDateEndRange ?? "")}
                  </TextFont>
                  <CalendarGray />
                </StButtonDatePickerBtn>
              </StCalendarWrapDiv>
            </SwitchBlock>

            <SwitchBlock title="deadlineDay" value={switches.isDeadlineDay} onChange={handleSwitch} switchKey="isDeadlineDay">
              <StCalendarWrapDiv>
                <StButtonDatePickerBtn onClick={() => setActiveDateModal("planedEndDateStartRange")}>
                  <TextFont size={18} lineHeight={24}>
                    {datePrettier(planedEndDateStartRange ?? "")}
                  </TextFont>
                  <CalendarGray />
                </StButtonDatePickerBtn>
                <TextFont size={18} lineHeight={24}>
                  -
                </TextFont>
                <StButtonDatePickerBtn onClick={() => setActiveDateModal("planedEndDateEndRange")}>
                  <TextFont size={18} lineHeight={24}>
                    {datePrettier(planedEndDateEndRange ?? "")}
                  </TextFont>
                  <CalendarGray />
                </StButtonDatePickerBtn>
              </StCalendarWrapDiv>
            </SwitchBlock>

            <SwitchBlock title="taskStatus" value={switches.isStatus} onChange={handleSwitch} switchKey="isStatus">
              <StBlockStatusDiv>
                {Object.keys(statusTabs).map((element) => (
                  <StButtonTabFilterLine
                    key={element}
                    onClick={() => handleChange("status", statusTabs[element])}
                    isActive={status === statusTabs[element]}
                  >
                    <TextFont size={18} lineHeight={24}>
                      <FormattedMessage id={element} />
                    </TextFont>
                  </StButtonTabFilterLine>
                ))}
              </StBlockStatusDiv>
            </SwitchBlock>
          </div>
          <StButtonMainCustomBtn onClick={() => applyEvent(localFilter, closeEvent)}>
            <TextFont size={18} weight="700" color={Colors.LIGHT.white}>
              <FormattedMessage id={"apply"} />
            </TextFont>
          </StButtonMainCustomBtn>
        </StContentDiv>
      </div>
      <ModalUI isVisible={!!activeDateModal} onClose={() => setActiveDateModal("")}>
        {dateTabs()}
      </ModalUI>
      <ModalTag
        isVisible={activeModal === "tag"}
        setIsVisible={() => setActiveModal("")}
        currentTagId={tagId ?? ""}
        setTag={handleChange}
      />
      <ModalRenderWithCondition condition={activeModal === "group"}>
        <ModalProject
          isVisible={activeModal === "group"}
          close={() => setActiveModal("")}
          handleProject={handleChange}
          isFilter={true}
          title={"filterByGroup"}
          projectName={projectName}
          handleProjectName={setProjectName}
        />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={activeModal === "delegatedFrom"}>
        <DelegatedTab
          isVisible={true}
          close={() => setActiveModal("")}
          initUsers={filter.delegatedFromUsers ?? []}
          title="delegatedFrom"
          onChangeFilter={setLocalFilter}
        />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={activeModal === "delegatedTo"}>
        <DelegatedTab
          isVisible={true}
          close={() => setActiveModal("")}
          initUsers={filter.delegatedToUsers ?? []}
          title="delegatedTo"
          onChangeFilter={setLocalFilter}
        />
      </ModalRenderWithCondition>
    </ModalUI>
  );
};

const StHeaderWrapDiv = styled.div`
  ${boxCenterCss};
  justify-content: space-between;
  padding: 9px;
  border-bottom: 0.5px solid ${Colors.LIGHT.black300};
  margin-bottom: 40px;
  gap: 12px;
`;
const StContentDiv = styled.div`
  padding: 0 12px;
  overflow-y: auto;
`;
const StButtonItemBtn = styled.button`
  z-index: 1;
`;
const StButtonDatePickerBtn = styled.button`
  ${boxCenterCss};
  justify-content: space-between;
  height: 55px;
  gap: 5px;
  padding-left: 10px;
  padding-right: 20px;
  background-color: ${Colors.LIGHT.white};
  border: 1px solid ${Colors.LIGHT.placeholder.main};
  border-radius: 10px;
`;
const StButtonTabFilterBtn = styled.button<{ isActive: boolean }>`
  ${boxCenterCss};
  height: 42px;
  width: 31%;
  min-width: auto;
  padding: 0 10px;
  margin-right: 0px;
  background-color: ${Colors.LIGHT.lighGrey};
  border-radius: 5px;
  border: 1px solid ${Colors.LIGHT.lighGrey};
  &:active {
    border: 1px solid ${Colors.LIGHT.lighGrey};
  }
  ${({ isActive }) =>
    isActive &&
    `
  border: 1px solid ${Colors.LIGHT.text.accent};
`}
`;
const StButtonTabFilterLine = styled(StButtonTabFilterBtn)`
  width: auto;
`;
const StBlockTabsDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
const StLineTabDiv = styled.div`
  ${boxCenterCss};
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${Colors.LIGHT.lightGrey200};
`;
const StIconWrapDiv = styled.div`
  ${boxCenterCss};
  justify-content: flex-end;
  max-width: 70%;
`;
const StCalendarWrapDiv = styled.div`
  ${boxCenterCss};
  justify-content: space-between;
`;
const StTextFontWidthTF = styled(TextFont)`
  width: 70%;
`;
const StTextTitleTF = styled(TextFont)`
  position: absolute;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;
const StBlockStatusDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 10px;
`;
const StButtonMainCustomBtn = styled(ButtonMain)`
  width: 100%;
`;
