import styled from "styled-components";

import { TextFont } from "@components/ui";
import { Colors } from "@theme/colors";

export const StContentWrapSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding: 0 12px;
  gap: 30px;
  overflow-y: auto;
  height: 92%;
`;
export const StInpPlaceInput = styled.input`
  padding: 16px;
  background-color: ${Colors.LIGHT.white};
  border-radius: 10px;
  width: 90%;
`;
export const StBlockSettingsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const StBlockZoom = styled(StBlockSettingsDiv)`
  display: none; //TODO: hiiden for 1.23
  gap: 15px;
`;
export const StBlockLinksDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
export const StLinkWrapDiv = styled.div`
  padding: 20px 12px;
`;

export const StButtonPermanentLinkBtn = styled.button<{ isActive: boolean }>`
  padding: 12px 16px;
  border-radius: 10px;
  background-color: ${Colors.LIGHT.background.green};
  ${({ isActive }) =>
    isActive &&
    `
   background-color: ${Colors.LIGHT.grey};
 `}
`;
export const StButtonSettingBtn = styled.button`
  padding: 10px;
  background-color: ${Colors.LIGHT.lighGrey};
  border-radius: 10px;
`;

export const StButtonRoomsBtn = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  background-color: ${Colors.LIGHT.white};
  border-radius: 11px;
  margin-top: 5px;
  width: 100%;
`;

export const StLeftBlockDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;
export const StMeetingRoomsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;
export const StButtonDotesBtn = styled.button`
  display: flex;
  height: 30px;
  justify-content: flex-end;
  padding-top: 5px;
`;

export const StButtonZoomTitleDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StButtonZoomMainDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StButtonZoomBtn = styled.button`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${Colors.LIGHT.white};
  padding: 16px 12px;
  gap: 10px;
`;
export const StTextTitleBlockTF = styled(TextFont)`
  margin-bottom: 4px;
`;
export const StTextDescriptionTF = styled(TextFont)`
  margin-left: 16px;
`;
