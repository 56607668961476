import React, { FC, useEffect } from "react";

import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { RenderWithCondition } from "@components/hoc";
import { TextFont } from "@components/ui";
import { Colors } from "@theme/colors";

interface IProps {
  externalUserId: string;
  isVisible: boolean;
  position: {
    top: number;
    left: number;
  };
  onClick: () => void;
}

const RemoveParticipantsButton: FC<IProps> = ({ isVisible, position, onClick }) =>
  createPortal(
    <RenderWithCondition condition={isVisible}>
      <StContentDiv id="wrapperActions" {...position} isVisible={isVisible}>
        <StTextButtonBtn onClick={onClick}>
          <TextFont size={16} color={Colors.LIGHT.red}>
            <FormattedMessage id={"delete"} />
          </TextFont>
        </StTextButtonBtn>
      </StContentDiv>
    </RenderWithCondition>,
    document.body,
  );

const StContentDiv = styled.div<{ top: number; left: number; isVisible }>`
  position: absolute;
  z-index: 999 !important;
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  scale: ${(props) => (props.isVisible ? 1 : 0)};
  border-radius: 10px;
  background-color: ${Colors.LIGHT.red100};
  :hover {
    background-color: ${Colors.LIGHT.red200};
  }
  :active {
    background-color: ${Colors.LIGHT.red300};
  }
`;

const StTextButtonBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 8px;
  gap: 8px;
  width: 100%;
  border-radius: 10px;
`;

export default RemoveParticipantsButton;
