import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as CancelIcon } from "@assets/cancel_small.svg";
import { ReactComponent as CompleteIcon } from "@assets/complete.svg";
import { ReactComponent as UsersIcon } from "@assets/users.svg";
import { RenderWithCondition } from "@hoc";
import { IMeetingRoom, IOccupancyRoom } from "@interfaces/meet.interface";
import { boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import { HEIGHT_BLOCK } from "../../../activityParticipants/components/Activity/constants";
import { IMeetingRoomWithColor } from "../MeetingRoomsBooking";

import { equipmentsBase, equipmentsData } from "./constants";

interface IProps {
  data: IMeetingRoomWithColor[];
  occupancyRooms: IOccupancyRoom[];
  handleSelectedRoom: (room: IMeetingRoom) => void;
  selectedRoom: IMeetingRoom | null;
}

export const MeetingRooms: FC<IProps> = ({ data, occupancyRooms, handleSelectedRoom, selectedRoom }) => {
  const [tooltipId, setTooltipId] = useState("");
  const [typeEquipment, setTypeEquipment] = useState<Record<string, string>>({});

  function getInitials(str: string) {
    if (!str) return "";
    const firstCharts = str
      .replace(/[№,"]/g, "")
      .split(" ")
      .map((value) => value.slice(0, 1))
      .join("");
    return firstCharts.slice(0, 2).toLocaleUpperCase();
  }

  return (
    <>
      <StContentWrapDiv>
        <StHeaderBlockDiv>
          <TextFont size={18} weight="700">
            <FormattedMessage id="chooseMeetingRoom" defaultMessage={"Выберите подходящую переговорную"} />
          </TextFont>
          <TextFont size={16} lineHeight={24} color={Colors.LIGHT.text.main}>
            <FormattedMessage id="pushForChooseMeetingRoom" defaultMessage={"Для выбора нажмите на подходящую переговорную"} />
          </TextFont>
        </StHeaderBlockDiv>
        <StMeetingRoomsListDiv>
          {data.map((meetingRoom, index) => {
            const currentEquipments = equipmentsBase.reduce((acc: string[], item: string) => {
              if (meetingRoom[item]) {
                acc.push(item);
              }
              return acc;
            }, []);
            const isFreeRoom = !occupancyRooms?.filter((room) => meetingRoom.externalId === room.externalId)[0]?.meetings.length;
            if (meetingRoom?.id === selectedRoom?.id && !isFreeRoom) {
              handleSelectedRoom(null);
            }
            return (
              <StMeetingRoomItemBtn
                heightValue={HEIGHT_BLOCK + 1}
                key={meetingRoom?.id}
                onClick={() => handleSelectedRoom(meetingRoom)}
                active={meetingRoom?.id === selectedRoom?.id}
                disabled={!isFreeRoom}
                occupied={!isFreeRoom}
              >
                <StAvatarDiv backgroundColor={isFreeRoom ? meetingRoom.colorAvatar : Colors.LIGHT.placeholder.main}>
                  <StAbsoluteNumDiv>
                    <TextFont size={10} lineHeight={10} color={Colors.LIGHT.placeholder.main} weight="700">
                      {index + 1}.
                    </TextFont>
                  </StAbsoluteNumDiv>
                  <TextFont size={14} lineHeight={14} color={Colors.LIGHT.white} weight="700">
                    {getInitials(meetingRoom.name)}
                  </TextFont>
                </StAvatarDiv>
                <StInfoBlockDiv>
                  <TextFont
                    size={15}
                    lineHeight={14}
                    color={isFreeRoom ? Colors.LIGHT.text.main : Colors.LIGHT.placeholder.main}
                    weight="700"
                  >
                    {meetingRoom.name}
                  </TextFont>
                  <StInfoLineDiv>
                    <StInfoItemDiv>
                      <StInfoIconDiv>
                        <RenderWithCondition condition={isFreeRoom}>
                          <CompleteIcon />
                        </RenderWithCondition>
                        <RenderWithCondition condition={!isFreeRoom}>
                          <CancelIcon />
                        </RenderWithCondition>

                        <StTextMinWidthTF
                          minWidth={56}
                          size={12}
                          lineHeight={16}
                          color={isFreeRoom ? Colors.LIGHT.green : Colors.LIGHT.red}
                        >
                          <FormattedMessage
                            id={isFreeRoom ? "available" : "occupied"}
                            defaultMessage={isFreeRoom ? "Свободно" : "Занято"}
                          />
                        </StTextMinWidthTF>
                      </StInfoIconDiv>

                      <StInfoIconDiv onMouseOver={() => setTooltipId(meetingRoom?.id)} onMouseOut={() => setTooltipId("")}>
                        <UsersIcon />
                        <StTextMinWidthTF minWidth={16} size={12} lineHeight={16} color={Colors.LIGHT.placeholder.main} align="center">
                          {meetingRoom.capacity}
                        </StTextMinWidthTF>

                        <RenderWithCondition condition={meetingRoom?.id === tooltipId}>
                          <StTooltipDiv>
                            <StInfoIconDiv>
                              <UsersIcon />
                              <TextFont size={14} lineHeight={16} color={Colors.LIGHT.text.darkGreen}>
                                Вместимость {meetingRoom.capacity} чел.
                              </TextFont>
                            </StInfoIconDiv>
                          </StTooltipDiv>
                        </RenderWithCondition>
                      </StInfoIconDiv>

                      {currentEquipments.map((item) => (
                        <StInfoIconDiv
                          onMouseOver={() => setTypeEquipment({ currentRoom: meetingRoom?.id, type: item })}
                          onMouseOut={() => setTypeEquipment({})}
                        >
                          {equipmentsData[item].img}

                          <RenderWithCondition condition={meetingRoom?.id === typeEquipment.currentRoom && item === typeEquipment.type}>
                            <StTooltipDiv>
                              <StInfoIconDiv>
                                {equipmentsData[item].img}
                                <TextFont size={14} lineHeight={16} color={Colors.LIGHT.text.darkGreen}>
                                  {equipmentsData[item].defaultMessage}
                                </TextFont>
                              </StInfoIconDiv>
                            </StTooltipDiv>
                          </RenderWithCondition>
                        </StInfoIconDiv>
                      ))}
                    </StInfoItemDiv>
                  </StInfoLineDiv>
                </StInfoBlockDiv>
              </StMeetingRoomItemBtn>
            );
          })}
        </StMeetingRoomsListDiv>
      </StContentWrapDiv>
    </>
  );
};

const StContentWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
  padding-left: 20px;
  cursor: pointer;
`;
const StHeaderBlockDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const StMeetingRoomsListDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const StMeetingRoomItemBtn = styled.button<{ heightValue?: number; active: boolean; occupied: boolean }>`
  display: flex;
  position: relative;
  border: 1px solid transparent;
  border-bottom: 1px solid ${Colors.LIGHT.lighGrey};
  background-color: ${Colors.LIGHT.white};
  align-items: center;
  padding: 0px 10px;
  gap: 14px;
  border-radius: 7px;
  transition: all 0.5s ease;
  box-sizing: border-box;
  ${(props) => props.heightValue && `height: ${props.heightValue}px;`}
  ${(props) => props.occupied && "cursor: not-allowed;"}

  &:hover {
    ${({ occupied }) => !occupied && `background-color: ${Colors.LIGHT.hover};`}
    transition: all 0.3s ease;
  }
  &:active {
    ${({ occupied }) => !occupied && `background-color: ${Colors.LIGHT.green100};`}
    border: 1px solid transparent;
  }
  ${(props) =>
    props.active &&
    `
    background-color: ${Colors.LIGHT.green400};
    border: 1px solid  ${Colors.LIGHT.green700};
    &:hover {
    background-color: ${Colors.LIGHT.green400};
    transition: all 0.3s ease;
  }
   }
  `}
`;
const StAvatarDiv = styled.div<{ backgroundColor: string }>`
  ${boxCenterCss};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
const StAbsoluteNumDiv = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: -7px;
`;
const StInfoBlockDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const StInfoLineDiv = styled.div`
  display: flex;
  gap: 8px;
`;
const StInfoItemDiv = styled.div`
  ${boxCenterCss};
  gap: 8px;
`;
const StInfoIconDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
`;
const StTextMinWidthTF = styled(TextFont)<{ minWidth: number }>`
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
`;

const StTooltipDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-self: flex-start;
  background-color: ${Colors.LIGHT.white};
  left: 25%;
  bottom: 100%;
  width: 190px;
  border-radius: 10px;
  box-shadow: 0 2px 6px ${Colors.LIGHT.shadowTooltip};
  gap: 10px;
`;
