import React, { useEffect, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { ReactComponent as SvgSearch } from "@assets/search.svg";
import { LIMIT_LETTERS_TAG } from "@constants/logic";
import { LoaderRenderWithCondition, RenderWithCondition } from "@hoc";
import { create, getList, selectSpecialTag, selectStoreTag } from "@store/tag";
import { clearTagId } from "@store/tag/slice";
import { ButtonDrop, InputSearch, SearchWrap } from "@styles/styles";
import { Colors } from "@theme/colors";
import { HeaderModal, ModalUI, TextFont } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";

const eventHelperUtils = new EventHelperUtils();

interface IModalTag {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  currentTagId: string;
  setTag: (name: string, value: any, text?: string) => void;
}

export const ModalTag = ({ isVisible, setIsVisible, setTag, currentTagId }: IModalTag) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");

  const { avaliableTags, tagId, isLoading } = useSelector(selectStoreTag);
  const currentTag = useSelector(selectSpecialTag(currentTagId));

  useEffect(() => {
    dispatch(getList(""));
  }, []);

  useEffect(() => {
    if (tagId) {
      handlePress(tagId);
      dispatch(clearTagId());
    }
  }, [tagId]);

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleInput = (text: string) => {
    const value = text.replace(/\s/g, "");
    setSearch(value);

    eventHelperUtils.debounce(() => {
      dispatch(getList(value));
    }, 1000);
  };

  const handleDrop = () => {
    setSearch("");
    dispatch(getList(""));
  };

  const handlePress = (id: string, name?: string) => {
    setTag("tagId", id, name);
    setIsVisible(false);
  };

  const addTag = (name: string) => {
    dispatch(create(name));
    setSearch("");
  };

  const phSearch = useIntl().formatMessage({ id: "findAndAdd" });

  return (
    <ModalUI isVisible={isVisible} onClose={handleClose} scrollEnable={false}>
      <HeaderModal title="tags" leftSide={{ onPressClose: handleClose }} />
      <StContentWrapDiv>
        <StBlockSearchDiv>
          <SearchWrap>
            <SvgSearch />
            <InputSearch
              value={search}
              onChange={(e) => handleInput(e.target.value)}
              placeholder={phSearch}
              maxLength={LIMIT_LETTERS_TAG}
            />
          </SearchWrap>
          <RenderWithCondition condition={Boolean(search)}>
            <ButtonDrop onClick={handleDrop}>
              <TextFont size={16} color={Colors.LIGHT.text.accent}>
                <FormattedMessage id="drop" />
              </TextFont>
            </ButtonDrop>
          </RenderWithCondition>
        </StBlockSearchDiv>
        <TextFont color={Colors.LIGHT.placeholder.main}>
          <FormattedMessage id="tagDescription" />
        </TextFont>
        <RenderWithCondition condition={Boolean(currentTag?.id)}>
          <StBlockSelectedTagDiv>
            <StButtonRemoveBtn onClick={() => setTag("tagId", null)}>
              <TextFont size={16}>{currentTag?.name}</TextFont>
              <SvgRemove fill={Colors.LIGHT.placeholder.main} width={9} height={9} />
            </StButtonRemoveBtn>
          </StBlockSelectedTagDiv>
        </RenderWithCondition>
        <LoaderRenderWithCondition condition={isLoading}>
          <StListTagsDiv>
            {avaliableTags.length
              ? avaliableTags.map(
                  (tag) =>
                    tag.id !== currentTag?.id && (
                      <button key={tag.id} onClick={() => handlePress(tag.id, tag.name)}>
                        <TextFont size={16}>{tag?.name}</TextFont>
                      </button>
                    ),
                )
              : search && (
                  <StButtonCreateTagBtn onClick={() => addTag(search)}>
                    <TextFont>{`${search} - `}</TextFont>
                    <TextFont>
                      <FormattedMessage id="tagCreate" />
                    </TextFont>
                  </StButtonCreateTagBtn>
                )}
          </StListTagsDiv>
        </LoaderRenderWithCondition>
      </StContentWrapDiv>
    </ModalUI>
  );
};

const StContentWrapDiv = styled.div`
  padding: 20px 15px 0 9px;
  height: 100%;
`;
const StBlockSearchDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const StBlockSelectedTagDiv = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid ${Colors.LIGHT.lighGrey};
`;
const StListTagsDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  max-height: 80%;
  overflow-y: auto;
  gap: 17px;
`;
const StButtonCreateTagBtn = styled.button`
  display: flex;
  gap: 3px;
`;
const StButtonRemoveBtn = styled.button`
  display: flex;
  align-items: center;
  background-color: ${Colors.LIGHT.white};
  border-radius: 45px;
  padding: 3px 9px;
  margin-top: 10px;
  align-self: flex-start;
  gap: 7px;
`;
