import React, { FC, useEffect, useState } from "react";

import styled from "styled-components";

import { IMeetParticipants } from "@interfaces/meet.interface";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import { TFilter, titleFilter, tempFilters } from "../constants";

interface IProps {
  filter: TFilter;
  onPress: (type: TFilter) => void;
  participants: IMeetParticipants[];
  allParticipants: number;
  externalUsersLength: number;
  isAuthor: boolean;
}

export const Filters: FC<IProps> = ({ participants, onPress, allParticipants, externalUsersLength, filter, isAuthor }) => {
  const [filters, setFilters] = useState<Record<TFilter, number>>({ ...tempFilters });

  useEffect(() => {
    const result = { ...tempFilters };

    participants.forEach((participant) => {
      if (participant.status === "ATTENDER" || participant.status === "OWNER") result.attender++;
      if (participant.status === "NON_ATTENDER") result.non_attender++;
      if (participant.status === "MAYBE_ATTENDER") result.maybe_attender++;
      if (!participant.status) result.non_answer++;
      if (participant.statusComment && isAuthor) result.with_comment++;
      result.all = allParticipants;
    });

    setFilters({ ...result, non_answer: result.non_answer + externalUsersLength });
  }, [allParticipants, externalUsersLength, isAuthor, participants]);

  return (
    <StContentWrapSection>
      {Object.keys(filters).map(
        (i) =>
          !!filters[i] && (
            <StButtonTabBtn key={i} isActive={filter === i} onClick={() => onPress(i as TFilter)}>
              <TextFont color={Colors.LIGHT.text.grey900}>{titleFilter[i as TFilter]}</TextFont>
              <StAmountDiv>
                <TextFont color={Colors.LIGHT.text.grey800}>{filters[i]}</TextFont>
              </StAmountDiv>
            </StButtonTabBtn>
          ),
      )}
    </StContentWrapSection>
  );
};

const StContentWrapSection = styled.section`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin: 8px 0;
`;
const StAmountDiv = styled.div`
  background-color: ${Colors.LIGHT.lightGrey100};
  border-radius: 2px;
  padding: 0 1px;
`;
const StButtonTabBtn = styled.button<{ isActive: boolean }>`
  display: flex;
  gap: 4px;
  padding: 4px 6px;
  align-items: center;
  background-color: ${Colors.LIGHT.lightGrey100};
  border-radius: 6px;
  border: 1px solid ${Colors.LIGHT.lightGrey500};
  &:active {
    border: 1px solid ${Colors.LIGHT.lightGrey500};
  }
  ${({ isActive }) =>
    isActive &&
    `
  border: 1px solid ${Colors.LIGHT.background.green}`}
`;
