import React, { FC, useState } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgColorBucket } from "@assets/color-bucket.svg";
import { RenderWithCondition } from "@components/hoc";
import { IEventsOfDay } from "@interfaces/eventsOfDay.interface";
import { IMeet } from "@interfaces/meet.interface";
import { Colors } from "@theme/colors";

import { CategoryModal } from "../category/CategoryModal";
import { ICategory } from "../types";

interface IProps {
  meet: IMeet | IEventsOfDay;
  onSelect?: (category: ICategory) => void;
}

export const CategoryButton: FC<IProps> = ({ meet, onSelect = () => null }) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const openModal = () => setIsVisibleModal(true);

  return (
    <>
      <StButtonItemBtn onClick={openModal}>
        <SvgColorBucket width={19} fill={meet.userCategory?.color || Colors.DARK.grey} />
        <StTextSpan color={meet.userCategory?.color || Colors.DARK.text.main}>
          {meet.userCategory ? meet.userCategory?.description : <FormattedMessage id="setCategory" />}
        </StTextSpan>
      </StButtonItemBtn>

      <RenderWithCondition condition={isVisibleModal}>
        <CategoryModal meet={meet} setIsVisible={setIsVisibleModal} onSelect={onSelect} />
      </RenderWithCondition>
    </>
  );
};

const StButtonItemBtn = styled.button`
  padding: 8px;
  display: flex;
  flex-direction: row;
  background: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const StTextSpan = styled.span<{ color: string }>`
  margin-left: 8px;
  color: ${(props) => props.color};
  font-size: 16px;
`;
