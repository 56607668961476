import React, { FC, useCallback } from "react";

import { useSelector } from "react-redux";

import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { RenderWithCondition } from "@hoc";
import { IMeetParticipants, TTypeParticipants } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { LetterAvatar } from "@screens/day/components/timetable/main/modal/components/participants/components/LetterAvatar";
import { IExternalUser } from "@services/meet.service";
import { UserAvatar } from "@shared/userAvatar/UserAvatar";
import { selectScreenDay } from "@store/screenDay";
import { commonStyles } from "@styles/styles";
import { AssistantToast, TextFont } from "@ui";
import globalState from "@utils/globalState";

import { ReactComponent as SvgAttend } from "./assets/attend.svg";
import { ReactComponent as SvgMayBe } from "./assets/maybe.svg";
import { ReactComponent as SvgNotAnswer } from "./assets/not_answer.svg";
import { ReactComponent as SvgNotAttend } from "./assets/not_attend.svg";
import { ReactComponent as SvgOwner } from "./assets/owner.svg";

const iconStatus: Record<TTypeParticipants, JSX.Element> = {
  OWNER: <SvgOwner />,
  ATTENDER: <SvgAttend />,
  NON_ATTENDER: <SvgNotAttend />,
  MAYBE_ATTENDER: <SvgMayBe />,
};

interface IProps {
  user: IMeetParticipants | IExternalUser;
  isExternal?: boolean;
  isSave: boolean;
  handleDeleteItem: (id: string, isExternal: boolean) => void;
  setSelectUser: React.Dispatch<React.SetStateAction<IStateUser | undefined>>;
  assistant?: Partial<IStateUser> | null;
  showAttendanceStatus?: boolean;
}

export const UserListItem: FC<IProps> = ({
  user,
  isSave,
  isExternal = false,
  handleDeleteItem,
  setSelectUser,
  assistant,
  showAttendanceStatus,
}) => {
  const { participantsAttendance } = useSelector(selectScreenDay);

  const borderColorStatus = useCallback(
    (item: IMeetParticipants) => {
      if (!isSave) {
        const attendance = participantsAttendance?.filter((i) => i.userId === item.userId)[0];
        return attendance?.meetings.length ? globalState.colorSchema.red : globalState.colorSchema.green;
      }
      // @ts-ignore
      return user?.busy ? globalState.colorSchema.red : globalState.colorSchema.green;
    },
    [isSave, participantsAttendance],
  );

  const clickHandler = () => {
    if (!isExternal) {
      setSelectUser(user as unknown as IStateUser);
    }
  };
  return (
    // @ts-ignore
    <button key={user.userId} style={styles.item} onClick={() => clickHandler(user.userId)}>
      <div style={{ display: "flex", width: "93%", paddingTop: 8 }}>
        <div style={{ position: "relative" }}>
          <RenderWithCondition condition={isExternal}>
            {/* @ts-ignore */}
            <LetterAvatar userName={(user?.firstName && user?.firstName[0]?.toUpperCase()) ?? ""} />
          </RenderWithCondition>
          <RenderWithCondition condition={!isExternal}>
            <>
              <UserAvatar
                // @ts-ignore
                avatarFileId={user?.avatarFileId ?? null}
                style={{
                  width: 45,
                  height: 45,
                  borderRadius: 45,
                  marginRight: 8,
                  // @ts-ignore
                  ...(!user.status && { border: `2px solid ${borderColorStatus(user)}` }),
                }}
              />
              <RenderWithCondition condition={showAttendanceStatus}>
                {/* @ts-ignore */}
                <div style={{ position: "absolute", right: 5, bottom: 0 }}>{iconStatus[user.status] ?? <SvgNotAnswer />}</div>
              </RenderWithCondition>
            </>
          </RenderWithCondition>
        </div>
        <div style={{ width: "80%" }}>
          <TextFont type="bold" size={16} weight="700" style={{ marginBottom: 6 }}>
            {/* @ts-ignore */}
            {`${user?.firstName} ${user?.lastName}`}
          </TextFont>
          <TextFont size={14} style={commonStyles.oneOfLines} color={globalState.colorSchema.placeholder.main}>
            {/* @ts-ignore */}
            {isExternal ? user?.email : user?.workPosition ?? ""}
          </TextFont>
        </div>
      </div>
      {/* @ts-ignore */}
      <RenderWithCondition condition={!isSave && user?.status !== "OWNER"}>
        <button
          style={styles.remove}
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteItem(user.id, isExternal);
          }}
        >
          <SvgRemove width={12} height={12} fill={globalState.colorSchema.background.grey} />
        </button>
      </RenderWithCondition>

      {/* @ts-ignore */}
      <RenderWithCondition condition={assistant && user.status === "OWNER"}>
        <div style={{ paddingTop: 8, paddingRight: 6 }}>
          <AssistantToast assistant={assistant} />
        </div>
      </RenderWithCondition>
    </button>
  );
};

const styles = {
  list: {
    display: "flex",
    "flex-direction": "column",
    marginTop: 6,
    gap: 8,
  },
  item: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 8,
    paddingLeft: 8,
    borderRadius: 10,
    backgroundColor: globalState.colorSchema.white,
  },
  remove: {
    width: "7%",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 8,
    paddingTop: 8,
  },
};
