import { FC, useCallback, useEffect, useState } from "react";
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ModalRenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { useSubscribe } from "@hooks/useSubscribe";
import { IEventOfDaySimplified, TFirstArgPress } from "@interfaces/eventsOfDay.interface";
import { IMeet } from "@interfaces/meet.interface";
import { Layout } from "@layout";
import { useGetMeetingByIdQuery, useGetMeetingListQuery } from "@services/meetApi";
import { TaskSubtypes, selectBusinessTasks } from "@store/businessTask";
import { selectCalendar } from "@store/calendar";
import { selectConfigure } from "@store/configure";
import { selectNotifications, setMeetingId } from "@store/notification";
import { selectCurrentDate, selectScreenDay, updateId } from "@store/screenDay";
import { setActiveTabId, setCategoryModalEvent } from "@store/screenDay/slice";
import { userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { AddCircle, Prompting, TextFont } from "@ui";
import { myTracker } from "@utils/myTracker";
import { toFormatDate } from "@utils/toFormatTime";

import { Notes, tightModeElements } from "../../shared";

import { Header } from "./components/header";
import { Modal as ModalTask } from "./components/ListTasks";
import { ListTasks } from "./components/ListTasks/ListTasks";
import { Tabs } from "./components/tabs";
import { Timetable } from "./components/timetable";
import { CategoryModal } from "./components/timetable/main/modal/components/viewNotSave/category/CategoryModal";
import { Modal } from "./components/timetable/main/modal/Modal";
import { filterEvents } from "./components/timetable/main/util";

const HEADER_HEIGHT = "40px";

export const Day: FC = () => {
  const dispatch = useDispatch();
  const { isTablet } = useDimension();

  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isVisibleMeet, setIsVisibleMeet] = useState(false);
  const [isVisibleTask, setIsVisibleTask] = useState(false);
  const [currentMeet, setCurrentMeet] = useState<TFirstArgPress | undefined>(undefined);
  const [taskEvent, setTaskEvent] = useState<{ id: string; taskType: TaskSubtypes } | null>();
  const [filteredEventsOfDay, setFilteredEventsOfDay] = useState<(IEventOfDaySimplified | IEventOfDaySimplified[])[]>([]);
  const [skipFetchMeet, setSkipFetchMeet] = useState(false);

  const {
    settings: { isTightMode },
  } = useSelector(selectConfigure);
  const notifications = useSelector(selectNotifications);
  const { activeTabId } = useSelector(selectScreenDay);
  const { eventToReopen, currentId: eventId, joinData, isOpenModalMeet, date } = useSelector(selectScreenDay);
  const selectedDay = useSelector(selectCurrentDate);
  const { isOpenModalTask, currentTask } = useSelector(selectBusinessTasks);
  const { isVisibleAppCalendar } = useSelector(selectCalendar);
  const { directorId } = userSelector();

  const { data: eventsOfDay, isFetching, isLoading, refetch } = useGetMeetingListQuery({ date: toFormatDate(selectedDay) });
  useGetMeetingByIdQuery(
    {
      id: currentMeet?.id,
      repeat: currentMeet?.repeat,
      currentDate: toFormatDate(selectedDay),
    },
    {
      skip: skipFetchMeet,
    },
  );

  useSubscribe(
    "updateMeetings",
    () => {
      refetch();
    },
    [selectedDay],
  );

  useSubscribe(
    "change_listener_user",
    () => {
      setFilteredEventsOfDay([]);
      setIsFetchingData(true);
      refetch();
    },
    [directorId],
  );

  useEffect(() => {
    setIsFetchingData(true);
  }, [date]);

  useEffect(() => {
    if (!isFetching) {
      setIsFetchingData(false);
    }
  }, [isFetching, isFetchingData]);

  useEffect(() => {
    if (eventId && isOpenModalMeet) {
      setIsVisibleMeet(true);
      handleChangeCurrentMeet("id", eventId);
    }
    if (currentTask && isOpenModalTask && isVisibleAppCalendar) {
      setIsVisibleTask(true);
      setTaskEvent({ id: currentTask.id, taskType: currentTask.subType });
    }
  }, [eventId, isOpenModalMeet, currentTask, isOpenModalTask, isVisibleAppCalendar]);

  useEffect(() => {
    if (currentTask?.id === taskEvent?.id) return;

    if (currentTask && isOpenModalTask && isVisibleAppCalendar) {
      setIsVisibleTask(true);
      setTaskEvent({ id: currentTask.id, taskType: currentTask.subType });
    }
  }, [currentTask, isOpenModalTask, taskEvent, isVisibleAppCalendar]);

  useEffect(() => {
    if (notifications.meetingID) {
      handleChangeCurrentMeet("id", notifications.meetingID);
      dispatch(setActiveTabId("timetable"));
      setIsVisibleMeet(true);
      dispatch(setMeetingId(undefined));
    }
  }, [notifications.meetingID]);

  const handlePress = useCallback(
    async (meet: TFirstArgPress, type: "TASK" | "MEETING", isPersonalTask?: boolean) => {
      if (type === "MEETING") {
        dispatch(updateId({ id: meet.id, modalOpen: true }));
        setIsVisibleMeet(true);
        setCurrentMeet(meet);

        if (taskEvent) {
          setTaskEvent(null);
        }
      }

      if (type === "TASK") {
        setTaskEvent({ taskType: isPersonalTask ? TaskSubtypes.Personal : TaskSubtypes.Business, id: meet.id });
        setIsVisibleTask(true);
      }
    },
    [taskEvent],
  );

  useEffect(() => {
    const url = new URL(location.href);
    if (url.search) {
      const arrQueryParams = Array.from(url.searchParams);

      (async () => {
        for await (const [type, id] of arrQueryParams) {
          if (type === "meeting") {
            setSkipFetchMeet(false);
            setCurrentMeet({ ...currentMeet, id });
            handlePress({ id, repeat: false }, "MEETING");
            break;
          }

          if (type === "task") {
            handlePress({ id }, "TASK");
            break;
          }
        }
      })();
    }
  }, [location]);

  useEffect(() => {
    /*
      ToDo: продумать вариант обновления экрана встречи без жесткой перезагрузки
      после создания исключения из серии.
    */
    if (eventToReopen?.id) {
      setIsVisibleMeet(true);
      setCurrentMeet({ id: eventToReopen.id, date: eventToReopen.date, repeat: eventToReopen.repeat });
    }
  }, [eventToReopen]);

  useEffect(() => {
    // ToDo: Типизировать данные от селектора и фильтра!!!
    if (!isLoading && !isFetching) {
      const result = filterEvents(eventsOfDay);
      setFilteredEventsOfDay(result);
    }
  }, [eventsOfDay, isLoading, isFetching]);

  useEffect(() => {
    if (joinData) handlePress({ id: "", repeat: Boolean(joinData?.repeat) }, "MEETING");
  }, [joinData]);

  const handleChangeCurrentMeet = (name: keyof TFirstArgPress, val: boolean | string) => {
    setCurrentMeet({
      ...currentMeet,
      [name]: val,
    });
  };

  const configTabs = {
    notes: <Notes backgroundColor={Colors.LIGHT.white} />,
    timetable: <Timetable filteredEventsOfDay={filteredEventsOfDay} handlePress={handlePress} isFetching={isLoading} />,
    tasks: <ListTasks handlePress={handlePress} />,
  };

  const mob = () => (
    <StMobContentWrapDiv>
      <Tabs />
      <StContentTableDiv taskTable={activeTabId === "tasks"}>{configTabs[activeTabId]}</StContentTableDiv>
    </StMobContentWrapDiv>
  );

  const tablet = () =>
    isTightMode ? (
      tightModeElements.day
    ) : (
      <StTabletWrapSection>
        <StLeftSide>
          <StHeaderTableDiv left>
            <TextFont type="bold" weight="700" size={24} lineHeight={32} color={Colors.LIGHT.text.darkGreen}>
              Задачи
            </TextFont>
          </StHeaderTableDiv>
          <ListTasks handlePress={handlePress} />
          <AddCircle
            press={() => {
              myTracker("CreateTaskOnDayClick");
              handlePress({ id: "" }, "TASK");
            }}
            bottom={"6%"}
            right={0}
          />
        </StLeftSide>
        <StCenterSide>
          <StHeaderTableDiv>
            <TextFont weight="700" size={24} lineHeight={32} color={Colors.LIGHT.text.darkGreen}>
              Расписание
            </TextFont>
          </StHeaderTableDiv>
          <StCalcWrapDiv>
            <StTimeTableWrapDiv>
              <Timetable handlePress={handlePress} filteredEventsOfDay={filteredEventsOfDay} isFetching={isLoading || isFetchingData} />
              <AddCircle press={() => handlePress({ id: "", repeat: false }, "MEETING")} bottom={"6%"} right={0} testId="add-meet-btn" />
            </StTimeTableWrapDiv>
          </StCalcWrapDiv>
        </StCenterSide>
        <StRightSide>
          <StHeaderTableDiv right>
            <TextFont weight="700" size={24} lineHeight={32} color={Colors.LIGHT.text.darkGreen}>
              Заметки
            </TextFont>
          </StHeaderTableDiv>
          <StNotesWrapDiv>
            <Notes autoFocus={false} />
          </StNotesWrapDiv>
        </StRightSide>
      </StTabletWrapSection>
    );

  return (
    <Layout
      backgroundColor={Colors.LIGHT.white}
      header={!isTightMode && <Header handlePressEvent={handlePress} />}
      footerHeight={isTightMode ? "0px" : undefined}
      headerHeight={isTightMode ? "0px" : undefined}
    >
      <>
        {isTablet ? tablet() : mob()}
        <Prompting kind="day" keySetting="isPromptDay" />

        <ModalRenderWithCondition condition={isVisibleMeet}>
          <Modal
            isVisible={isVisibleMeet}
            meetId={currentMeet?.id ?? eventId}
            setIsVisible={setIsVisibleMeet}
            data={joinData ?? {}}
            isRepeating={currentMeet?.repeat}
            meetDate={currentMeet?.date}
          />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isVisibleTask}>
          <ModalTask
            isVisible={isVisibleTask}
            id={taskEvent?.id ?? ""}
            setIsVisible={setIsVisibleTask}
            taskSubType={taskEvent?.taskType}
            setId={(id, subType) => setTaskEvent({ id, taskType: subType })}
          />
        </ModalRenderWithCondition>
      </>
    </Layout>
  );
};

const StMobContentWrapDiv = styled.div`
  height: 100%;
`;
const StContentTableDiv = styled.div<{ taskTable: boolean }>`
  height: 90%;
  margin: ${({ taskTable }) => (taskTable ? "0" : "12px 12px 0 12px")};
`;
const StTabletWrapSection = styled.section`
  display: flex;
  height: 100%;
  background-color: ${Colors.LIGHT.white};
`;
const StSideDiv = styled.div`
  background-color: ${Colors.LIGHT.background.main};
  margin-top: 14px;
  height: 100%;
`;
const StLeftSide = styled(StSideDiv)`
  width: 33%;
  margin-right: 1%;
  border-top-right-radius: 20px;
  position: relative;
`;
const StRightSide = styled(StSideDiv)`
  width: 33%;
  margin-left: 1%;
  border-top-left-radius: 20px;
`;
const StCenterSide = styled(StSideDiv)`
  width: 33%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
`;
const StNotesWrapDiv = styled.div`
  padding: 0 12px;
  height: 87%;
  overflow-y: auto;
`;
const StTimeTableWrapDiv = styled.div`
  height: 88%;
  margin: 12px;
`;
const StCalcWrapDiv = styled.div`
  height: calc(100% - ${HEADER_HEIGHT});
`;
const StHeaderTableDiv = styled.div<{ left?: boolean; right?: boolean }>`
  display: flex;
  background-color: ${Colors.LIGHT.disabled};
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  height: ${HEADER_HEIGHT};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  ${({ left }) =>
    left &&
    `
  border-top-left-radius: 0;
`}
  ${({ right }) =>
    right &&
    `
  border-top-right-radius: 0;
`}
`;
