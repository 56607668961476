import React, { FC, useEffect } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { TTypePriorityTask } from "@interfaces/businessGoals.interface";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { maxLinesInput } from "@utils";

import { CommonFields } from "../types";

interface IProps extends CommonFields {
  name: string;
  description: string;
  priority: TTypePriorityTask;
  autoFocusName: boolean;
  text: string;
  outsidePress: () => void;
  onChangeTitle: (arg: string) => void;
}

export const Inputs: FC<IProps> = ({ name, description, handleData, priority, autoFocusName, text, outsidePress, onChangeTitle }) => {
  useEffect(() => {
    handleData("name", name);
  }, [name]);

  const changeDesc = (val: string) => {
    handleData("description", val);
  };

  const changeTitle = (val: string) => {
    if (maxLinesInput(val)) {
      onChangeTitle(val);
    }
  };

  const textAreaAdjust = (element) => {
    element.target.style.height = "0.1px";
    element.target.style.height = `${element.target.scrollHeight}px`;
  };

  const placeholderTaskName = `${useIntl().formatMessage({ id: "tagtask" })}`;
  const placeholderTaskDescription = useIntl().formatMessage({
    id: priority === "CONTACT" ? "phoneNumber" : "taskDescroption",
  });

  return (
    <StContainerDiv>
      <StSubInputDiv>
        <RenderWithCondition condition={!text}>
          <StLocalPlaceHolderDiv>
            <TextFont color={Colors.LIGHT.placeholder.system} type="bold" size={18}>
              <FormattedMessage id="nameTask" defaultMessage={"Наименование задачи"} />
            </TextFont>
            <TextFont color={Colors.LIGHT.placeholder.system} type="bold" size={12}>
              {placeholderTaskName}
            </TextFont>
          </StLocalPlaceHolderDiv>
        </RenderWithCondition>
        <StNameTextArea
          value={text}
          onChange={(e) => changeTitle(e.target.value)}
          maxLength={100}
          onBlur={() => outsidePress()}
          autoFocus={autoFocusName}
          onKeyUp={textAreaAdjust}
        />
      </StSubInputDiv>

      <StSubInputDiv>
        <DescriptionTextarea
          placeholder={placeholderTaskDescription}
          value={description}
          onChange={(e) => changeDesc(e.target.value)}
          onKeyUp={textAreaAdjust}
        />
      </StSubInputDiv>
    </StContainerDiv>
  );
};

const StSubInputDiv = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: ${Colors.LIGHT.white};
  border-radius: 9px;
  z-index: 1;
`;
const StLocalPlaceHolderDiv = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  gap: 5px;
  top: 19px;
  left: 18px;
  z-index: 1;
`;

const StContainerDiv = styled.div`
  display: flex;
  margin: 8px 0px 8px 0px;
  gap: 8px;
  flex-direction: column;
  position: relative;
`;
const StNameTextArea = styled.textarea`
  background-color: transparent;
  border-radius: 9px;
  font-size: 16px;
  width: 94%;
  padding: 12px 8px 8px 16px;
  z-index: 5;
  min-height: 72px;
  margin: 8px 0;
`;

const DescriptionTextarea = styled(StNameTextArea)`
  min-height: 56px;
  margin: 0;
  padding-top: 16px;
`;
