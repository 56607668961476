import React, { FC, ReactNode, useEffect } from "react";

import { useSelector } from "react-redux";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { AttachFileContextMenuButton } from "@shared/fileAttachments";
import { selectUserPermissoins } from "@store/user";
import { Colors } from "@theme/colors";

import { TaskMeetings } from "../../../taskMeetings/TaskMeeting";

interface IProps {
  isPersonalTask?: boolean;
  componentDelegate: ReactNode;
  task: ITask | null;
  close: () => void;
  openFilePicker: ({ closeOverModal }: { closeOverModal?: boolean }) => void;
}

export const Actions: FC<IProps> = ({ openFilePicker, isPersonalTask, componentDelegate, task, close }) => {
  const permissions = useSelector(selectUserPermissoins);

  useEffect(() => {
    document.getElementById("modalBusinessTask").addEventListener("click", (e) => {
      if (!document.getElementById("businessTaskActions")?.contains(e?.target as Node)) {
        close();
      }
    });
  }, []);

  return (
    <StContentWrapDiv id="businessTaskActions">
      <RenderWithCondition condition={permissions.CAN_DELEGATE && !isPersonalTask}>
        <div className="action">{componentDelegate}</div>
      </RenderWithCondition>
      <RenderWithCondition condition={task?.type !== "CONTROL" && !isPersonalTask}>
        <div className="action">
          <TaskMeetings closeOverModal={close} taskId={task?.id ?? ""} onlyButton />
        </div>
      </RenderWithCondition>
      <div className="action">
        <AttachFileContextMenuButton openFilePicker={() => openFilePicker({ closeOverModal: true })} />
      </div>
    </StContentWrapDiv>
  );
};

const StContentWrapDiv = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  min-width: 185px;
  border-radius: 8px;
  box-shadow: 0 2px 6px ${Colors.LIGHT.shadowTooltip};
  background-color: ${Colors.LIGHT.white};
  z-index: 999;
  & .action {
    width: 100%;
  }
  & .action:hover {
    background-color: ${Colors.LIGHT.disabled};
  }
`;
