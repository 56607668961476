import React, { useState, MouseEvent, Ref } from "react";

import FlatList, { FlatListProps } from "flatlist-react";
import styled from "styled-components";

import { ITask } from "@interfaces/businessGoals.interface";
import { TaskSubtypes } from "@store/businessTask";

import { Item } from "./Item";

interface ISwipeList extends Partial<FlatListProps<unknown>> {
  data: any[];
  press?: (id: string, subType?: TaskSubtypes) => void;
  footer?: JSX.Element;
  element?: (arg: {
    item: any;
    index: number;
    handleRightClick: (e: MouseEvent<HTMLButtonElement>, taskId: string, taskSubType?: TaskSubtypes) => void;
  }) => JSX.Element;
  buttons: (arg: { item: ITask; pos: { left: number; top: number }; isVisible: boolean }) => JSX.Element;
  onScroll?: () => void;
  listInnerRef?: Ref<HTMLDivElement>;
  allowPressIcon?: boolean;
  setItemSubtype?: React.Dispatch<React.SetStateAction<TaskSubtypes>>;
}

export const SwipeList = ({
  data,
  press,
  footer = null,
  buttons,
  element,
  onScroll,
  listInnerRef,
  allowPressIcon = false,
  setItemSubtype,
  ...props
}: ISwipeList) => {
  let index = 0;

  const [actionsId, setActionsId] = useState("");
  const [pos, setPos] = useState({ left: 0, top: 0 });

  async function handleRightClick(e: MouseEvent<HTMLButtonElement>, taskId: string, taskSubType: TaskSubtypes) {
    e.preventDefault();
    setItemSubtype && setItemSubtype(taskSubType);
    setActionsId(taskId);

    const fixedYpos = e.clientY < 100 ? 105 : e.clientY;

    setPos({ top: fixedYpos, left: e.clientX });
  }

  document.addEventListener("click", () => setActionsId(""));

  const renderItem = (item: ITask, index) => (
    <>
      {element ? (
        element({ item, index, handleRightClick })
      ) : (
        <Item
          index={index}
          item={item}
          press={press}
          onRightClick={handleRightClick}
          isRightClick={actionsId === item.id}
          allowPressIcon={allowPressIcon}
        />
      )}
      {buttons({ item, pos, isVisible: item.id && item.id === actionsId })}
    </>
  );

  return (
    <StContentWrapDiv onScroll={onScroll} ref={listInnerRef} {...props}>
      <FlatList renderWhenEmpty={() => null} list={data} renderItem={(item: ITask) => renderItem(item, index++)} {...props} />
      {footer}
    </StContentWrapDiv>
  );
};

const StContentWrapDiv = styled.div`
  height: 100%;
  overflow-y: auto;
`;
