import React, { useState, MouseEvent } from "react";

import { isEmpty } from "lodash";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { ReactComponent as SvgPlus } from "@assets/plus.svg";
import { LoaderRenderWithCondition, ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { Modal } from "@screens/day/components/ListTasks";
import { Buttons, ButtonsAllTasks, ButtonsMoveTasks } from "@screens/day/components/ListTasks/taskItem/buttons";
import { SingleSectionHeader } from "@screens/day/components/ListTasks/taskItem/headers";
import { ListSingleSection } from "@shared";
import { selectConfigure } from "@store/configure";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

import { numberOfTasks, TypeKey } from "./constants";
import { CreateTask } from "./CreateTask";
import { RenderItem } from "./RenderItem";

export const ListTasksTablet = () => {
  const [isVisibleDelegated, setIsVisibleDelegated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isCreating, setIsCreating] = useState<TypeKey | undefined>();
  const [taskId, setTaskId] = useState("");
  const [actionsId, setActionsId] = useState("");
  const [pos, setPos] = useState({ left: 0, top: 0 });

  const tasks = { transferred: [], delegated: [], takenBack: [] };
  const { isModalOpen } = useSelector(selectConfigure);

  const handlePress = (id: string) => {
    setTaskId(id);
    setIsVisible(true);
  };

  const handleRightClick = (e: MouseEvent<HTMLButtonElement>, taskId: string) => {
    e.preventDefault();
    setActionsId(taskId);
    setPos({ top: e.clientY, left: e.clientX });
  };

  document.addEventListener("click", (e) => setActionsId(""));

  const isRenderTitle = (objKey: string): boolean => {
    if (isEmpty(tasks?.transferred) && isEmpty(tasks.delegated)) {
      return objKey !== "urgent";
    } else if (isEmpty(tasks.delegated)) {
      return objKey !== "delegated" && objKey !== "transferred";
    }
    return objKey !== "transferred";
  };

  const renderElseItem = (name: TypeKey, index: number) => {
    if (index === 0) {
      if (isCreating === name) {
        return <CreateTask priority={name} setIsCreating={setIsCreating} />;
      }

      return (
        <button onClick={() => setIsCreating(name)} style={{ ...styles.block, flexDirection: "row", alignItems: "center" }}>
          <div style={styles.plus}>
            <SvgPlus width={14} height={13} />
          </div>
          <TextFont size={12} color={globalState.colorSchema.text.accent} style={{ width: "94%" }}>
            <FormattedMessage id="create" />
          </TextFont>
        </button>
      );
    }
    return <div />;
  };

  const renderHiddenItem = (title: string, props) => {
    switch (title) {
      case "transferred":
        return <ButtonsMoveTasks {...props} />;
      case "delegated":
        return <ButtonsAllTasks {...props} />;
      default:
        return <Buttons {...props} />;
    }
  };

  const renderItem = (data: { index: number; item: ITask; section: string }) => (
    <>
      <RenderItem task={data.item} press={handlePress} onRightClick={handleRightClick} />
      {renderHiddenItem(data.section, { item: data.item, isVisible: actionsId === data.item.id, pos })}
    </>
  );

  return (
    <div style={{ height: "100%" }}>
      <LoaderRenderWithCondition condition={!isCreating && !isModalOpen}>
        <div style={styles.container}>
          {Object.keys(tasks).map((name) => (
            <div key={name}>
              <RenderWithCondition condition={isRenderTitle(name)}>
                {name === "delegated" ? (
                  <SingleSectionHeader
                    type="delegated"
                    show={() => setIsVisibleDelegated(true)}
                    styleTextShow={{ color: globalState.colorSchema.text.accent, marginLeft: 10 }}
                    styleWrapper={{ justifyContent: "flex-start" }}
                  />
                ) : (
                  <div style={styles.wrapperBlock}>
                    <TextFont style={styles.title}>
                      <FormattedMessage id={name} />
                    </TextFont>
                  </div>
                )}
              </RenderWithCondition>
              <div style={styles.section}>
                {tasks[name].map((item, index) => renderItem({ index, item, section: name }))}
                {numberOfTasks[name] - tasks[name].length > 0
                  ? Array(numberOfTasks[name] - tasks[name].length)
                      .fill("")
                      .map((i, index) => (
                        <div key={i.id} style={styles.wrapperBlock}>
                          {renderElseItem(name as TypeKey, index)}
                        </div>
                      ))
                  : null}
              </div>
            </div>
          ))}
        </div>
      </LoaderRenderWithCondition>
      <ModalRenderWithCondition condition={isVisible}>
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} id={taskId} />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={isVisibleDelegated}>
        <ListSingleSection type="delegated" close={() => setIsVisibleDelegated(false)} />
      </ModalRenderWithCondition>
    </div>
  );
};

const styles = {
  container: {
    height: "100%",
    "overflow-y": "auto",
    paddingTop: "5px",
  },
  section: {
    display: "flex",
    "flex-direction": "column",
  },
  wrapperBlock: {
    borderBottom: `1px solid ${globalState.colorSchema.lighGrey}`,
    height: "2.5vh",
  },
  block: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    paddingBottom: "5px",
    paddingTop: "2px",
  },
  title: {
    fontSize: "16px",
    fontWeight: "400",
    paddingLeft: "8px",
    paddingBottom: "5px",
    paddingTop: "2px",
  },
  plus: {
    display: "flex",
    width: "6%",
    alignItems: "center",
  },
};
