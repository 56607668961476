import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Dayjs } from "dayjs";
import { isEqual } from "lodash";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { IGoal } from "@interfaces/businessGoals.interface";
import { create, deleteGoal, selectBusiness, selectSpecifyGoal, update } from "@store/businessGoals";
import { selectYearMonth } from "@store/date";
import { diff } from "@utils/diffObjects";
import { myTracker } from "@utils/myTracker";

import { tempGoal } from "./constants";

interface IProps {
  goalId: string;
  close: () => void;
}

export const useData = ({ goalId, close }: IProps) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const yearMonth = useSelector(selectYearMonth);

  const goal = useSelector(selectSpecifyGoal(goalId));
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  // @ts-ignore
  const [data, setData] = useState<IGoal>({});
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isVisibleEmptyYearMonths, setIsVisibleEmptyYearMonths] = useState(false);

  const { isLoading, goals } = useSelector(selectBusiness);

  useEffect(() => {
    if (goal) {
      setData(goal);
    } else {
      setData({ ...tempGoal, yearMonths: [yearMonth] });
    }
  }, [goal, yearMonth]);

  useEffect(() => {
    setIsEdit(!!data.description?.trim() && !isEqual(data, goal ? goal : tempGoal));
  }, [data, goal]);

  const handleData = (name: string, val: any) => {
    setData({ ...data, [name]: val });
  };

  const handleSave = () => {
    myTracker("SaveBusinessGoalClick");

    if (!data?.yearMonths.length) {
      return setIsVisibleEmptyYearMonths(true);
    }

    if (data.description) {
      if (goal) {
        dispatch(update({ id: goal.id, data: diff(data, goal), yearMonth }));
      } else {
        dispatch(create({ ...data, yearMonth, position: goals.length }));
      }
      if (ref.current !== null) {
        ref.current.blur();
      }
      if (!data.yearMonths.includes(yearMonth)) {
        close();
      }
    }
  };

  const handlePress = (id: string) => {
    setId(id);
    setIsVisibleModal(true);
  };

  const handleOutsidePress = () => {
    if (goal && !isEdit) {
      return;
    }

    if (ref.current !== null) {
      handleSave();
      ref.current.blur();
    }
  };

  const handleAddMonth = (yearMonth: Dayjs) => {
    handleData("yearMonths", [...(data.yearMonths ?? []), yearMonth.format("YYYY-MM")]);
  };

  const handleRemoveMonth = (yearMonth: string) => {
    handleData("yearMonths", [...(data.yearMonths ?? []).filter((item) => item !== yearMonth)]);
  };

  const handleDeleteNotification = useCallback(() => {
    dispatch(deleteGoal(goalId));
    close();
  }, [goalId]);

  const handleCancelNotification = useCallback(() => {
    setData(goal ? goal : tempGoal);
    close();
    setIsCancel(false);
  }, [isCancel]);

  const msgBusinessDeleteAlert = useIntl().formatMessage({ id: "businessDeleteAlert" });
  const msgCancelExeption = useIntl().formatMessage({ id: "cancelException" });

  return useMemo(
    () => ({
      id,
      ref,
      isEdit,
      setIsCancel,
      isCancel,
      isDelete,
      setIsDelete,
      data,
      handleData,
      isVisibleModal,
      setIsVisibleModal,
      isLoading,
      goal,
      handlePress,
      handleOutsidePress,
      handleAddMonth,
      handleSave,
      handleDeleteNotification,
      handleCancelNotification,
      msgBusinessDeleteAlert,
      msgCancelExeption,
      isVisibleEmptyYearMonths,
      setIsVisibleEmptyYearMonths,
      handleRemoveMonth,
    }),
    [
      ref,
      id,
      isEdit,
      isCancel,
      isDelete,
      data,
      isVisibleModal,
      isVisibleEmptyYearMonths,
      isLoading,
      goal,
      msgBusinessDeleteAlert,
      msgCancelExeption,
    ],
  );
};
