import React, { FC, useEffect, useMemo, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { ReactComponent as CalendarGrey } from "@assets/calendar_dots_grey.svg";
import { ReactComponent as SvgCopy } from "@assets/copy_border.svg";
import { ReactComponent as SvgDelete } from "@assets/delete.svg";
import { ReactComponent as SvgSettings } from "@assets/settings.svg";
import { ReactComponent as SvgDotes } from "@assets/three_dotes.svg";
import { ReactComponent as SvgZoom } from "@assets/zoom.svg";
import { regExpOnlyUrl, regExpUrl } from "@constants/regExp";
import { DIRECTOR_LINK } from "@constants/settings";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IMeet, IMeetingRoom } from "@interfaces/meet.interface";
import { LinkToZoom } from "@screens/profile/components/Settings/linkToZoom/LinkToZoom";
import { meetService } from "@services/meet.service";
import { useGetAvailablePlacesQuery, useGetListMeetingRoomsQuery } from "@services/meetApi";
import { useGetZoomAuthStatusQuery } from "@services/userApi";
import { selectIsDogmaUser, userSelector } from "@store/user";
import { selectUserPersonalSettings } from "@store/userSettings";
import { updatePersonalSettings } from "@store/userSettings/api";
import { Colors } from "@theme/colors";
import { ArrowSvg, FloatMenu, HeaderModal, Loader, ModalUI, TextFont } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";

import { IStartData } from "../../activityParticipants/ActivityParticipants";
import { MeetingRoomsBooking } from "../meetingRoomsBooking/MeetingRoomsBooking";

import {
  StBlockLinksDiv,
  StBlockSettingsDiv,
  StBlockZoom,
  StButtonDotesBtn,
  StButtonZoomBtn,
  StButtonZoomMainDiv,
  StButtonZoomTitleDiv,
  StButtonPermanentLinkBtn,
  StButtonRoomsBtn,
  StButtonSettingBtn,
  StContentWrapSection,
  StInpPlaceInput,
  StLeftBlockDiv,
  StLinkWrapDiv,
  StMeetingRoomsDiv,
  StTextDescriptionTF,
  StTextTitleBlockTF,
} from "./styled";

const eventHelperUtils = new EventHelperUtils();

interface IProps {
  onClose: () => void;
  onSave: (arg: string, index: number) => void;
  currentPlace: { title: string; index: number; id: string };
  places: string[];
  meet: IMeet;
  startData: IStartData;
  allowEdit: boolean;
  handleCreate?: (name: string, value: unknown) => void;
  hotUpdate?: boolean;
}

export const ModalPlacement: FC<IProps> = ({
  onClose,
  onSave,
  currentPlace,
  places,
  meet,
  startData,
  allowEdit,
  handleCreate,
  hotUpdate,
}) => {
  const dispatch = useDispatch();
  const { directorId } = userSelector();

  const [text, setText] = useState("");
  const [permanentLink, setPermanentLink] = useState("");
  const [directorPermanentLink, setDirectorPermanentLink] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [skipGetPlaces, setSkipGetPlaces] = useState(true);
  const [zoomConference, setZoomConference] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const [isVisibleMenu, setIsVisibleMenu] = useState(false);
  const [isVisiblePlaces, setIsVisiblePlaces] = useState(false);
  const [cityMeetingRooms, setCityMeetingRooms] = useState<IMeetingRoom[] | null>(null);
  const [selectedData, setSelectedData] = useState<IMeetingRoom | null | undefined>(undefined);

  const settings = useSelector(selectUserPersonalSettings);
  const isDogmaUser = useSelector(selectIsDogmaUser);

  const { data: availablePlaces } = useGetAvailablePlacesQuery(undefined, {
    skip: skipGetPlaces,
  });
  const { data: zoomStatus } = useGetZoomAuthStatusQuery(undefined, { skip: true }); //TODO: hiiden for 1.23

  const { data: listMeetingRooms } = useGetListMeetingRoomsQuery(undefined, {
    skip: skipGetPlaces,
  });

  useEffect(() => {
    if (
      availablePlaces &&
      currentPlace &&
      currentPlace.title !== settings?.videoConferencePermanentUrl &&
      !availablePlaces?.includes(currentPlace.title) &&
      !JSON.stringify(listMeetingRooms)?.includes(currentPlace?.id)
    ) {
      setText(currentPlace.title);
    }
  }, [availablePlaces, currentPlace, settings?.videoConferencePermanentUrl, listMeetingRooms]);

  useEffect(() => {
    if (!availablePlaces && isDogmaUser) {
      setSkipGetPlaces(false);
    }
  }, [availablePlaces, isDogmaUser]);

  useEffect(() => {
    const roomsOfCity = listMeetingRooms?.filter((item) => item?.meetingRooms?.find((room) => room?.id === currentPlace?.id))[0]
      ?.meetingRooms;
    if (listMeetingRooms && roomsOfCity && currentPlace.index !== -1) {
      setTimeout(() => {
        setIsVisiblePlaces(true);
        setCityMeetingRooms(roomsOfCity ?? []);
      }, 300);
    }
  }, [currentPlace, listMeetingRooms]);

  useEffect(() => {
    if (!directorId) return;

    const directorLink = localStorage.getItem(DIRECTOR_LINK);
    setDirectorPermanentLink(directorLink ?? "");
  }, [directorId]);

  useEffect(() => {
    if (!hotUpdate && selectedData !== undefined) {
      startData.handleData({ ...meet, meetingRoomId: selectedData?.id ?? null, meetingRoom: selectedData });
      onClose();
    }
  }, [selectedData]);

  const handlePressSaveHeader = () => {
    if (zoomConference) return handleSave(zoomConference);
    if (text?.match(regExpUrl)) return handleSave(text?.match(regExpUrl)[0]);
    return handleSave(text);
  };

  const handleGetZoomConference = async () => {
    if (zoomConference) return;
    try {
      setIsLoading(true);
      const response = await meetService.getLinkToZoomConference({
        // @ts-ignore
        startTime: meet.startTime,
        endTime: meet.endTime,
        descroption: meet.description,
        name: meet.name,
      });
      setZoomConference(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = (val: string) => {
    onSave(val, currentPlace.index);
    onClose();
  };

  const handleUserLink = async () => {
    if (directorPermanentLink) {
      handleSave(directorPermanentLink);
    } else if (settings?.videoConferencePermanentUrl) {
      handleSave(settings.videoConferencePermanentUrl);
    } else {
      setIsVisible(true);
    }
  };

  const handleSavePermanentLink = () => {
    dispatch(updatePersonalSettings({ id: directorId, body: { videoConferencePermanentUrl: permanentLink } }));
    handleSave(permanentLink);
  };

  const isEdit = !!permanentLink?.match(regExpOnlyUrl) && !places?.includes(permanentLink);
  const isPermanentLink = useMemo(() => {
    if (directorPermanentLink) return places?.includes(directorPermanentLink);
    return places?.includes(settings?.videoConferencePermanentUrl ?? "");
  }, [directorPermanentLink, places, settings?.videoConferencePermanentUrl]);

  const textPermanentLinkBtn = useMemo(() => {
    if (directorId) {
      if (directorPermanentLink) return "Использовать постоянную ссылку руководителя";
      return "Использовать постоянную ссылку ассистента";
    }
    return "Использовать постоянную ссылку";
  }, [directorId, directorPermanentLink]);

  return (
    <ModalUI onClose={onClose} isVisible scrollEnable={false}>
      <HeaderModal
        title="Выбрать место"
        leftSide={{ onPressClose: onClose, isHideCancel: true }}
        isEdit={(!!text && text !== currentPlace.title) || !!zoomConference}
        rightSide={{ onPress: handlePressSaveHeader, styleText: { fontWeight: "700" } }}
      />
      <StContentWrapSection>
        <div>
          <StTextTitleBlockTF type="bold" size={18} weight="700">
            <FormattedMessage id="meetPlaceIn" />
          </StTextTitleBlockTF>

          <div>
            <StInpPlaceInput value={text} onChange={(e) => setText(e.target.value)} placeholder="Место встречи" maxLength={255} />
            <StTextDescriptionTF size={12} color={Colors.LIGHT.grey500}>
              Напишите название места или вставьте ссылку
            </StTextDescriptionTF>
          </div>
        </div>
        <div>
          <StTextTitleBlockTF type="bold" size={18} weight="700">
            <FormattedMessage id="meetPlaceOnline" />
          </StTextTitleBlockTF>

          <StBlockSettingsDiv>
            <StBlockZoom>
              {zoomStatus === "AUTHORIZED" && (
                <StButtonZoomBtn disabled={!!zoomConference} onClick={handleGetZoomConference}>
                  <StButtonZoomMainDiv>
                    <StButtonZoomTitleDiv>
                      <SvgZoom width={24} height={24} />
                      <TextFont size={16} weight="700">
                        <FormattedMessage id="addZoomVideoMeeting" />
                      </TextFont>
                    </StButtonZoomTitleDiv>
                    <RenderWithCondition condition={!zoomConference}>
                      {isLoading ? <Loader /> : <ArrowSvg type="down" width={18} height={18} />}
                    </RenderWithCondition>

                    {zoomConference && (
                      <div>
                        <StButtonDotesBtn
                          data-tooltip-id="zoom-link-menu"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsVisibleMenu(true);
                          }}
                        >
                          <SvgDotes width={18} height={18} fill={Colors.LIGHT.background.grey} />
                        </StButtonDotesBtn>
                        <FloatMenu
                          id="zoom-link-menu"
                          isOpen={isVisibleMenu}
                          config={[
                            {
                              title: "copyTheLink",
                              icon: <SvgCopy />,
                              onPress: () => eventHelperUtils.copyToClipBoard("fd"),
                            },
                            {
                              title: "delete",
                              icon: <SvgDelete />,
                              onPress: () => setZoomConference(undefined),
                              styleText: { color: Colors.LIGHT.red },
                            },
                          ]}
                          onClose={() => setIsVisibleMenu(false)}
                          place="top-start"
                        />
                      </div>
                    )}
                  </StButtonZoomMainDiv>
                  {zoomConference && (
                    <a href={zoomConference}>
                      <TextFont color={Colors.LIGHT.blue}>zoomConference</TextFont>
                    </a>
                  )}
                </StButtonZoomBtn>
              )}
            </StBlockZoom>

            <StBlockLinksDiv>
              <StButtonPermanentLinkBtn onClick={handleUserLink} disabled={isPermanentLink} isActive={isPermanentLink}>
                <TextFont color={Colors.LIGHT.white} size={16} weight="700">
                  {textPermanentLinkBtn}
                </TextFont>
              </StButtonPermanentLinkBtn>
              <StButtonSettingBtn onClick={() => setIsVisible(true)}>
                <SvgSettings />
              </StButtonSettingBtn>
            </StBlockLinksDiv>
          </StBlockSettingsDiv>
        </div>

        <StMeetingRoomsDiv>
          <StTextTitleBlockTF type="bold" size={18} weight="700">
            <FormattedMessage id="selectMeetingRoom" defaultMessage={"Подобрать переговорную"} />
          </StTextTitleBlockTF>
          <div>
            {listMeetingRooms &&
              listMeetingRooms?.map((item) => (
                <StButtonRoomsBtn
                  onClick={() => {
                    setIsVisiblePlaces(true);
                    setCityMeetingRooms(item?.meetingRooms ?? []);
                  }}
                  key={item.location}
                >
                  <StLeftBlockDiv>
                    <CalendarGrey />
                    <TextFont size={16} weight="700">{`г ${item.location}`}</TextFont>
                  </StLeftBlockDiv>
                  <ArrowSvg type="right" fill={Colors.LIGHT.placeholder.main} width={26} height={26} />
                </StButtonRoomsBtn>
              ))}
          </div>
        </StMeetingRoomsDiv>
      </StContentWrapSection>

      <ModalRenderWithCondition condition={isVisiblePlaces}>
        <MeetingRoomsBooking
          close={onClose}
          allowEdit={allowEdit}
          startData={startData}
          dataMeetingRooms={cityMeetingRooms}
          titleModal="occupancy"
          titleTable="occupancyMeetingRooms"
          setIsVisiblePlaces={() => setIsVisiblePlaces(false)}
          setSelectedData={setSelectedData}
          hotUpdate={hotUpdate}
        />
      </ModalRenderWithCondition>

      <ModalUI onClose={() => setIsVisible(false)} isVisible={isVisible}>
        <HeaderModal
          title="Постоянная ссылка"
          isEdit={isEdit}
          leftSide={{ onPressClose: () => setIsVisible(false), isHideCancel: true }}
          rightSide={{ onPress: handleSavePermanentLink }}
        />
        <StLinkWrapDiv>
          <LinkToZoom link={permanentLink} setLink={setPermanentLink} />
        </StLinkWrapDiv>
      </ModalUI>
    </ModalUI>
  );
};
