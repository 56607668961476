import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetStore } from "@constants/logic";
import { InterfaceBusinessTask } from "@interfaces";
import { getPersonalSettings as getFilterSettings } from "@store/userSettings/api";
import { deleteSearchParams } from "@utils";

import { defaultFilter, TaskSubtypes } from "./constants";

const initialState: InterfaceBusinessTask.IState = {
  search: "",
  filter: defaultFilter,
  isOpenModalTask: false,
  currentTask: null,
};

const businessTasks = createSlice({
  name: "businessTasks",
  initialState,
  reducers: {
    updateTaskId: (state, { payload }: PayloadAction<null | { id: string; subType: TaskSubtypes }>) => {
      state.isOpenModalTask = !!payload;

      if ((payload?.id && payload?.subType) || payload === null) {
        state.currentTask = payload;
      }
      if (!payload) {
        deleteSearchParams(["task", "personal"]);
      }
    },
    setFilter: (state: InterfaceBusinessTask.IState, action: PayloadAction<Partial<InterfaceBusinessTask.IFilter>>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    dropFilter: (state: InterfaceBusinessTask.IState, { payload }: PayloadAction<Partial<InterfaceBusinessTask.IFilter>>) => {
      state.filter = { ...defaultFilter, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetStore, () => initialState)

      .addCase(getFilterSettings.fulfilled, (state, { payload }) => {
        state.filter = { ...defaultFilter, ...payload.response.data.filter };
      });
  },
});

export const { updateTaskId, setFilter, dropFilter } = businessTasks.actions;

export default businessTasks.reducer;
