import React, { FC, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import SvgClose from "@assets/remove.svg";
import { DIRECTOR_LINK } from "@constants/settings";
import { IStateUser } from "@interfaces/user.interface";
import { StoreTagTypes, api } from "@services/api";
import { userSettings } from "@services/userSettings.service";
import { setCurrentEvent } from "@store/screenDay/slice";
import { changeExtraTheme } from "@store/theme/slice";
import { changeDirectorId, selectIsAssistant, userSelector } from "@store/user";
import { commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { ModalUI, TextFont } from "@ui";
import { isTablet } from "@utils/isTablet";

import { UserAvatar } from "./userAvatar/UserAvatar";

interface IProps {
  type?: "view" | "select";
}

export const SelectRole: FC<IProps> = ({ type = "select" }) => {
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);
  const [activeUser, setActiveUser] = useState<IStateUser | null>();

  const { currentUser, managers, directorId } = userSelector();
  const isAssistantRole = useSelector(selectIsAssistant);

  useEffect(() => {
    if (directorId) {
      setActiveUser(managers.filter((i) => i.id === directorId)[0] as IStateUser);
    } else {
      setActiveUser(currentUser);
    }
  }, [currentUser, directorId, managers]);

  const pressUser = async (id: string) => {
    if (activeUser?.id === id) return;

    dispatch(changeDirectorId(currentUser?.id === id ? "" : id));
    dispatch(changeExtraTheme(currentUser?.id === id ? undefined : "ASSISTANT"));
    dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "UNANSWERED_COUNT" }]));
    dispatch(setCurrentEvent(null));

    if (currentUser?.id !== id) {
      const response = await userSettings.getPersonalSettings();
      // @ts-ignore
      localStorage.setItem(DIRECTOR_LINK, response.data?.videoConferencePermanentUrl ?? "");
    }

    setIsVisible(false);
  };

  const renderUser = (user: IStateUser, isAvatar: { val: boolean; type: "btn" | "img" }) => {
    if (!user) return null;

    const borderColor = () => {
      if (activeUser?.id === currentUser?.id) {
        return isTablet ? Colors.LIGHT.green : "white";
      }
      return Colors.ASSISTANT.green;
    };
    const avatar = (size = 24) => (
      <UserAvatar
        avatarFileId={user.avatarFileId ?? null}
        style={{
          ...styles.avatar,
          width: size,
          height: size,
          ...(activeUser?.id === user.id &&
            isAvatar.val && {
              border: `2px solid ${borderColor()}`,
            }),
        }}
      />
    );

    const renderAvatar = isAvatar.type === "img" ? avatar() : <button onClick={() => pressUser(user.id)}>{avatar()}</button>;

    return isAvatar.val ? (
      renderAvatar
    ) : (
      <button
        onClick={() => pressUser(user.id)}
        style={{ ...styles.buttonUser, ...(user.id === activeUser?.id && { backgroundColor: "#E3E9E7" }) }}
      >
        {avatar(32)}
        <TextFont size={18} style={{ width: "85%", ...commonStyles.oneOfLines }}>
          {user?.lastName} {user?.firstName} {user?.middleName}
        </TextFont>
      </button>
    );
  };

  const mob = (
    <>
      <button style={{ flexDirection: "row", alignItems: "center" }} onClick={() => setIsVisible(true)}>
        {activeUser && renderUser(activeUser, { val: true, type: "img" })}
        <div style={styles.amount}>
          <TextFont size={10} type="bold" weight="700" color={"#99752C"}>
            +{[currentUser, ...managers].length - 1}
          </TextFont>
        </div>
      </button>

      <ModalUI style={styles.modal} isVisible={isVisible} onClose={() => setIsVisible(false)}>
        <div style={{ alignItems: "flex-end" }}>
          <button onClick={() => setIsVisible(false)} style={styles.roundContainer}>
            <SvgClose />
          </button>
        </div>
        <div>{[...managers, currentUser].map((item) => renderUser(item as IStateUser, { val: false, type: "btn" }))}</div>
      </ModalUI>
    </>
  );

  const tablet = (
    <div style={styles.list}>{[currentUser, ...managers].map((item) => renderUser(item as IStateUser, { val: true, type: "btn" }))}</div>
  );

  if (!isAssistantRole) return null;

  switch (type) {
    case "view": {
      return (
        <div style={{ flexDirection: "row", flex: 0.2, alignItems: "center" }}>
          {renderUser(activeUser as IStateUser, { val: true, type: "img" })}
        </div>
      );
    }
    case "select":
      return isTablet ? tablet : mob;
  }
};

const styles = {
  list: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  buttonUser: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    padding: 12,
    borderBottom: "1px solid #E3E9E7",
  },

  avatar: {
    borderRadius: 24,
    zIndex: 2,
    marginRight: 0,
  },
  amount: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    height: 24,
    borderRadius: 24,
    border: "2px solid white",
    backgroundColor: "#BE98411F",
    marginLeft: -6,
    zIndex: 1,
  },
  modal: {
    backgroundColor: "#fff",
    borderRadius: 10,
    justifyContent: "flex-start",
    paddingBottom: 20,
    paddingTop: 20,
    position: "absolute",
    width: "100%",
    bottom: 0,
    minHeight: 200,
  },
  roundContainer: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    backgroundColor: "#ECF0EF",
    marginRight: 15,
    marginBottom: 14,
  },
};
