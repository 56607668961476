import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";

import { StoreTagTypes, api } from "@services/api";

export const updateScreens = (store: ToolkitStore, type: "personal" | "task" | "meeting") => {
  const screen = window.location.pathname;

  switch (screen) {
    case "/":
      if (type === "personal" || type === "task") {
        return store.dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "TASK_LIST" }]));
      }

      if (type === "meeting") {
        return store.dispatch(
          api.util.invalidateTags([
            { type: StoreTagTypes.Meet, id: "LIST" },
            { type: StoreTagTypes.Meet, id: "UNANSWERED_COUNT" },
          ]),
        );
      }

      break;
    default:
      break;
  }
};
