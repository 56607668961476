import React, { useEffect, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { linkPrivacyPolicy, linkTermsOfUse } from "@constants/settings";
import emitter from "@services/emitter";
import { registerApi } from "@store/auth/api";
import { RootState } from "@store/index";
import { boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { isTablet } from "@utils/isTablet";

import logo from "./assets/logo.png";

export const Registration = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [firstName, setFirtsName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");

  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (isLoggedIn) {
      emitter.emit("login");

      return navigate("/");
    }
  }, [isLoggedIn]);

  const onSubmit = () => {
    dispatch(registerApi({ firstName, middleName, lastName }));
  };

  const phFirstName = useIntl().formatMessage({ id: "firstName" });
  const phLastName = useIntl().formatMessage({ id: "lastName" });
  const phMiddleName = useIntl().formatMessage({ id: "middleName" });

  return (
    <StContentWrapDiv isTablet={isTablet}>
      <div>
        <StLogoWrapDiv>
          <img src={logo} width={"146px"} />
        </StLogoWrapDiv>

        <TextFont size={18} lineHeight={24} align="center">
          <FormattedMessage id="enterYourFullName" />
        </TextFont>

        <StBlockCodeDiv>
          <StInput value={lastName} placeholder={phLastName} onChange={(e) => setLastName(e.target.value)} />
          <StInput value={firstName} placeholder={phFirstName} onChange={(e) => setFirtsName(e.target.value)} />
          <StInput value={middleName} placeholder={phMiddleName} onChange={(e) => setMiddleName(e.target.value)} />
        </StBlockCodeDiv>

        <StNextButton onClick={onSubmit}>
          <TextFont size={18} color={Colors.LIGHT.white} type="bold">
            <FormattedMessage id="next" />
          </TextFont>
        </StNextButton>
      </div>

      <StLinkWrapDiv>
        <TextFont color={Colors.LIGHT.text.grey700} align="center">
          <FormattedMessage id="privacy" />
        </TextFont>
        <a href={linkPrivacyPolicy} target="_blank">
          <TextFont color={Colors.LIGHT.green}>
            <FormattedMessage id="privacy1" />
          </TextFont>
        </a>
        <TextFont color={Colors.LIGHT.text.grey700}>и</TextFont>
        <a href={linkTermsOfUse} target="_blank">
          <TextFont color={Colors.LIGHT.green}>
            <FormattedMessage id="privacy2" />
          </TextFont>
        </a>
      </StLinkWrapDiv>
    </StContentWrapDiv>
  );
};

const StContentWrapDiv = styled.div<{ isTablet: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px 12px 12px;
  background-color: ${Colors.LIGHT.background.main};
  height: 100vh;
  ${(props) =>
    props.isTablet &&
    `
  padding-top: 30px;
  `};
`;
const StLogoWrapDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;
const StLinkWrapDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 400;
  padding: 0 21px;
  margin-bottom: 50px;
  gap: 5px;
`;
const StBlockCodeDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
`;
const StNextButton = styled.button`
  ${boxCenterCss};
  width: 100%;
  background-color: ${Colors.LIGHT.text.accent};
  border-radius: 10px;
  height: 56px;
  margin-bottom: 10px;
`;
const StInput = styled.input`
  font-size: 18px;
  font-weight: 400;
  border-radius: 10px;
  border: 1px solid ${Colors.LIGHT.lightGrey250};
  padding-left: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 10px;
  background-color: ${Colors.LIGHT.white};
`;
