import React, { FC, useCallback, useMemo, useState } from "react";

import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { LoaderRenderWithCondition, ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { TTaskSubType } from "@interfaces/businessTask.interface";
import { useGetStatisticTaskQuery, useGetTaskListQuery } from "@services/taskApi";
import { TaskSubtypes } from "@store/businessTask";
import { selectCurrentDate } from "@store/screenDay";
import { ButtonMain, Spacer, boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { Prompting, TextFont } from "@ui";
import { toFormatDate } from "@utils";

import { Modal, SwipeList } from "../ListTasks";

import { ReactComponent as SvgEmptyList } from "./assets/empty_list.svg";
import { ButtonsAnalysis } from "./Buttons";
import { AdditionalTask, Comment, HeaderAnalysis, NotEngaged } from "./components";
import { IActiveModal, TypeActiveModal, TypeElements, titlesStatistic } from "./config";

export const Analysis: FC = () => {
  const { isTablet } = useDimension();

  const [isVisible, setIsVisible] = useState(false);
  const [activeModal, setActiveModal] = useState<IActiveModal | undefined>(undefined);
  const [id, setId] = useState("");
  const [itemSubtype, setItemSubtype] = useState<TaskSubtypes>();

  const dateNow = useSelector(selectCurrentDate);

  const { data, isFetching: isLoadingList } = useGetTaskListQuery({
    params: { date: toFormatDate(dateNow), next: 15, current: 0 },
  });
  const { data: statistic } = useGetStatisticTaskQuery({ date: toFormatDate(dateNow) });
  const tasks = data?.tasks ?? [];

  const closeModal = useCallback(() => setActiveModal(undefined), []);

  const handlePress = (id: string, subType?: TTaskSubType) => {
    setItemSubtype(subType);
    setId(id);
    setIsVisible(true);
  };

  const isToday = useMemo(() => toFormatDate(dateNow) === toFormatDate(dayjs()), [dateNow]);
  const isPersonalTask = useMemo(() => itemSubtype === TaskSubtypes.Personal, [itemSubtype]);

  const msgDidYouDoThisTask = useIntl().formatMessage({ id: "didYouDoThisTask" });
  const msgToday = useIntl().formatMessage({ id: "today" }).toLowerCase();

  const modals: Record<TypeActiveModal, JSX.Element> = {
    comment: <Comment close={closeModal} id={activeModal?.id} isPersonalTask={itemSubtype === TaskSubtypes.Personal} />,
    additionalTask: <AdditionalTask close={closeModal} />,
    notEngaged: <NotEngaged id={activeModal?.id} close={closeModal} isPersonalTask={isPersonalTask} />,
  };

  const elements: Record<TypeElements, JSX.Element> = {
    title: (
      <RenderWithCondition condition={tasks.length}>
        <StDateWrapDiv>
          <TextFont type="bold" size={20} weight="700" lineHeight={28}>{`${msgDidYouDoThisTask} ${
            isToday ? msgToday : dateNow.format("DD.MM.YY г.")
          }?`}</TextFont>
        </StDateWrapDiv>
      </RenderWithCondition>
    ),
    list: (
      <RenderWithCondition condition={tasks.length}>
        <LoaderRenderWithCondition condition={isLoadingList}>
          <SwipeList
            allowPressIcon
            data={tasks}
            press={handlePress}
            buttons={(data) => <ButtonsAnalysis {...data} setActiveModal={setActiveModal} />}
            setItemSubtype={setItemSubtype}
          />
        </LoaderRenderWithCondition>
      </RenderWithCondition>
    ),
    button: (
      <StButtonWrapBtn>
        <ButtonMain onClick={() => setActiveModal({ ...activeModal, visible: "additionalTask" })}>
          <TextFont type="bold" size={18} weight="700" color={Colors.LIGHT.white} align="center">
            <FormattedMessage id="addUnplannedTask" />
          </TextFont>
        </ButtonMain>
      </StButtonWrapBtn>
    ),
    statistic: (
      <RenderWithCondition condition={statistic}>
        <>
          <TextFont type="bold" size={20} weight="700" lineHeight={28}>
            <FormattedMessage id="planningResult" />
          </TextFont>
          <Spacer px={10} />

          {statistic &&
            Object.keys(statistic).map(
              (i) =>
                i !== "businessTasks" && (
                  <StItemWrapDiv key={i}>
                    <StTextFontCustomTF size={16} flex={6}>
                      <FormattedMessage id={titlesStatistic[i]} />
                    </StTextFontCustomTF>
                    <StTextFontCustomTF type="bold" size={16} flex={1} align="center">
                      {statistic[i]}
                    </StTextFontCustomTF>
                  </StItemWrapDiv>
                ),
            )}
        </>
      </RenderWithCondition>
    ),
  };

  return (
    <StContentWrapDiv>
      <HeaderAnalysis />
      <StContentDiv isTablet={isTablet}>
        <StLeftBlockDiv isTablet={isTablet}>
          {tasks.length ? (
            <>
              {elements.title}
              <div>{elements.list}</div>
            </>
          ) : (
            <StEmptyListWrapDiv>
              <SvgEmptyList />
              <Spacer px={10} />
              <TextFont type="bold" size={24}>
                Список задач пуст
              </TextFont>
              <div>{elements.button}</div>
            </StEmptyListWrapDiv>
          )}
        </StLeftBlockDiv>
        <StRightBlockDiv>
          <RenderWithCondition condition={tasks.length}>
            <>
              <div>{elements.button}</div>
              <Spacer px={40} />
            </>
          </RenderWithCondition>

          <StStatInfoDiv>{elements.statistic}</StStatInfoDiv>
        </StRightBlockDiv>
      </StContentDiv>

      <RenderWithCondition condition={Boolean(activeModal)}>{modals[activeModal?.visible]}</RenderWithCondition>

      <Prompting keySetting="isPromptAnalysis" kind="analysis" />
      <ModalRenderWithCondition condition={isVisible}>
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} id={id} taskSubType={itemSubtype} setId={handlePress} />
      </ModalRenderWithCondition>
    </StContentWrapDiv>
  );
};

const StContentWrapDiv = styled.div`
  display: grid;
  grid-template-rows: 7% 93%;
  height: 100vh;
`;
const StContentDiv = styled.div<{ isTablet: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${({ isTablet }) =>
    isTablet &&
    `
    flex-direction: row;
  `}
`;
const StLeftBlockDiv = styled.div<{ isTablet?: boolean }>`
  padding: 20px 0;
  background-color: ${Colors.LIGHT.background.main};
  flex: 1.2;
  border-top-right-radius: 20px;
  ${({ isTablet }) =>
    isTablet &&
    `
    margin-right: 20px;
  `}
`;
const StRightBlockDiv = styled.div`
  padding: 20px 0;
  background-color: ${Colors.LIGHT.background.main};
  flex: 0.8;
  border-top-left-radius: 20px;
  padding: 20px 18px 0;
`;
const StEmptyListWrapDiv = styled.div`
  ${boxCenterCss};
  flex-direction: column;
  height: 80%;
  gap: 20px;
`;
const StStatInfoDiv = styled.div`
  margin: 0 18px;
`;
const StButtonWrapBtn = styled.div`
  padding: 0 12px;
`;
const StDateWrapDiv = styled.div`
  padding: 0 12px;
  margin-bottom: 20px;
`;
const StItemWrapDiv = styled.div`
  ${boxCenterCss};
  justify-content: space-between;
  margin-bottom: 6px;
`;
const StTextFontCustomTF = styled(TextFont)<{ flex: number }>`
  ${({ flex }) =>
    flex &&
    `
  flex: ${flex};
  
`}
`;
