import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgAttention } from "@assets/danger-circle.svg";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

interface IProps {
  style?: React.CSSProperties;
  textInfo: string;
}

export const RepeatInfo: FC<IProps> = ({ style, textInfo }) => (
  <StContentWrapDiv>
    <StLineTextDiv>
      <StWrapIconDiv>
        <SvgAttention fill={Colors.LIGHT.orange} />
      </StWrapIconDiv>
      <StTextAttentionTF size={16} lineHeight={24} weight="700">
        <FormattedMessage id={"attention"} defaultMessage={"Внимание!"} />
      </StTextAttentionTF>
    </StLineTextDiv>
    <StLineTextDiv>
      <div>
        <StWrapIconDiv />
      </div>
      <TextFont size={14} lineHeight={22}>
        <FormattedMessage id={textInfo} />
      </TextFont>
    </StLineTextDiv>
  </StContentWrapDiv>
);

const StContentWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 9px 16px;
  background-color: ${Colors.LIGHT.orange500};
  border-radius: 10px;
  width: 100%;
  border: 0.5px solid ${Colors.LIGHT.orange};
  box-sizing: border-box;
`;
const StLineTextDiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const StWrapIconDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  gap: 10px;
`;
const StTextAttentionTF = styled(TextFont)`
  margin-left: 5px;
`;
