import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { LineHorizontal } from "@components/shared";
import { RoundButton } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont, ModalUI } from "@ui";

interface IActionsSheetModeToDate {
  onClose: () => void;
  goToSpecificDay: () => void;
  openAddDays: () => void;
  containerStyle?: React.CSSProperties;
}

const ActionsSheetModeToDate: FC<IActionsSheetModeToDate> = (props) => (
  <ModalUI isVisible={true} className="bottomModal" onClose={props.onClose} style={props.containerStyle}>
    <div>
      <RoundButton onClick={props.onClose}>
        <SvgClose />
      </RoundButton>
      <StContentDiv>
        <StTextControlDayTF size={18} color={Colors.LIGHT.text.darkGreen} onClick={props.goToSpecificDay}>
          <FormattedMessage id={"goToDay"} defaultMessage={"Перейти в день"} />
        </StTextControlDayTF>
        <LineHorizontal />
        <StTextControlDayTF size={18} color={Colors.LIGHT.text.darkGreen} onClick={props.openAddDays}>
          <FormattedMessage id={"setWorkTime"} defaultMessage={"Установить время работы"} />
        </StTextControlDayTF>
      </StContentDiv>
    </div>
  </ModalUI>
);

export default ActionsSheetModeToDate;

const StContentDiv = styled.div`
  margin-top: 65px;
  margin-bottom: 50px;
`;
const StTextControlDayTF = styled(TextFont)`
  padding: 12px;
  cursor: pointer;
`;
