import React, { FC, useCallback } from "react";

import { SuccessfulSendAnimation } from "@screens/profile/components/sender/sendFeedback/successfulSendAnimation/SuccessfulSendAnimation";
import { TextFont } from "@ui";
import { NotificationCustom } from "@ui/notification/NotificationCustom";

interface ISendFeedbackNotification {
  closeParent: () => void;
  setIsSendFeedbackVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SendFeedbackNotification: FC<ISendFeedbackNotification> = ({ closeParent, setIsSendFeedbackVisible }) => {
  const handleClose = useCallback(() => {
    closeParent();
    setIsSendFeedbackVisible(false);
  }, [closeParent, setIsSendFeedbackVisible]);

  return (
    <NotificationCustom onClose={handleClose}>
      <div style={styles.container}>
        <div style={{ height: 80 }}>
          <SuccessfulSendAnimation />
        </div>
        {/* @ts-ignore */}
        <TextFont style={styles.text}>Сообщение успешно отправлено!</TextFont>
      </div>
    </NotificationCustom>
  );
};

const styles = {
  container: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 16,
    marginRight: 16,
  },
  text: {
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 12,
    textAlign: "center",
  },
};
