import React, { FC, memo, useMemo } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { TFirstArgPress } from "@interfaces/eventsOfDay.interface";
import { selectConfigure } from "@store/configure";
import { selectCurrentDate, setJoinData } from "@store/screenDay";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { arePropsEqual } from "@utils/arePropsEqual";
import { toFormatDate, toFormatISO } from "@utils/toFormatTime";

import { heightBlockEvent } from "../constants";

interface IRow {
  hour: number;
  width: number;
  currentTime?: Dayjs;
  isHide?: boolean;
  press?: (arg: TFirstArgPress, type: "TASK" | "MEETING", isPersonalTask?: boolean) => void;
}

export const Row: FC<IRow> = memo(function Row({ hour, width, currentTime, isHide, press }) {
  const dispatch = useDispatch();

  const currentMinute = currentTime?.minute();

  const dateNow = useSelector(selectCurrentDate);
  const {
    settings: { isTightMode },
  } = useSelector(selectConfigure);

  const handlePress = () => {
    if (hour === 24) return;

    const startTime = dateNow.hour(hour).minute(0).second(0);
    const calculatedEndTime =
      startTime.format("HH") === "23" ? toFormatISO(startTime.endOf("hour")) : toFormatISO(startTime.add(1, "hour"));

    dispatch(setJoinData({ startTime: toFormatISO(startTime), endTime: calculatedEndTime }));
    press({ id: "" }, "MEETING");
  };

  const formatCurrentMinute = (currentMinute ?? 0) < 10 ? `0${currentMinute}` : currentMinute;
  const formatHour = useMemo(() => {
    let tempHour = hour;

    if (tempHour === 24) tempHour = 0;

    return tempHour < 10 ? `0${tempHour}` : tempHour;
  }, [hour]);
  const isCurrentHour = hour === currentTime?.hour() && toFormatDate(dateNow) === toFormatDate(dayjs());

  return (
    <StContentWrapDiv isHide={isHide} data-testid={`timetable-row-${hour}`}>
      <StTextTimeTF size={12} color={Colors.LIGHT.text.grey400}>{`${formatHour}:00`}</StTextTimeTF>
      <StButtonItemBtn disabled={isHide} onClick={handlePress}>
        <StLineTimeDiv />

        <RenderWithCondition condition={isTightMode}>
          <StLineTimeTightModeDiv />
        </RenderWithCondition>
      </StButtonItemBtn>

      <RenderWithCondition condition={isCurrentHour && currentTime}>
        <StBlockCurrentTimeDiv top={(heightBlockEvent / 60) * currentMinute}>
          <StTextCurrentTimeTF color={Colors.LIGHT.red} size={10}>{`${formatHour}:${formatCurrentMinute}`}</StTextCurrentTimeTF>
          <StLineCurrentTimeDiv width={width} />
        </StBlockCurrentTimeDiv>
      </RenderWithCondition>
    </StContentWrapDiv>
  );
}, arePropsEqual);

const StContentWrapDiv = styled.div<{ isHide: boolean }>`
  display: flex;
  height: 38px;
  position: relative;
  ${({ isHide }) => isHide && "opacity: 0.3"}
`;
const StTextTimeTF = styled(TextFont)`
  line-height: 20px;
  text-align: center;
  width: 50px;
  margin-top: -8px;
  height: 100%;
`;
const StButtonItemBtn = styled.button`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
`;
const StLineTimeDiv = styled.div`
  height: 0.9px;
  width: 100%;
  background-color: ${Colors.LIGHT.grey200};
  z-index: 2;
`;
const StLineCurrentTimeDiv = styled.div<{ width: number }>`
  height: 0.9px;
  width: ${({ width }) => width}px;
  background-color: ${Colors.LIGHT.red};
`;
const StLineTimeTightModeDiv = styled.div`
  position: absolute;
  height: 0.5px;
  z-index: 10;
  width: 100%;
  background-color: ${Colors.LIGHT.lighGrey};
  left: 50px;
  top: 0;
`;
const StBlockCurrentTimeDiv = styled.div<{ top: number }>`
  position: absolute;
  display: flex;
  z-index: 2;
  width: 100%;
  left: 17px;
  top: ${({ top }) => top}px;
  gap: 8px;
`;

const StTextCurrentTimeTF = styled(TextFont)`
  margin-top: -5px;
`;
