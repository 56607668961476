import React, { ReactElement } from "react";

import styled from "styled-components";

import { ReactComponent as SvgCancel } from "@assets/cancel.svg";
import { ReactComponent as SvgCheck } from "@assets/circleCheck.svg";
import { ReactComponent as SvgQuestion } from "@assets/question.svg";
import { TTypeParticipants } from "@interfaces/meet.interface";
import { TextFont } from "@ui";

import { AttendanceStatuses } from "../types";

export const getButtonContentByStatus = (status: TTypeParticipants): ReactElement | null => {
  let text = "";
  let Icon = SvgQuestion;

  switch (status) {
    case AttendanceStatuses.ATTENDER:
      text = "Пойду";
      Icon = SvgCheck;
      break;
    case AttendanceStatuses.NON_ATTENDER:
      text = "Не пойду";
      Icon = SvgCancel;
      break;
    case AttendanceStatuses.MAYBE_ATTENDER:
      text = "Может быть пойду";
      Icon = SvgQuestion;
      break;
    default:
      break;
  }

  return (
    <>
      <Icon />
      <StTextStatusTF size={18}>{text}</StTextStatusTF>
    </>
  );
};
const StTextStatusTF = styled(TextFont)`
  margin-left: 9px;
`;
