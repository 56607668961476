import React, { useMemo } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { daysOfWeekDefault } from "@screens/day/components/header/modal/CalendarCustom";
import { stylesForDay } from "@shared/planningWork/utils";
import { selectCurrentDate } from "@store/screenDay";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { toFormatDate } from "@utils/toFormatTime";

type TWeek = Record<string, Dayjs>;

interface IEmptyCalendar {
  type?: "default" | "multituple";
  press: (...arg: any) => void;
  days?: any[];
  currentDay: string;
  startDay: string | null;
  yearOverview?: boolean;
  deadline?: string;
}

export const EmptyCalendar = ({ press, days = [], currentDay, type = "default", startDay, deadline }: IEmptyCalendar) => {
  const dateNow = useSelector(selectCurrentDate);

  const month = useMemo(() => {
    const resultMonth: Array<TWeek> = [];
    let index = 0;
    const arrayDays = [...Array(dateNow.daysInMonth() + 1).keys()].filter((i) => i !== 0);

    for (let i = 0; i < 5; i++) {
      const week: TWeek = {};
      daysOfWeekDefault.forEach((day: string) => {
        if (index < arrayDays.length) {
          week[day] = dateNow.date(arrayDays[index]);
          index += 1;
        }
      });
      resultMonth.push(week);
    }

    return resultMonth;
  }, []);

  return (
    <StContentWrapDiv>
      {month.map((week, index) => (
        <StContentDiv key={index}>
          {Object.entries(week).map(([, dateWeek]) => {
            if (!dateWeek) return <StBodyCellDiv />;

            const config = {
              isSelect: {
                multituple: !!days.find((i) => i.date === toFormatDate(dateWeek)),
                default: toFormatDate(dateWeek) === (currentDay ? toFormatDate(dayjs(currentDay)) : toFormatDate(dateNow)),
              },
              isDisabled: {
                multituple: toFormatDate(dateWeek) < toFormatDate(dayjs()),
                default: startDay ? dateWeek < dayjs(startDay) : false,
              },
            };

            const { wrapper, text } = stylesForDay({
              isSelect: config.isSelect[type],
              isToday: toFormatDate(dateNow) === toFormatDate(dateWeek),
              isDeadline: toFormatDate(dayjs(deadline ?? "")) === toFormatDate(dateWeek),
              deadline,
            });

            return (
              <StButtonCellBtn key={String(dateWeek)} onClick={() => press(dateWeek)}>
                <StTextWrapDiv style={{ ...wrapper }}>
                  <TextFont size={20} weight="400" style={{ ...text }}>
                    {`${new Date(String(dateWeek)).getDate()}`}
                  </TextFont>
                </StTextWrapDiv>
              </StButtonCellBtn>
            );
          })}
        </StContentDiv>
      ))}
    </StContentWrapDiv>
  );
};

const StContentWrapDiv = styled.div`
  background-color: ${Colors.LIGHT.white};
  border-radius: 13px;
  padding: 9px 16px;
`;
const StContentDiv = styled.div`
  display: flex;
`;
const StBodyCellDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  width: 14.5%;
`;
const StTextWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 2px;
`;
const StButtonCellBtn = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  width: 14.5%;
  position: relative;
`;
