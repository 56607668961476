import { IEventOfDaySimplified } from "@interfaces/eventsOfDay.interface";

import { StoreTagTypes, api } from "./api";

export const calendarApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCalendarEventsList: build.query<Record<string, IEventOfDaySimplified[]>, { startDate: string; endDate: string }>({
      query: ({ startDate, endDate }) => {
        if (!startDate || !endDate) return null;

        return {
          url: `/api/api-gateway/v1/events/period?startDate=${startDate}&endDate=${endDate}`,
          method: "get",
        };
      },
      providesTags: (result, error) => {
        if (!error && result) {
          return Object.keys(result).map((key) => ({ type: StoreTagTypes.Calendar, id: key }));
        }

        return [];
      },
    }),
  }),
});

export const { useGetCalendarEventsListQuery } = calendarApi;
