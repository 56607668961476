import { memo, FC, useRef, useState, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgMeet } from "@assets/meet.svg";
import { RenderWithCondition } from "@hoc";
import { IMeet } from "@interfaces/meet.interface";
import { Modal } from "@screens/day/components/timetable/main/modal/Modal";
import { api, StoreTagTypes } from "@services/api";
import { IAttachEventMeta } from "@services/meet.service";
import { AttachEventsEventType, unattachEvent } from "@store/attachedMeetingTasks/api";
import { attachEvents } from "@store/attachedMeetingTasks/api";
import { getSelectedMeetings, selectAttachedEvents } from "@store/attachedMeetingTasks/selectors";
import {
  removeSelectedItemId,
  addSelectedItemId,
  addSelectedMeetings,
  removeSelectedMeetingById,
  addEventsMeta,
  removeItemFromEventsMeta,
} from "@store/attachedMeetingTasks/slice";
import { selectTheme } from "@store/theme";
import { ButtonBillet } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { ArrowSvg } from "@ui";

import { EventItem } from "./EventItem";
import { ModalMeetingAttach } from "./ModalMeetingsList";

interface ITaskMeetings {
  allowMeetingsAttach?: boolean;
  taskId: string;
  initialTaskIds?: string[];
  onlyButton?: boolean;
  closeOverModal?: () => void;
  enableAddButton?: boolean;
  disable?: { value: boolean; press: () => void };
}

export const TaskMeetings: FC<ITaskMeetings> = memo(
  ({ allowMeetingsAttach: allowTaskAddition, taskId, closeOverModal, onlyButton = false, enableAddButton = true, disable }) => {
    const dispatch = useDispatch();
    const currenDetailedMeetingId = useRef("");

    const theme = selectTheme("extra");

    const [isDetailedMeetingViewModalVisible, setIsDetailedMeetingViewModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const uniqueIds = new Set<string>();

    const { errorMsg } = useSelector(selectAttachedEvents);
    const attachedMeetings = useSelector(getSelectedMeetings);

    const openMeetingDetailedPage = (meetingId: string) => {
      currenDetailedMeetingId.current = meetingId;
      setIsDetailedMeetingViewModalVisible(true);
    };

    const handleAttachItems = async (itemsEntities: IMeet[], eventsMeta: IAttachEventMeta[]) => {
      dispatch(addSelectedMeetings(itemsEntities));

      for (const item of eventsMeta) {
        uniqueIds.add(item.id);
      }

      for (const id of Array.from(uniqueIds)) {
        dispatch(addSelectedItemId(id));
      }

      if (taskId && eventsMeta.length) {
        await dispatch(attachEvents({ eventId: taskId, eventsMeta, eventType: AttachEventsEventType.MeetingsToTask }));
        dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Task, id: taskId }]));
        return;
      }

      dispatch(addEventsMeta(eventsMeta));
      if (closeOverModal) closeOverModal();
    };

    const onEventDelete = (eventId: string) => {
      if (taskId) {
        dispatch(unattachEvent([{ meetingId: eventId, taskId }]));
      }

      dispatch(removeSelectedItemId(eventId));
      dispatch(removeSelectedMeetingById(eventId));
      dispatch(removeItemFromEventsMeta(eventId));
    };

    useEffect(() => {
      if (errorMsg) {
        for (const id of Array.from(uniqueIds)) {
          onEventDelete(id);
        }
        uniqueIds.clear();
      }
    }, [errorMsg]);

    const onPress = () => {
      if (disable?.value) {
        return disable.press();
      }
      return setIsModalVisible(true);
    };

    const renderList = attachedMeetings.map((itemAttach) => {
      const renderItem = (item: IMeet) => (
        <EventItem
          key={item.id}
          item={item}
          allowDeletion={allowTaskAddition}
          onDelete={() => onEventDelete(item.id)}
          date={""}
          onPress={openMeetingDetailedPage}
        />
      );
      return renderItem(itemAttach);
    });

    return (
      <>
        {attachedMeetings.length && !onlyButton ? (
          <StContentWrapDiv>
            <TextFont size={18} weight="700">
              <FormattedMessage id="taskAppointments" />
            </TextFont>
            <RenderWithCondition condition={attachedMeetings.length}>
              <StListWrapDiv>{renderList}</StListWrapDiv>
            </RenderWithCondition>
            <RenderWithCondition condition={allowTaskAddition}>
              <ButtonBillet onClick={onPress}>
                <TextFont size={16} color={theme.text.accent}>
                  {"+ "}
                  <FormattedMessage id="addMeeting" defaultMessage={"Добавить встречу"} />
                </TextFont>
                <ArrowSvg type="right" fill={Colors.LIGHT.placeholder.main} width={26} height={26} />
              </ButtonBillet>
            </RenderWithCondition>
          </StContentWrapDiv>
        ) : (
          enableAddButton && (
            <StButtonItemBtn onClick={() => setIsModalVisible(true)}>
              <SvgMeet width={17} height={15} fill={Colors.LIGHT.placeholder.main} />
              <TextFont color={onlyButton ? Colors.LIGHT.text.main : Colors.LIGHT.placeholder.main} size={onlyButton ? 16 : 18}>
                <FormattedMessage id="meetingAdd" />
              </TextFont>
            </StButtonItemBtn>
          )
        )}
        <RenderWithCondition condition={isModalVisible}>
          <ModalMeetingAttach
            isVisible={isModalVisible}
            setIsVisible={setIsModalVisible}
            handleAttachItems={handleAttachItems}
            onItemDelete={onEventDelete}
          />
        </RenderWithCondition>

        {/* Open meeting detailed page */}
        <RenderWithCondition condition={isDetailedMeetingViewModalVisible}>
          <Modal
            isVisible={isDetailedMeetingViewModalVisible}
            meetId={currenDetailedMeetingId.current}
            setIsVisible={setIsDetailedMeetingViewModalVisible}
          />
        </RenderWithCondition>
      </>
    );
  },
);

const StContentWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const StListWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
`;
const StButtonItemBtn = styled.button`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
`;
