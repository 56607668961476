import React, { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as RemoveGreen } from "@assets/remove-green.svg";
import { ReactComponent as SearchGreen } from "@assets/search-green.svg";
import { ReactComponent as SvgSearch } from "@assets/search.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IFilter } from "@interfaces/businessTask.interface";
import { SelectRole } from "@shared";
import { selectBusinessTasks, setFilter } from "@store/businessTask";
import { selectTheme, themeSelector } from "@store/theme";
import { selectUserPermissoins } from "@store/user";
import { boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";
import { myTracker } from "@utils/myTracker";

import { ReactComponent as Filter } from "./assets/filter.svg";
import { ReactComponent as Grouping } from "./assets/grouping.svg";
import { ReactComponent as Project } from "./assets/project.svg";
import { FilterModal } from "./components/filterModal";
import { GroupingModal } from "./components/groupingModal";
import { ProjectModal } from "./components/projectModal";

const eventHelperUtils = new EventHelperUtils();

export const Header = () => {
  const dispatch = useDispatch<any>();

  const [isSearchPressed, setIsSearchPressed] = useState<boolean>(false);
  const [isShownProject, setIsShownProject] = useState<boolean>(false);
  const [isShownGrouping, setIsShownGrouping] = useState<boolean>(false);
  const [isShownFilter, setIsShownFilter] = useState<boolean>(false);

  const {
    filter: { name },
    filter,
  } = useSelector(selectBusinessTasks);
  const permissons = useSelector(selectUserPermissoins);
  const theme = selectTheme("extra");
  const { extra } = themeSelector();
  const [search, setSearch] = useState(name);

  useEffect(() => {
    if (search) {
      setIsSearchPressed(true);
    }
  }, []);

  const applyEvent = async (argFilter: Partial<IFilter>, callback?: () => void) => {
    dispatch(
      setFilter({
        ...argFilter,
        pageable: {
          next: 25,
          current: 0,
        },
      }),
    );
    if (callback) callback();
  };

  const handleSearch = (value: string) => {
    setSearch(value);

    eventHelperUtils.debounce(() => {
      dispatch(
        setFilter({
          ...filter,
          name: value,
          pageable: {
            next: 25,
            current: 0,
          },
        }),
      );
    }, 1000);
  };

  const handleToggleSearch = (arg: boolean) => {
    if (!arg && search) {
      handleSearch("");
    }
    setIsSearchPressed(arg);
  };

  const colorSvg = extra === "ASSISTANT" ? Colors.ASSISTANT.background.darkGreen : Colors.ASSISTANT.grey500;

  return (
    <StContentWrapSection>
      <StBlockLeftDiv>
        <RenderWithCondition condition={permissons.WORK_WITH_SPACE_PROJECTS}>
          <StButtonLeftBtn disabled={extra === "ASSISTANT"} onClick={() => setIsShownProject(true)}>
            <Project fill={extra === "ASSISTANT" ? Colors.LIGHT.lightYellow100 : Colors.LIGHT.text.accent} />
          </StButtonLeftBtn>
        </RenderWithCondition>
        <RenderWithCondition condition={extra === "ASSISTANT"}>
          <SelectRole type="view" />
        </RenderWithCondition>
      </StBlockLeftDiv>
      <StTextTitleTF type="bold" size={16} weight="700" color={theme.text.main} align="center">
        <FormattedMessage id={"availableTasks"} />
      </StTextTitleTF>
      <StBlockRightDiv>
        <StSearchLineDiv>
          {isSearchPressed && (
            <StInputWrapDiv>
              <SvgSearch />
              <StSearchInput value={search} onChange={(e) => handleSearch(e.target.value)} placeholder="Поиск" maxLength={255} />
            </StInputWrapDiv>
          )}
          <StButtonCloseBtn onClick={() => handleToggleSearch(!isSearchPressed)}>
            {isSearchPressed ? <RemoveGreen fill={colorSvg} /> : <SearchGreen fill={colorSvg} />}
          </StButtonCloseBtn>
        </StSearchLineDiv>

        <StButtonGroupBtn
          onClick={() => {
            myTracker("GroupingClick");
            setIsShownGrouping(true);
          }}
        >
          <Grouping fill={colorSvg} />
        </StButtonGroupBtn>
        <StButtonFilterBtn
          onClick={() => {
            myTracker("FilterClick");
            setIsShownFilter(true);
          }}
        >
          <Filter fill={colorSvg} />
        </StButtonFilterBtn>
      </StBlockRightDiv>
      <ModalRenderWithCondition condition={isShownProject}>
        <ProjectModal isVisible={isShownProject} closeEvent={() => setIsShownProject(false)} />
      </ModalRenderWithCondition>
      <FilterModal applyEvent={applyEvent} filter={filter} isVisible={isShownFilter} closeEvent={() => setIsShownFilter(false)} />
      <ModalRenderWithCondition condition={isShownGrouping}>
        <GroupingModal
          applyEvent={applyEvent}
          groupByType={filter.groupByType}
          isVisible={isShownGrouping}
          closeEvent={() => setIsShownGrouping(false)}
        />
      </ModalRenderWithCondition>
    </StContentWrapSection>
  );
};

const StContentWrapSection = styled.section`
  ${boxCenterCss};
  justify-content: space-between;
  flex: 1;
  padding: 4px 13px;
  height: 100%;
  position: relative;
`;
const StBlockLeftDiv = styled.div`
  ${boxCenterCss};
  justify-content: flex-start;
  flex: 2;
  gap: 20px;
  z-index: 1;
`;
const StBlockRightDiv = styled.div`
  ${boxCenterCss};
  justify-content: flex-end;
  flex: 2;
  z-index: 1;
`;
const StSearchLineDiv = styled.div`
  ${boxCenterCss};
  justify-content: flex-end;
  width: auto;
  flex: 1.5;
`;
const StInputWrapDiv = styled.div`
  ${boxCenterCss};
  height: 100%;
  width: 90%;
  border-radius: 10px;
  background-color: ${Colors.LIGHT.disabled};
  padding: 9px 8px;
`;
const StButtonLeftBtn = styled.button`
  display: flex;
  justify-content: flex-start;
`;
const StButtonCloseBtn = styled.button`
  margin-left: 20px;
`;
const StButtonGroupBtn = styled.button`
  ${boxCenterCss};
  justify-content: flex-end;
  margin-left: 20px;
  width: 50px;
  height: 25px;
`;
const StButtonFilterBtn = styled.button`
  ${boxCenterCss};
  justify-content: flex-end;
  width: 42px;
  margin-left: 0px;
`;
const StTextTitleTF = styled(TextFont)`
  flex: 1;
`;
const StSearchInput = styled.input`
  margin-left: 6px;
  width: 100%;
`;
