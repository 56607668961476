/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, memo, useState, useRef, useCallback, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgPlus } from "@assets/plus.svg";
import { RenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { Modal as ModalTask } from "@screens/day/components/ListTasks";
import { Item } from "@screens/day/components/ListTasks/taskItem/Item";
import { selectAttachedEvents } from "@store/attachedMeetingTasks/selectors";
import { selectTheme } from "@store/theme";
import { Colors } from "@theme/colors";
import { TextFont, ArrowSvg, Loader } from "@ui";
import { arePropsEqual } from "@utils/arePropsEqual";

import { ModalItemAttach } from "./ModalTaskList";

interface IMeetingTasks {
  allowTaskDeletion?: boolean;
  allowTaskAddition?: boolean;
  attachedTasks?: ITask[];
  handleAttachTasks: (tasks: ITask[]) => void;
  onTaskDelete: (id: string) => void;
  onlyButton?: boolean;
  close3DotMenu?: () => void;
}

export const MeetingTasks: FC<IMeetingTasks> = memo(
  ({ allowTaskAddition, allowTaskDeletion, attachedTasks, onTaskDelete, handleAttachTasks, onlyButton, close3DotMenu }) => {
    const { isLoading } = useSelector(selectAttachedEvents);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [isDetailedTaskViewModalVisible, setIsDetailedTaskViewModalVisible] = useState(false);
    const currenDetailedTaskId = useRef("");
    const selectedTaskIds = useRef<string[]>([]);
    const theme = selectTheme("extra");

    const openTaskDetailedPage = (taskId: string) => {
      currenDetailedTaskId.current = taskId;
      setIsDetailedTaskViewModalVisible(true);
    };

    const SelectedTaskList = useCallback(() => {
      const renderItem = (index: number, item: ITask) => (
        <Item
          key={item.id}
          index={index}
          item={item}
          press={() => {
            openTaskDetailedPage(item.id);
          }}
          meetingTaskStyles
          allowDeletion={allowTaskDeletion}
          onDelete={() => onTaskDelete(item.id)}
          onRightClick={() => {}}
        />
      );

      const renderList = attachedTasks.map((item, index) => renderItem(index, item));

      return <>{renderList}</>;
    }, [attachedTasks]);

    useEffect(() => {
      const temp: string[] = [];

      for (const task of attachedTasks) {
        temp.push(task.id);
      }

      selectedTaskIds.current = temp;
    }, [attachedTasks]);

    if (!allowTaskAddition && !attachedTasks.length) return null;

    return (
      <>
        {onlyButton ? (
          <StButtonAddBtn onClick={() => setIsModalVisible(true)}>
            <SvgPlus fill={Colors.LIGHT.grey} width={20} height={20} />
            <TextFont size={16}>
              <FormattedMessage id="addTask" defaultMessage={"Добавить задачу"} />
            </TextFont>
          </StButtonAddBtn>
        ) : (
          <StContentWrapDiv>
            <StDropDownDiv>
              <TextFont size={18} weight="700" style={{ marginBottom: 5 }}>
                Задачи по встрече
              </TextFont>
              <RenderWithCondition condition={attachedTasks.length > 1}>
                <button onClick={() => setIsOpen((state) => !state)}>
                  <ArrowSvg type={isOpen ? "up" : "down"} />
                </button>
              </RenderWithCondition>
            </StDropDownDiv>
            <RenderWithCondition condition={isOpen}>
              <>
                <SelectedTaskList />
                {isLoading && <Loader />}

                <RenderWithCondition condition={allowTaskAddition}>
                  <StBilletBtn onClick={() => setIsModalVisible(true)}>
                    <TextFont size={16} color={theme.text.accent}>
                      {"+ "}
                      <FormattedMessage id="addTask" defaultMessage={"Добавить задачу"} />
                    </TextFont>
                    <ArrowSvg type="right" fill={Colors.LIGHT.placeholder.main} width={26} height={26} />
                  </StBilletBtn>
                </RenderWithCondition>
              </>
            </RenderWithCondition>

            {/* Open task detailed page */}
            <RenderWithCondition condition={isDetailedTaskViewModalVisible}>
              <ModalTask
                isVisible={isDetailedTaskViewModalVisible}
                setIsVisible={setIsDetailedTaskViewModalVisible}
                id={currenDetailedTaskId.current}
              />
            </RenderWithCondition>
          </StContentWrapDiv>
        )}
        <RenderWithCondition condition={isModalVisible}>
          <ModalItemAttach
            isVisible={isModalVisible}
            setIsVisible={setIsModalVisible}
            handleAttachTasks={(tasks) => handleAttachTasks(tasks)}
            checkedTaskIds={selectedTaskIds.current}
            onTaskDelete={(id) => onTaskDelete(id)}
            close3DotMenu={close3DotMenu}
          />
        </RenderWithCondition>
      </>
    );
  },
  arePropsEqual,
);

const StButtonAddBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 6px;
`;
const StContentWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const StDropDownDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StBilletBtn = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  background-color: ${Colors.LIGHT.white};
  border-radius: 11px;
  margin-top: 8px;
  width: 100%;
`;
