import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { NotificationUI } from "@components/ui/notification/NotificationUI";
import { TNotificationDurations } from "@interfaces/meet.interface";
import { updatePersonalSettings } from "@store/userSettings/api";
import { ButtonMain, RoundButton } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import {
  IUserNotificationSettings,
  NotificationDurations,
  NotificationPeriod,
  NotificationTypes,
} from "../../../../../../interfaces/userSettings.interface";

import { mapHours, mapMinutes, mapTypes } from "./constants";
import { RadioFormReports } from "./RadioFormReports";

interface IControlBlockReminder {
  isOpen: boolean;
  onCancel?: () => void;
  openNotify?: () => void;
  currentId?: string;
  dataSettings?: IUserNotificationSettings[];
}

export const ControlBlockReminder: FC<IControlBlockReminder> = ({ isOpen, onCancel, currentId, dataSettings, openNotify }) => {
  const [selectedType, setSelectedType] = useState(NotificationTypes.PUSH);
  const [selectedPeriod, setSelectedPeriod] = useState(NotificationPeriod.MINUTES);
  const [selectedDurations, setSelectedDurations] = useState<TNotificationDurations>(NotificationDurations.FIFTEEN_MINUTES);

  const dispatch = useDispatch();

  const activeTab = useMemo(() => selectedPeriod === NotificationPeriod.MINUTES, [selectedPeriod]);
  const currentTimePeriod = useMemo(() => (selectedPeriod === NotificationPeriod.MINUTES ? mapMinutes : mapHours), [selectedPeriod]);
  const startChecked = useMemo(
    () => (selectedPeriod === NotificationPeriod.MINUTES ? NotificationDurations.FIFTEEN_MINUTES : NotificationDurations.ONE_HOUR),
    [selectedPeriod, currentTimePeriod],
  );

  useEffect(() => {
    if (!isOpen) {
      setSelectedType(NotificationTypes.PUSH);
      setSelectedPeriod(NotificationPeriod.MINUTES);
      setSelectedDurations(NotificationDurations.FIFTEEN_MINUTES);
    }
  }, [isOpen]);

  function handleChangeType(e: ChangeEvent<HTMLInputElement>) {
    setSelectedType(e.target.value as NotificationTypes);
  }
  function handleChangePeriod(value?: string) {
    setSelectedPeriod(value ? NotificationPeriod.MINUTES : NotificationPeriod.HOURS);
    setSelectedDurations(value ? NotificationDurations.FIFTEEN_MINUTES : NotificationDurations.ONE_HOUR);
  }
  function handleChangeDurations(e: ChangeEvent<HTMLInputElement>) {
    setSelectedDurations(e.target.value as TNotificationDurations);
  }
  function handleOnSave() {
    const newReminder = [
      {
        notificationType: NotificationTypes.PUSH,
        sendingTimes: selectedType === NotificationTypes.PUSH ? [selectedDurations] : [],
      },
      { notificationType: NotificationTypes.WEB_SOCKET, sendingTimes: selectedType === NotificationTypes.PUSH ? [selectedDurations] : [] },
      {
        notificationType: NotificationTypes.EMAIL,
        sendingTimes: selectedType === NotificationTypes.EMAIL ? [selectedDurations] : [],
      },
    ].filter((item) => item.sendingTimes?.length !== 0);

    const isDuplicate = dataSettings?.some(
      (item) => item.notificationType === newReminder[0].notificationType && item.sendingTimes.includes(newReminder[0].sendingTimes[0]),
    );
    if (isDuplicate) {
      setTimeout(() => {
        onCancel();
      }, 0);
      openNotify();
      return;
    }
    const requestData = Object.values(
      [...dataSettings.filter((item) => item.sendingTimes?.length), ...newReminder].reduce((acc, { notificationType, sendingTimes }) => {
        if (!acc[notificationType]) {
          acc[notificationType] = { notificationType, sendingTimes: [] };
        }
        acc[notificationType].sendingTimes.push(...sendingTimes);
        return acc;
      }, {}),
    );
    dispatch(updatePersonalSettings({ id: currentId, body: { reminderNotificationSendingTimeSettings: requestData } }));
    onCancel();
  }

  return (
    <NotificationUI isVisible={isOpen} onCancel={onCancel} onClose={onCancel} style={styleModal}>
      <StContentWrapDiv>
        <RoundButton onClick={onCancel} right={12} top={12}>
          <SvgClose />
        </RoundButton>
        <StBlockDiv>
          <TextFont size={18} weight="700" lineHeight={24}>
            <FormattedMessage id={"when"} />
          </TextFont>
          <StLineWrapDiv>
            <StTabWrapDiv onClick={() => !activeTab && handleChangePeriod("minutes")} active={activeTab}>
              <TextFont size="14px" lineHeight={14} color={activeTab ? Colors.LIGHT.white : Colors.LIGHT.text.main}>
                <FormattedMessage id="minutesSubTitle" />
              </TextFont>
            </StTabWrapDiv>
            <StTabWrapDiv onClick={() => activeTab && handleChangePeriod()} right={true} active={!activeTab}>
              <TextFont size="14px" lineHeight={14} color={!activeTab ? Colors.LIGHT.white : Colors.LIGHT.text.main}>
                <FormattedMessage id="hoursSubTitle" />
              </TextFont>
            </StTabWrapDiv>
          </StLineWrapDiv>
          <StDropFieldDiv>
            <RadioFormReports
              options={currentTimePeriod}
              startChecked={startChecked}
              selectedValue={selectedPeriod}
              handleChange={handleChangeDurations}
              style={{ height: 240 }}
            />
          </StDropFieldDiv>
        </StBlockDiv>
        <StBlockDiv>
          <TextFont size={18} weight="700" lineHeight={24}>
            <FormattedMessage id={"how"} />
          </TextFont>
          <RadioFormReports options={mapTypes} startChecked={selectedType} selectedValue={selectedType} handleChange={handleChangeType} />
        </StBlockDiv>
        <StBlockDiv>
          <StButtonMainCustom onClick={handleOnSave}>
            <TextFont size={18} weight="700" lineHeight={22}>
              <FormattedMessage id={"ready"} />
            </TextFont>
          </StButtonMainCustom>
        </StBlockDiv>
      </StContentWrapDiv>
    </NotificationUI>
  );
};

const StContentWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${() => Colors.LIGHT.background.main};
  border-radius: 24px;
  padding: 30px;
`;
const StBlockDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const StButtonMainCustom = styled(ButtonMain)`
  background-color: ${Colors.LIGHT.white};
  align-self: flex-end;
  border: 1px solid transparent;
  transition: all 0.5s;
  &:hover {
    border: 1px solid ${Colors.LIGHT.placeholder.main};
    transition: all 0.5s;
  }
  &:active {
    background-color: ${Colors.LIGHT.grey400};
    transition: all 0.5s;
  }
`;
const StLineWrapDiv = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
`;
const StTabWrapDiv = styled.div<{ active: boolean; right?: boolean }>`
  display: flex;
  gap: 9px;
  padding: 8px 30px;
  border: 1px solid ${Colors.LIGHT.text.accent};
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.LIGHT.white};
  transition: all 0.3s ease;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  ${({ right }) =>
    right &&
    `
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  `}
  &:hover {
    background-color: ${Colors.LIGHT.lighGrey};
  }
  &:active {
    background-color: ${Colors.LIGHT.grey};
  }
  ${({ active }) =>
    active &&
    `
    background-color: ${Colors.LIGHT.text.accent};
    transition: all 0.3s ease;
    &:hover {
    background-color: ${Colors.LIGHT.text.accent};
  }
  `}
`;
const StDropFieldDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  position: relative;
  width: 100%;
`;
const styleModal = {
  width: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
