/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";

import { isArray, isEqual, omit } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgSearch } from "@assets/search.svg";
import { LoaderRenderWithCondition, ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { Modal as ModalTask } from "@screens/day/components/ListTasks";
import { Modal } from "@screens/day/components/ListTasks/Modal/Modal";
import { Item } from "@screens/day/components/ListTasks/taskItem/Item";
import { NotFound } from "@screens/task/components/notFound";
import { useGetAllTaskListQuery } from "@services/taskApi";
import { removeSelectedItemId } from "@store/attachedMeetingTasks/slice";
import { selectBusinessTasks, dropFilter, setFilter } from "@store/businessTask";
import { TaskSubtypes, defaultFilter } from "@store/businessTask/constants";
import { sortingAllTasks } from "@store/businessTask/utils";
import { ButtonDrop, InputSearch, SearchWrap } from "@styles/styles";
import { Colors } from "@theme/colors";
import { EmptyBusinessTasks } from "@ui";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";
import { TextFont } from "@ui/TextFont";
import EventHelperUtils from "@utils/event-helper.utills";
import { showToast } from "@utils/toast";

const eventHelperUtils = new EventHelperUtils();

interface IModalTag {
  isVisible: boolean;
  checkedTaskIds: string[];
  setIsVisible: (arg: boolean) => void;
  onTaskDelete: (id: string) => void;
  handleAttachTasks: (tasks: ITask[]) => void;
  close3DotMenu?: () => void;
}

export const ModalItemAttach = ({ isVisible, checkedTaskIds, onTaskDelete, setIsVisible, handleAttachTasks, close3DotMenu }: IModalTag) => {
  const dispatch = useDispatch();
  const newlySelectedTasksIds = useRef<string[]>([]);
  const newlySelectedTasks = useRef<ITask[]>([]);
  const currenDetailedTaskId = useRef("");

  const [isDetailedTaskViewModalVisible, setIsDetailedTaskViewModalVisible] = useState(false);
  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false);
  const [search, setSearch] = useState("");

  const {
    filter: { name, groupByType },
    filter,
  } = useSelector(selectBusinessTasks);

  const { data: response, isFetching } = useGetAllTaskListQuery({ filter });
  const page = response?.page ?? { content: [], total: 0 };

  useEffect(() => {
    dispatch(
      setFilter({
        ...filter,
        status: "OPEN",
        pageable: {
          next: 20,
          current: 0,
          total: filter.pageable.total,
        },
      }),
    );
  }, []);

  const handleResetFilter = async () => {
    setSearch("");
    dispatch(dropFilter({ name, groupByType }));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearch(value);
    eventHelperUtils.debounce(
      () =>
        dispatch(
          setFilter({
            ...filter,
            status: "OPEN",
            name: value,
            pageable: {
              next: 20,
              current: 0,
              total: filter.pageable.total,
            },
          }),
        ),
      1000,
    );
  };

  const loadMoreData = async () => {
    if (page.content.length >= page.total || isFetching) return;

    try {
      dispatch(
        setFilter({
          ...filter,
          status: "OPEN",
          pageable: {
            next: 20,
            current: page.content.length,
            total: filter.pageable.total,
          },
        }),
      );
    } catch (error) {
      return showToast("dataDidNotLoaded", "error");
    }
  };

  const handleSelectedTasks = useCallback(
    (task: ITask) => {
      const newlySelectedTasksIds = newlySelectedTasks.current?.map((item) => item.id) ?? [];

      if (newlySelectedTasksIds.includes(task.id)) {
        const resultTasks = [];

        for (const item of newlySelectedTasks.current) {
          if (item.id !== task.id) {
            resultTasks.push(item);
          }
        }

        newlySelectedTasks.current = resultTasks;

        return;
      }

      if (checkedTaskIds.includes(task.id)) {
        onTaskDelete(task.id);
        return;
      }

      if (!newlySelectedTasksIds.includes(task.id) && !checkedTaskIds.includes(task.id)) {
        newlySelectedTasks.current = [...newlySelectedTasks.current, task];
      }
    },
    [newlySelectedTasksIds, checkedTaskIds],
  );

  const onAddHandler = () => {
    handleAttachTasks(newlySelectedTasks.current);
    setIsVisible(false);
    close3DotMenu?.();
  };

  const checkIsChecked = (id: string) => (checkedTaskIds ? checkedTaskIds.includes(id) : false);

  const clearNewlySelectedItems = () => {
    const newlySelectedTasksIds = newlySelectedTasks.current?.map((item) => item.id) ?? [];

    for (const id of newlySelectedTasksIds) {
      dispatch(removeSelectedItemId(id));
    }
  };

  const handleItemPress = (id: string) => {
    setIsDetailedTaskViewModalVisible(true);
    currenDetailedTaskId.current = id;
  };

  const handleClose = () => {
    clearNewlySelectedItems();
    setIsVisible(false);
    close3DotMenu?.();
  };

  const listTasks = useMemo(() => page.content.filter((task) => task.subType === TaskSubtypes.Business), [page.content]);
  const groupedTasks = useMemo(() => sortingAllTasks(groupByType, page.content), [groupByType, page.content]);
  const current = useMemo(() => listTasks.length, [listTasks.length]);
  const isDefaultFilter = useMemo(() => isEqual(omit(filter, ["pageable"]), omit(defaultFilter, ["pageable"])), [filter]);

  const phSearch = useIntl().formatMessage({ id: "search" });

  const renderFooter = (
    <div>
      {current < page.total && !isFetching && <StShowBtn onClick={loadMoreData}>Показать еще</StShowBtn>}
      <>
        {isFetching ? (
          <>
            <StLoaderWrapDiv>
              <ReactLoading type="spokes" color={Colors.LIGHT.green} height={25} width={25} />
            </StLoaderWrapDiv>
          </>
        ) : (
          <div />
        )}
      </>
    </div>
  );

  const renderItem = (index: number, item: ITask, isLastItem: boolean) => (
    <>
      <Item
        key={item.id}
        index={index}
        item={item}
        press={() => handleItemPress(item.id)}
        checkbox
        meetingTaskStyles
        handleCheckedTask={handleSelectedTasks}
        isChecked={checkIsChecked(item.id)}
        onRightClick={() => {}}
      />
      {isLastItem && renderFooter}
    </>
  );

  const renderTasks = useMemo(() => {
    const isEmptyArray = !listTasks.length;

    if (isEmptyArray && isDefaultFilter) {
      return (
        <EmptyBusinessTasks
          press={() => {
            setIsAddTaskModalVisible(true);
          }}
        />
      );
    } else if (isEmptyArray) {
      return (
        <NotFound
          handleResetFilter={handleResetFilter}
          disableButton
          titleTextId="taskNotFoundInfo"
          descriptionTextId="taskNotFoundDescriptionInfo"
        />
      );
    } else if (isArray(groupedTasks)) {
      const renderList = groupedTasks.map((item, index) => {
        const isLastItem = groupedTasks.length - 1 === index;
        return renderItem(index, item, isLastItem);
      });

      return renderList;
    }
  }, [groupedTasks, listTasks, isDefaultFilter]);

  return (
    <ModalUI isVisible={isVisible} onClose={handleClose} scrollEnable={false}>
      <HeaderModal
        title="addTask"
        leftSide={{ onPressClose: handleClose, onPressCancel: handleClose }}
        rightSide={{ onPress: onAddHandler, title: "add" }}
        isEdit={true}
      />
      <StContentWrapDiv>
        <StBlockSearchDiv>
          <SearchWrap>
            <SvgSearch />
            <InputSearch value={search} onChange={handleSearch} placeholder={phSearch} maxLength={255} />
          </SearchWrap>
          <RenderWithCondition condition={Boolean(search)}>
            <ButtonDrop onClick={handleResetFilter}>
              <TextFont size={16} color={Colors.LIGHT.text.accent}>
                <FormattedMessage id="drop" />
              </TextFont>
            </ButtonDrop>
          </RenderWithCondition>
        </StBlockSearchDiv>

        <button
          onClick={() => {
            setIsAddTaskModalVisible(true);
          }}
        >
          <StTextCreateTask size={18} color={Colors.LIGHT.text.grey}>
            <FormattedMessage id="createTask" />
          </StTextCreateTask>
        </button>

        <StBlockTasksDiv>
          <LoaderRenderWithCondition condition={isFetching && filter.pageable.current === 0}>
            <StListTasksDiv isTypeNone={groupByType === "NONE"}>{renderTasks}</StListTasksDiv>
          </LoaderRenderWithCondition>
        </StBlockTasksDiv>
      </StContentWrapDiv>

      <ModalRenderWithCondition condition={isAddTaskModalVisible}>
        <Modal isVisible={isAddTaskModalVisible} setIsVisible={setIsAddTaskModalVisible} id={""} disablePersonalTasks />
      </ModalRenderWithCondition>

      {/* Open task detailed page */}
      <RenderWithCondition condition={isDetailedTaskViewModalVisible}>
        <ModalTask
          isVisible={isDetailedTaskViewModalVisible}
          setIsVisible={setIsDetailedTaskViewModalVisible}
          id={currenDetailedTaskId.current}
        />
      </RenderWithCondition>
    </ModalUI>
  );
};

const StContentWrapDiv = styled.div`
  padding: 20px 15px 0 9px;
  overflow-y: auto;
  height: 92%;
`;
const StBlockSearchDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StShowBtn = styled.button`
  color: ${Colors.LIGHT.text.grey};
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;
`;
const StBlockTasksDiv = styled.div`
  height: 100%;
  background-color: ${Colors.LIGHT.background.main};
  margin-top: 10px;
`;
const StLoaderWrapDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  position: absolute;
`;
const StListTasksDiv = styled.div<{ isTypeNone: boolean }>`
  padding-bottom: 100px;
  height: ${(props) => (props.isTypeNone ? "93%" : "100%")};
`;
const StTextCreateTask = styled(TextFont)`
  margin-top: 20px;
`;
