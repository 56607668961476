import React from "react";

import { Dayjs } from "dayjs";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { Calendar } from "@shared/planningWork";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

interface IProps {
  dateKey: string;
  closeEvent: () => void;
  handleDate: (key: string, value: Dayjs) => void;
  currentDay: string;
}

export const DateTab = ({ dateKey, closeEvent, handleDate, currentDay }: IProps) => (
  <>
    <StButtonItemBtn onClick={closeEvent}>
      <TextFont size={16} color={Colors.LIGHT.text.accent}>
        <FormattedMessage id="close" />
      </TextFont>
    </StButtonItemBtn>
    <StCalendarWrapDiv>
      <Calendar
        press={(value) => handleDate(dateKey, value)}
        currentDay={currentDay}
        startDay={""}
        disable={{ style: false, button: false }}
      />
    </StCalendarWrapDiv>
  </>
);

const StButtonItemBtn = styled.button`
  margin-left: 12px;
  margin-top: 12px;
`;
const StCalendarWrapDiv = styled.div`
  padding: 20px 12px;
`;
