import { createApi } from "@reduxjs/toolkit/query/react";

import { BASE_URL, http } from "@api/httpClient";

export enum StoreTagTypes {
  Calendar = "Calendar",
  Users = "Users",
  ExternalUser = "ExternalUser",
  MixedUsers = "MixedUsers",
  PastMeets = "PastMeets",
  UnansweredMeets = "UnansweredMeets",
  Meet = "Meet",
  Task = "Task",
}
const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async (args: {
    url: string;
    method: "get" | "post" | "put" | "delete" | "patch";
    /* eslint-disable @typescript-eslint/no-explicit-any */
    data?: any;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    params?: any;
    id?: string;
    meta?: Record<string, string>;
  }) => {
    try {
      const result = await http({
        url: baseUrl + args.url,
        method: args.method,
        data: args.data,
        params: args.params,
      });
      return { data: result.data, meta: args.meta };
    } catch (axiosError) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const api = createApi({
  reducerPath: "rtkqSplitApi",
  baseQuery: axiosBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: Object.keys(StoreTagTypes),
  endpoints: () => ({}),
});
