import React, { FC } from "react";

import styled from "styled-components";

import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import { ITab } from "./types";

interface IProps extends ITab {
  width?: string;
}

export const Tab: FC<Omit<IProps, "id">> = ({ title, onPress, isActive, width = "50%" }) => (
  <StButtonTabBtn onClick={onPress} width={width} isActive={isActive}>
    <StTextTabTF type="bold" size={12} weight={isActive ? "700" : "400"}>
      {title}
    </StTextTabTF>
  </StButtonTabBtn>
);

const StButtonTabBtn = styled.button<{ width: string; isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 28px;
  border-radius: 8px;
  border: 1px solid ${Colors.LIGHT.black100};
  width: ${({ width }) => width};

  ${({ isActive }) =>
    isActive &&
    `
    border: 1px solid ${Colors.LIGHT.black100};
    background-color: ${Colors.LIGHT.white};
    box-shadow: 1px 1px 3px ${Colors.LIGHT.black400};
  `}
`;
const StTextTabTF = styled(TextFont)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
