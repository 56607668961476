import React, { Dispatch, SetStateAction, useEffect, useCallback, useMemo, useState, useRef } from "react";

import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { ITask, WorkDay } from "@interfaces/businessGoals.interface";
import { WorkDays } from "@screens/day/components/ListTasks/Modal";
import { AddWorkDaysReminder } from "@screens/day/components/ListTasks/Modal/components/addWorkDaysReminder/AddWorkDaysReminder";
import { useUpdateTaskMutation, useDeleteWorkDayTaskMutation } from "@services/taskApi";
import { PlanningWork } from "@shared";
import { saveDate } from "@store/screenDay";
import { showToast } from "@utils/toast";

import ActionsSheetModeToDate from "../ActionsSheetModeToDate";
import useGoToSpecificDays from "../hooks/useGoToSpecificDays";
import { tempWorkDay } from "../ViewNotSave/constants";
import { getWorkDayActionSheetStyles } from "../viewSave/utils";

export type THandleOpenWorkDay = (day: string, hasTimeBeenSet?: boolean) => void;

interface IWorkDaysController {
  task: ITask;
  isPersonalTask: boolean;
  isAddWorkDayReminderVisible: boolean;
  allowWorkdaysAddition?: boolean;
  parentWidth?: number;
  isModalView?: boolean;
  setIsWorkDayReminderVisible: Dispatch<SetStateAction<boolean>>;
  handleClose?: () => void;
  outModalClose?: () => void;
  onRemindLater?: () => void;
}

export const useWorkDaysController = ({
  task,
  isAddWorkDayReminderVisible,
  isPersonalTask,
  setIsWorkDayReminderVisible,
  allowWorkdaysAddition = true,
  parentWidth = 500,
  isModalView = false,
  handleClose,
  outModalClose,
  onRemindLater,
}: IWorkDaysController) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const savedDayAfterClickTheTimeButton = useRef<string>();

  const [isVisibleAddDay, setIsVisibleAddDay] = useState(false);
  const [workDays, setWorkDays] = useState(task?.workDays ?? []);
  const [isVisibleDayActionsModal, setIsVisibleDayActionsModal] = useState(false);

  const [update] = useUpdateTaskMutation();
  const [deleteWorkDay] = useDeleteWorkDayTaskMutation();

  useEffect(() => {
    setWorkDays(task.workDays);
  }, [task]);

  const handleSaveAddDays = (days: WorkDay[], timeEnabled?: boolean) => {
    update({ id: task.id, isPersonalTask, data: { workDays: days, timeEnabled: !!timeEnabled } });
  };

  const goToSpecificDay = useGoToSpecificDays({
    handleClose: handleClose,
    callback: () => {
      if (outModalClose) outModalClose();
    },
  });

  const handleRemoveWorkDay = (date: string) => {
    deleteWorkDay({ id: task.id, isPersonalTask, date, skippedDate: task.skippedDate });
    setWorkDays(workDays.filter((day) => day.date !== date));
  };

  const openAddDays = () => {
    setIsVisibleDayActionsModal(false);
    setIsVisibleAddDay(true);
    setIsWorkDayReminderVisible(false);
  };

  const handleOpenWorkDay = (day: string, hasTimeBeenSet?: boolean) => {
    savedDayAfterClickTheTimeButton.current = day;

    if (hasTimeBeenSet) {
      setIsVisibleDayActionsModal(true);
      return;
    }

    navigate("/");

    dispatch(saveDate(dayjs(day)));

    handleClose && handleClose();
    outModalClose && outModalClose();
  };

  const onPressDisableWorkDay = useCallback(() => {
    showToast("actionUnavailableCompletedTask");
  }, []);

  const isEdit = useMemo(() => task?.status !== "COMPLETED" && task?.status !== "CLOSED", [task?.status]);

  const WorkDaysComponent = (
    <>
      <RenderWithCondition condition={task}>
        <WorkDays
          controlTask={Boolean(task?.controlledTask)}
          workDays={task.workDays}
          press={handleOpenWorkDay}
          remove={handleRemoveWorkDay}
          onAddDays={() => setIsVisibleAddDay(true)}
          disable={{ value: !isEdit, press: onPressDisableWorkDay }}
          allowWorkdaysAddition={allowWorkdaysAddition}
          handleClose={() => undefined}
          task={task}
        />
      </RenderWithCondition>
    </>
  );

  const actionSheetContainerStyles = useMemo(() => getWorkDayActionSheetStyles(parentWidth, isModalView), [parentWidth, isModalView]);

  const WorkDaysModals = (
    <>
      <ModalRenderWithCondition condition={isVisibleAddDay && Boolean(task)}>
        <PlanningWork
          isVisible={isVisibleAddDay}
          setIsVisible={setIsVisibleAddDay}
          isTime={Boolean(task?.timeEnabled)}
          startDate={task?.startDate}
          startDays={task?.workDays}
          tempObject={tempWorkDay}
          onSave={handleSaveAddDays}
          keyTitle="workDay"
          deadline={task?.plannedEndDate}
          disable={{}}
          timeEnabled={task.timeEnabled}
          onRemindLater={onRemindLater}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isAddWorkDayReminderVisible && Boolean(task)}>
        <AddWorkDaysReminder
          setIsVisible={setIsWorkDayReminderVisible}
          isVisible={isAddWorkDayReminderVisible}
          setIsVisibleAddDay={setIsVisibleAddDay}
          onRemindLater={onRemindLater}
        />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={isVisibleDayActionsModal}>
        <ActionsSheetModeToDate
          onClose={() => {
            setIsVisibleDayActionsModal(false);
          }}
          goToSpecificDay={() => {
            goToSpecificDay(savedDayAfterClickTheTimeButton.current!);
          }}
          openAddDays={openAddDays}
          containerStyle={actionSheetContainerStyles}
        />
      </ModalRenderWithCondition>
    </>
  );

  return [WorkDaysModals, WorkDaysComponent];
};
