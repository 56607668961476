import React from "react";

import styled from "styled-components";

import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { RenderWithCondition } from "@hoc";
import { Projects } from "@shared/projects";
import { Colors } from "@theme/colors";
import { TextFont, ModalUI } from "@ui";
import { HeaderModal } from "@ui/headerModal/HeaderModal";

interface IModalProject {
  isVisible: boolean;
  close: () => void;
  projectName?: string;
  handleProject: (name: string, value: any, text?: string) => void;
  isFilter?: boolean;
  title?: string;
  handleProjectName?: (name: string | null) => void;
}

export const ModalProject = ({
  isVisible,
  close,
  handleProject,
  isFilter = false,
  projectName,
  title = "projects",
  handleProjectName,
}: IModalProject) => {
  const selectProject = (key: string, value: string, text = "") => {
    handleProject(key, value);
    if (handleProjectName) {
      handleProjectName(text);
    }

    close();
  };

  const deleteProject = () => {
    if (isFilter) {
      handleProject("projectGroupId", null, "");
      handleProject("projectId", null, "");
    } else {
      handleProject("projectId", null, "");
    }

    if (handleProjectName) {
      handleProjectName(null);
    }
  };

  return (
    <ModalUI scrollEnable={false} isVisible={isVisible} onClose={close}>
      <HeaderModal title={title} leftSide={{ onPressClose: close }} />
      <RenderWithCondition condition={projectName}>
        <StContentWrapDiv>
          <StContentDiv>
            <TextFont>{projectName}</TextFont>

            <button onClick={deleteProject}>
              <SvgRemove fill={Colors.LIGHT.placeholder.main} />
            </button>
          </StContentDiv>
        </StContentWrapDiv>
      </RenderWithCondition>
      <StProjectsCustom handleProject={selectProject} isFilter={isFilter} />
    </ModalUI>
  );
};

const StContentWrapDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: ${Colors.LIGHT.background.darkGreen};
  padding: 11px 12px 11px 20px;
`;
const StContentDiv = styled.div`
  display: flex;
  align-items: center;
  border-radius: 45px;
  background-color: ${Colors.LIGHT.white};
  padding: 3px 9px;
  height: 28px;
`;
const StProjectsCustom = styled(Projects)`
  padding-bottom: 20px;
`;
