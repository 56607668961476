import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as CloseRed } from "@assets/close-red.svg";
import { ReactComponent as SvgComplete } from "@assets/complete.svg";
import { RenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { oneSTaskStatusBadges } from "@screens/day/components/ListTasks/Modal/components/viewSave/components/oneSStatusBadge/constants";
import { Colors } from "@theme/colors";
import { AssistantToast, TextFont } from "@ui";

import { CheckCode } from "../../../../../../../auth/CheckCode";
import { titleReason } from "../assets/constants";
import { ReactComponent as SvgPause } from "../assets/pause_status.svg";

interface IProps {
  task: ITask;
}

export const ViewSaveStatus: FC<IProps> = ({ task }) => {
  const renderElements = (): { element: JSX.Element | null; comment?: JSX.Element | null } => {
    if (task.isFromOneS && task?.oneSTaskResolution && task.status !== "CLOSED") {
      const oneSBadge = oneSTaskStatusBadges[task.oneSTaskResolution];

      if (!oneSBadge) return { element: null };

      return {
        element: (
          <StLineStatusOneSDiv>
            <StStatusWrapDiv>
              <StIconWrapDiv>{oneSBadge?.icon}</StIconWrapDiv>
              <TextFont size={14} color={Colors.LIGHT.green}>
                <FormattedMessage id={oneSBadge.textId} defaultMessage={oneSBadge.defaultMessage} />
              </TextFont>
            </StStatusWrapDiv>
            {task.assistant && <AssistantToast assistant={task.assistant} view="svgInBillet" />}
          </StLineStatusOneSDiv>
        ),
      };
    }
    switch (task.status) {
      case "CLOSED":
        return {
          element: (
            <RenderWithCondition condition={task.closeReason || task.isFromOneS}>
              <StStatusWrapDiv>
                <CloseRed width={17} height={17} />
                <TextFont size={14} color={Colors.LIGHT.red}>
                  <FormattedMessage id={task.closeReason ? titleReason[task?.closeReason] : "closedTask"} />
                </TextFont>
              </StStatusWrapDiv>
            </RenderWithCondition>
          ),
          comment: (
            <RenderWithCondition condition={!!task?.closeComment}>
              <StTextCommentTF size={18} color={Colors.LIGHT.white}>
                {task.closeComment}
              </StTextCommentTF>
            </RenderWithCondition>
          ),
        };
      case "COMPLETED":
        return {
          element: (
            <StStatusWrapDiv>
              <SvgComplete width={17} height={17} />
              <TextFont size={14} color={Colors.LIGHT.green}>
                <FormattedMessage id={"readyTask"} />
              </TextFont>
            </StStatusWrapDiv>
          ),
        };
      case "PAUSED":
        return {
          element: (
            <StStatusWrapDiv>
              <SvgPause width={17} height={17} />
              <TextFont size={14} color={Colors.LIGHT.grey500}>
                <FormattedMessage id={"suspendTask"} />
              </TextFont>
            </StStatusWrapDiv>
          ),
          comment: (
            <RenderWithCondition condition={!!task?.pauseComment}>
              <StTextCommentTF size={18} color={Colors.LIGHT.white}>
                {task?.pauseComment}
              </StTextCommentTF>
            </RenderWithCondition>
          ),
        };

      default:
        return { element: <StStatusDefaultDiv /> };
    }
  };

  const { element, comment } = renderElements();

  return (
    <StContentWrapDiv>
      {element}
      {task.assistant && task.createdByAssistant && !task.isFromOneS && <AssistantToast assistant={task.assistant} view="billet" />}
      {comment}
    </StContentWrapDiv>
  );
};

const StStatusWrapDiv = styled.div`
  display: flex;
  justify-content: space-between;
  height: 24px;
  padding: 0px 9px;
  align-items: center;
  align-self: flex-start;
  background-color: ${Colors.LIGHT.white};
  margin-right: 10px;
  border-radius: 55px;
  gap: 5px;
`;
const StStatusDefaultDiv = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;
const StIconWrapDiv = styled.div`
  width: 17px;
  height: 17px;
  margin-right: 5px;
`;
const StLineStatusOneSDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
const StTextCommentTF = styled(TextFont)`
  line-height: 24px;
  margin-bottom: 10px;
`;
const StContentWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
