import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgPlus } from "@assets/plus.svg";
import { IUnitedGroupItem } from "@components/screens/profile/components/groups/Groups";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { InterfaceUser } from "@interfaces";
import { IParticipantAttendance } from "@interfaces/eventsOfDay.interface";
import { IMeet, IMeetParticipants, TTypeParticipants, IAddNewParticipants } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { ParticipantsListItem } from "@screens/day/components/timetable/main/modal/components/participants/components";
import { UserInformation } from "@screens/profile/components";
import { IExternalUser } from "@services/meet.service";
import { useAddParticipantsToSingleOrSerieMeetingMutation, useUpdateSingleOrSerieMeetingMutation } from "@services/meetApi";
import { useCreateExternalUsersByEmailListMutation } from "@services/userApi";
import { selectCurrentDate } from "@store/screenDay";
import { selectTheme } from "@store/theme";
import { selectUserForAssistant, userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { Billet, ModalUI, TextFont } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { arePropsEqual } from "@utils/arePropsEqual";
import { isExternalUser } from "@utils/userTypeChecks";

import { useRoleModel } from "../accessRights/useRoleModel";
import { notifyParticipantsTextIdOptions } from "../constants";
import { TStringUnknownTupleArray } from "../utils";

import { Filters } from "./components/Filters";
import { ModalList } from "./components/ModalList";
import { ChangeEvents, firstNext, next } from "./constants";
import { CopyParticipantsEmails } from "./CopyParticipantsEmails";
import { ParticipantsList } from "./participantsList/ParticipantsList";
import { useData } from "./useData";

interface IProps {
  handleData?: (name: string | TStringUnknownTupleArray, value: unknown) => void;
  data: IMeet;
  isSave: boolean;
  currentUserAttendanceStatus: TTypeParticipants | null;
  meetChangeMode?: string;
  onPressAdd?: () => void;
  participantsAttendance: IParticipantAttendance[] | null;
  isExistMeet?: boolean;
  isEdit?: boolean;
  setRefetchOnClose?: React.Dispatch<React.SetStateAction<boolean>>;
  allowDelete: boolean;
  allowAdd: boolean;
}

export const Participants: FC<IProps> = memo(function Participants({
  handleData,
  currentUserAttendanceStatus,
  data,
  onPressAdd,
  isSave,
  participantsAttendance,
  isExistMeet = false,
  isEdit,
  setRefetchOnClose,
  allowAdd,
  allowDelete,
}) {
  const theme = selectTheme("extra");
  const isGroupAllSaved = data?.presetGroups?.length && data?.presetGroups[0]?.type === "ALL_IN_SPACE";

  const { filter, setFilter, allParticipants, resultParticipants } = useData({
    participants: data.participants ?? [],
    externalUsers:
      isGroupAllSaved && !isSave && data?.presetGroups[0]?.name
        ? // @ts-ignore
          [{ ...data, type: "ALL_IN_SPACE", name: data?.presetGroups[0]?.name }, ...(data.externalUsers ?? [])] ?? []
        : data.externalUsers ?? [],
  });

  const changeEvent = useRef<ChangeEvents>();
  const updationBody = useRef<object>();
  const meetChangeMode = useRef<"serie" | "event">();

  const [isVisible, setIsVisible] = useState(false);
  const [selectUser, setSelectUser] = useState<InterfaceUser.IStateUser | undefined>(undefined);
  const [isVisibleList, setIsVisibleList] = useState(false);
  const [isChangeModeSelectorVisible, setIsChangeModeSelectorVisible] = useState(false);
  const [isVisibleNotificationOptions, setIsVisibleNotificationOptions] = useState(false);
  const [page, setPage] = useState(1);

  const currentUser = useSelector(selectUserForAssistant);
  const { directorId } = userSelector();
  const selectedDay = useSelector(selectCurrentDate);
  const { isUserHasEditPermissions } = useRoleModel(data);

  const [updateSingleMeetOrSerie] = useUpdateSingleOrSerieMeetingMutation();
  const [addParticipantsToSingleOrSerieMeeting] = useAddParticipantsToSingleOrSerieMeetingMutation();
  const [createExternalUsersByEmailList] = useCreateExternalUsersByEmailListMutation();

  const handleDeleteItem = (id: string, isExternalUser: boolean, isGroupAll?: boolean) => {
    if (isSave && isUserHasEditPermissions) {
      updationBody.current = {
        participants: data?.participants?.filter((item) => item.userId !== id),
        externalUsers: data?.externalUsers?.filter((item) => item.id !== id),
      };
      changeEvent.current = ChangeEvents.Remove;

      if (data.repeat || data.parentEvent) {
        setIsChangeModeSelectorVisible(true);
      }
      if (!data.repeat && !data.parentEvent) {
        return handleSaveChanges();
      }
    }

    if (!id || !data.participants || !handleData) return;

    if (isExternalUser) {
      data.externalUsers &&
        handleData(
          isGroupAll
            ? [
                ["externalUsers", [...data.externalUsers].filter((i) => i.id !== id)],
                ["presetGroups", []],
              ]
            : [["externalUsers", [...data.externalUsers].filter((i) => i.id !== id)]],
          null,
        );

      return;
    }

    handleData(
      "participants",
      [...data.participants].filter((i) => i?.userId !== id),
    );
  };

  const handleAddUser = async (users: (IStateUser | IExternalUser)[] | Partial<IUnitedGroupItem>[], emailList?: string[]) => {
    if (!users) return;

    const externalUsers = [];
    const innerUsers: IMeetParticipants[] = [];
    // @ts-ignore
    const isGroupAll = users[0]?.type === "ALL_IN_SPACE";
    const onlyOwnerUser = data.participants?.filter((user) => user.status === "OWNER");

    for (const user of users) {
      const isInner =
        // @ts-ignore
        user?.roles ||
        // @ts-ignore
        user?.permissions ||
        // @ts-ignore
        user?.spaceId ||
        // @ts-ignore
        user?.user?.spaceId ||
        Object.prototype.hasOwnProperty.call(user, "workPosition");

      // @ts-ignore
      const userId = user?.user?.id ?? user.id;
      const innerUser = data.participants?.filter((i) => i.userId === userId)[0];

      if (isInner && !isGroupAll) {
        innerUsers.push({
          // @ts-ignore
          user: { ...user, workPosition: user?.workPosition ?? null },
          userId,
          isExternalUser: false,
          meetingStatus: null,
          status: innerUser?.status ?? null,
          id: userId,
          statusComment: null,
          statusCommentIsViewed: false,
          permissions: [],
        });
      } else {
        externalUsers.push(user);
      }
    }

    if (emailList && Boolean(emailList.length)) {
      const response = await createExternalUsersByEmailList({ data: { emails: emailList }, userId: directorId });

      if (response && Object.prototype.hasOwnProperty.call(response, "data")) {
        const newlyCreatedExternals = response["data"] ?? [];
        externalUsers.push(...newlyCreatedExternals);
      }
    }

    if (handleData) {
      handleData(
        isGroupAll
          ? [
              ["participants", onlyOwnerUser],
              ["externalUsers", externalUsers],
              ["presetGroups", [{ type: "ALL_IN_SPACE" }]],
              ["repeat", []],
            ]
          : [
              ["participants", innerUsers],
              ["externalUsers", [...externalUsers]],
            ],
        null,
      );
    }

    if (isSave) {
      const isAllowEditMetting = !!data.participants
        ?.filter((item) => item.userId === currentUser?.id)[0]
        ?.permissions.includes("EDIT_MEETING");

      if (data.createdByCurrentUser || isAllowEditMetting) {
        updationBody.current = {
          participants: innerUsers,
          externalUsers,
        };
        changeEvent.current = ChangeEvents.Remove;
      } else {
        updationBody.current = {
          userIds: innerUsers.map((item) => item.userId),
          externalUserIds: externalUsers.map((item) => item.id),
        };
        changeEvent.current = ChangeEvents.Add;
      }

      if (data.repeat || data.parentEvent) {
        setIsChangeModeSelectorVisible(true);
      }

      if (!data.repeat && !data.parentEvent) {
        return handleSaveChanges();
      }
    }
  };

  const handleSaveChanges = async () => {
    if (!data) return;
    if (!updationBody.current) return;
    if (isExistMeet) {
      proceedHandleSaveChanges(true);
      return;
    }

    setIsVisibleNotificationOptions(true);
  };

  const proceedHandleSaveChanges = (shouldNotifyUsers: boolean) => {
    let targetEventId = data.id;

    setIsVisibleNotificationOptions(false);

    if (meetChangeMode.current === "serie" && data?.parentEvent?.id && !data.repeat) {
      targetEventId = data?.parentEvent?.id;
    }

    updationBody.current = { ...updationBody.current, notifyAllParticipants: shouldNotifyUsers };

    switch (changeEvent.current) {
      case ChangeEvents.Add: {
        addParticipantsToSingleOrSerieMeeting({
          id: targetEventId,
          data: updationBody.current as IAddNewParticipants,
          repeat: Boolean(data?.repeat),
          parentEvent: data.parentEvent,
          changeSerie: meetChangeMode.current === "serie",
          date: dayjs(selectedDay).format("YYYY-MM-DD"),
          userId: directorId,
        });

        return;
      }
      case ChangeEvents.Remove: {
        return updateSingleMeetOrSerie({
          id: targetEventId,
          data: updationBody.current,
          date: dayjs(selectedDay).format("YYYY-MM-DD"),
          changeSerie: meetChangeMode.current === "serie",
          repeat: Boolean(data.repeat),
          parentEvent: data.parentEvent,
        });
      }
    }
  };

  const handleUserChoice = (mode: "serie" | "event") => {
    meetChangeMode.current = mode;
    setIsChangeModeSelectorVisible(false);
    handleSaveChanges();
  };

  const getUser = useCallback(
    (userId: string) => {
      const participant = data.participants?.find((i) => i.userId === userId);
      return participant?.user ? participant?.user : (participant as unknown as IStateUser);
    },
    [data.participants],
  );

  const isOnlyOwner = useMemo(
    () => data.participants?.length === 1 && data.participants[0].status === "OWNER" && !data?.externalUsers?.length,
    [data.participants, data.externalUsers],
  );

  const totalPages = useMemo(() => Math.ceil((resultParticipants.length - firstNext) / next) + 1, [resultParticipants.length]);

  const renderItem = (user: IExternalUser | IMeetParticipants, index: number, isModalList?: boolean) => {
    const isExternal = isExternalUser(user);
    const status =
      currentUser?.id === user.userId && currentUserAttendanceStatus ? currentUserAttendanceStatus : (user as IMeetParticipants)?.status;

    return (
      <ParticipantsListItem
        index={index}
        key={user.id}
        user={isExternal ? user : { ...user, status }}
        handleDeleteItem={handleDeleteItem}
        getUser={getUser}
        setSelectUser={setSelectUser}
        isSave={false}
        isExternal={isExternal}
        assistant={isExternal ? undefined : data.assistant}
        allowDelete={allowDelete}
        isModalList={isModalList}
        meet={data}
        participantsAttendance={participantsAttendance}
        isExistMeet={isExistMeet}
      />
    );
  };

  return (
    <section>
      <StHeaderBlockDiv>
        <TextFont size={18} weight="700" type="bold">
          {`${useIntl().formatMessage({ id: "meetingMembers" })}${allParticipants > 1 && !isGroupAllSaved ? ` - ${allParticipants}` : ""}`}
        </TextFont>

        <StIconsWrapDiv>
          <RenderWithCondition condition={!isGroupAllSaved || !isEmpty(data.externalUsers)}>
            <CopyParticipantsEmails dogmaUsers={data?.participants} externalUsers={data?.externalUsers} />
          </RenderWithCondition>
          <RenderWithCondition condition={allowAdd}>
            <button onClick={() => setIsVisible(true)}>
              <SvgPlus width={18} height={18} fill={Colors.LIGHT.background.grey} />
            </button>
          </RenderWithCondition>
        </StIconsWrapDiv>
      </StHeaderBlockDiv>
      {isSave && !isGroupAllSaved && !data.externalId && (
        <Filters
          filter={filter}
          onPress={setFilter}
          participants={[...(data.participants ?? [])]}
          allParticipants={allParticipants}
          externalUsersLength={(data.externalUsers ?? []).length}
          isAuthor={!!data.createdByCurrentUser}
        />
      )}
      {((data.participants?.length || data.externalUsers?.length) && !isOnlyOwner) || isGroupAllSaved || data.externalId ? (
        <StListItemsDiv>
          {resultParticipants.slice(0, page * next - firstNext).map((user, index) => renderItem(user, index))}

          {allParticipants > 50 ? (
            <button onClick={() => setIsVisibleList(true)}>
              <TextFont size={16} color={Colors.LIGHT.grey500}>
                Показать всех
              </TextFont>
            </button>
          ) : (
            resultParticipants.length > 5 &&
            page < totalPages && (
              <button
                onClick={() =>
                  setPage((prev) => {
                    if (prev < totalPages) {
                      return prev + 1;
                    }
                    return totalPages;
                  })
                }
              >
                <TextFont size={16} color={Colors.LIGHT.grey500}>
                  <FormattedMessage id="showMore" />
                </TextFont>
              </button>
            )
          )}
        </StListItemsDiv>
      ) : (
        <Billet
          textId="Добавить участников/занятость"
          additionalText="+ "
          onPress={onPressAdd}
          styleText={{ color: theme.text.accent, fontWeight: "400" }}
        />
      )}
      <ModalRenderWithCondition condition={isVisible}>
        <ParticipantsList
          meet={data}
          onSave={handleAddUser}
          onClose={() => setIsVisible(false)}
          isMeet
          handleData={handleData}
          setShouldResetMeet={setRefetchOnClose}
          isEdit={isEdit}
        />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={Boolean(selectUser)}>
        <ModalUI isVisible={Boolean(selectUser)} onClose={() => setSelectUser(undefined)}>
          <UserInformation user={selectUser} onClose={() => setSelectUser(undefined)} />
        </ModalUI>
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={isChangeModeSelectorVisible}>
        <NotificationConfirm
          phraseId="oneMeetOrAll"
          phraseOkId="allSeries"
          phraseCancelId="oneMeet"
          onOk={() => handleUserChoice("serie")}
          onCancel={() => handleUserChoice("event")}
        />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={isVisibleNotificationOptions}>
        <NotificationConfirm
          phraseId={notifyParticipantsTextIdOptions.titleTextId}
          phraseOkId={notifyParticipantsTextIdOptions.okTextId}
          phraseCancelId={notifyParticipantsTextIdOptions.cancelTextId}
          onOk={() => proceedHandleSaveChanges(true)}
          onCancel={() => proceedHandleSaveChanges(false)}
        />
      </ModalRenderWithCondition>
      <ModalList
        isAuthor={!!data.createdByCurrentUser}
        isVisible={isVisibleList}
        participants={data.participants ?? []}
        externalUsers={data.externalUsers ?? []}
        renderItem={renderItem}
        onClose={() => setIsVisibleList(false)}
      />
    </section>
  );
},
arePropsEqual);

const StHeaderBlockDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 6px;
  margin-bottom: 4px;
`;
const StIconsWrapDiv = styled.div`
  display: flex;
`;
const StListItemsDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  gap: 8px;
`;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
