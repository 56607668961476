import React, { Ref, useCallback, useLayoutEffect, useMemo, useState } from "react";

export type TGradientColor = "GREEN" | "VIOLET" | "BROWN";
interface IProps {
  lineHeight?: number;
  numbersOfLineView?: number;
  ref: Ref<any>;
  gradientColor: TGradientColor;
}

export const useAnimatedText = ({ lineHeight = 24, numbersOfLineView = 3, ref, gradientColor }: IProps) => {
  const [isDropdown, setIsDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    if (ref && typeof ref !== "function" && ref?.current?.offsetHeight > lineHeight * numbersOfLineView) {
      setIsDropdown(true);
      setHeight(ref?.current?.offsetHeight);
    }
  }, [lineHeight, numbersOfLineView, ref]);

  const stylesTransition = useMemo(
    () => ({
      transition: "height 0.4s ease-in-out, box-shadow 0.6s linear",
      overflow: "hidden",
    }),
    [],
  );

  const getGradientValue = useCallback(() => {
    switch (gradientColor) {
      case "GREEN":
        return "linear-gradient(to bottom,  rgba(39, 67, 53, 0.1) 0%,rgba(39, 67, 53, 1) 100%)";
      case "VIOLET":
        return "linear-gradient(to bottom,  rgba(25, 25, 69, 0.1) 0%,rgba(25, 25, 69, 1) 100%)";
      case "BROWN":
        return "linear-gradient(to bottom,  rgba(67, 51, 39, 0.1) 0%,rgba(67, 51, 39, 1) 100%)";
      default:
        return "linear-gradient(to bottom,  rgba(39, 67, 53, 0.1) 0%,rgba(39, 67, 53, 1) 100%)";
    }
  }, [gradientColor]);

  const LinearGradientComponent = useMemo(
    () => <div style={{ ...styles.gradient, position: "absolute", ...{ background: getGradientValue() } }} />,
    [getGradientValue],
  );

  return useMemo(
    () => ({ stylesTransition, height, isDropdown, isOpen, setIsOpen, LinearGradientComponent }),
    [isDropdown, isOpen, LinearGradientComponent, stylesTransition, height],
  );
};

const styles = {
  gradient: {
    bottom: 0,
    left: 0,
    width: "100%",
    height: 50,
  },
};
