import React, { FC, useEffect, useRef, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { FormattedMessage } from "react-intl";
import Toggle from "react-toggle";
import styled from "styled-components";

import { ReactComponent as SvgDeadLine } from "@assets/calendar_red.svg";
import { RenderWithCondition } from "@hoc";
import { Calendar } from "@shared/planningWork";
import { ButtonBillet } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { toFormatDate, toFormatDateRu } from "@utils/toFormatTime";

import { ReactComponent as SvgQuestion } from "../../../../assets/question.svg";
import { CommonFields } from "../types";

interface IProps extends CommonFields {
  date: string;
  startDate: string;
  field: string;
  isVisibleTooltip?: boolean;
  titleId?: string;
  styleContainer?: any;
}

export const Deadline: FC<IProps> = ({
  date,
  startDate,
  handleData,
  field,
  isVisibleTooltip = true,
  titleId = "deadlineDay",
  styleContainer,
}) => {
  const blockRef = useRef(null);

  const [switchDeadLine, setSwitchDeadLine] = useState(Boolean(date));
  const [isTooltip, setIsTooltip] = useState(false);
  const [isVisibleCalendar, setIsVisibleCalendar] = useState(false);

  useEffect(() => {
    if (isVisibleCalendar) {
      blockRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isVisibleCalendar, blockRef]);

  const pressCalendar = (dateWeek: Dayjs) => {
    handleData(field, toFormatDate(dateWeek) === date ? null : toFormatDate(dateWeek));
    setIsVisibleCalendar(false);
  };

  const handleSwitchDeadline = () => {
    if (switchDeadLine) {
      handleData(field, null);
      setIsVisibleCalendar(false);
    } else {
      setIsVisibleCalendar(true);
    }
    setSwitchDeadLine(!switchDeadLine);
  };

  return (
    <StContentWrapSection style={styleContainer} isVisibleCalendar={isVisibleCalendar} ref={blockRef}>
      <StButtonContentBlt isVisibleCalendar={isVisibleCalendar} onClick={handleSwitchDeadline}>
        <StBlockLeftDiv>
          <SvgDeadLine />
          <div>
            <TextFont type="bold" size={16} weight="700">
              <FormattedMessage id={titleId} />
            </TextFont>
            <RenderWithCondition condition={Boolean(date) && switchDeadLine}>
              <TextFont size={12} weight="400" color={Colors.LIGHT.blue}>
                {toFormatDateRu(dayjs(date ?? ""))}
              </TextFont>
            </RenderWithCondition>
          </div>
          <RenderWithCondition condition={isVisibleTooltip}>
            <SvgQuestion onMouseOver={() => setIsTooltip(true)} onMouseOut={() => setIsTooltip(false)} />
          </RenderWithCondition>
        </StBlockLeftDiv>
        <Toggle checked={switchDeadLine} onChange={handleSwitchDeadline} icons={false} />
        <RenderWithCondition condition={isVisibleTooltip && isTooltip}>
          <StTooltipDiv>
            <TextFont size={12}>
              <FormattedMessage id="deadlineWhat" />
            </TextFont>
          </StTooltipDiv>
        </RenderWithCondition>
      </StButtonContentBlt>
      <RenderWithCondition condition={isVisibleCalendar}>
        <Calendar currentDay={date} startDay={startDate} press={pressCalendar} deadline={date} />
      </RenderWithCondition>
    </StContentWrapSection>
  );
};

const StContentWrapSection = styled.section<{ isVisibleCalendar: boolean }>`
  ${({ isVisibleCalendar }) =>
    isVisibleCalendar &&
    `
background-color: ${Colors.LIGHT.white};
border-radius: 11px;
`};
  position: relative;
`;
const StButtonContentBlt = styled(ButtonBillet)<{ isVisibleCalendar: boolean }>`
  margin-top: 0;
  ${({ isVisibleCalendar }) =>
    isVisibleCalendar &&
    `
border-bottom: 1px solid ${Colors.LIGHT.lighGrey};
`};
`;
const StBlockLeftDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const StTooltipDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-self: flex-start;
  background-color: ${Colors.LIGHT.white};
  right: 0;
  bottom: 100%;
  width: 290px;
  border-radius: 10px;
  box-shadow: 0 2px 6px ${Colors.LIGHT.shadowTooltip};
`;
