import React, { useState, useEffect } from "react";

import RecaptchaV2 from "react-google-recaptcha";
import InputMask from "react-input-mask";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

import { NotificationClear } from "@components/ui/notification/NotificationClear";
import { RECAPTCHA_SITE_KEY } from "@constants/settings";
import { LoaderButtonRenderWithCondition } from "@hoc/LoaderButtonRenderWithCondition";
import { useDimension } from "@hooks/useDimension";
import { getCodeApi } from "@store/auth";
import { authSelector } from "@store/auth/selectors";
import { clearError } from "@store/auth/slice";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui/TextFont";
import { myTracker } from "@utils/myTracker";

import backgroundTablet from "./assets/background-tablet.png";
import background from "./assets/background.png";
import logo from "./assets/logo.png";

export const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isTablet } = useDimension();

  const { errorMsg, isLoading } = authSelector();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [isVisibleModalWarning, setIsVisibleModalWarning] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const isDisabledButton = phoneNumber.length < 12 || !isVerified;

  useEffect(() => {
    if (errorMsg === "404") {
      setIsVisibleModalWarning(true);
    }
  }, [errorMsg]);

  const handleChange = (currentText: string) => {
    if (currentText.length < 2) {
      return;
    }
    return setPhoneNumber(currentText);
  };

  const handleAuth = async () => {
    myTracker("LogInClick");

    if (isDisabledButton) return;

    try {
      const response = await dispatch(getCodeApi(phoneNumber));
      if (response?.payload?.phoneNumber && !response?.error) {
        navigate("/checkCode");
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn("handleAuth err", err); // TODO: fixed
    }
  };

  const closeHandlerModalWarning = () => {
    dispatch(clearError());
    setIsVisibleModalWarning(false);
  };

  const onLoginKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      handleAuth();
    }
  };

  const handleRecaptcha = () => {
    setIsVerified(true);
  };

  return (
    <StContentWrapDiv>
      <StContentDiv isLoading={isLoading}>
        <StHeaderDiv>
          <StImage src={logo} />
        </StHeaderDiv>
        <StBlockInputsDiv>
          <StWrapInputDiv>
            <InputMask
              onKeyDown={onLoginKeyDown}
              value={phoneNumber}
              placeholder="+7"
              onChange={(event) => handleChange(event.target.value)}
              mask="+79999999999"
              maskChar=""
              style={stylesInputMask}
            />
            <RecaptchaV2 sitekey={RECAPTCHA_SITE_KEY} onChange={handleRecaptcha} />
            <StButton disabled={isDisabledButton} onClick={handleAuth}>
              <LoaderButtonRenderWithCondition condition={isLoading}>
                <TextFont type="bold" className="buttonText" color="white">
                  <FormattedMessage id="logIn" />
                </TextFont>
              </LoaderButtonRenderWithCondition>
            </StButton>
          </StWrapInputDiv>
        </StBlockInputsDiv>
        <StFooterDiv>
          <StPictureImg src={isTablet ? backgroundTablet : background} />
        </StFooterDiv>

        {isVisibleModalWarning && <NotificationClear onOk={closeHandlerModalWarning} phraseId="authHelp" phraseOkId="ok" />}
      </StContentDiv>
    </StContentWrapDiv>
  );
};

const StContentWrapDiv = styled.div`
  overflow: "hidden";
  height: "100vh";
`;
const StContentDiv = styled.div<{ isLoading: boolean }>`
  height: 100vh;
  display: grid;
  grid-template: 10% 35% 55%/ 1fr;
  background-color: ${() => Colors.LIGHT.background.main};
  pointer-events: ${(props) => (props.isLoading ? "none" : "all")};
  overflow: hidden;
  padding-top: 60px;
`;
const StWrapInputDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const StHeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;
const StImage = styled.img`
  height: 100%;
`;
const StFooterDiv = styled.div`
  height: auto;
  width: 100%;
`;
const StButton = styled.button<{ disabled: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 16px 10px;
  background-color: ${(props) => (props.disabled ? Colors.LIGHT.lighGrey : Colors.LIGHT.text.accent)};
  border-radius: 10px;
  height: 56px;
`;
const StPictureImg = styled.img`
  width: 100%;
  height: 100%;
  background-size: contain;
`;
const StBlockInputsDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const stylesInputMask = {
  paddingInlineEnd: 16,
  paddingInlineStart: 10,
  borderRadius: 10,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: Colors.LIGHT.placeholder.main,
  fontSize: 18,
  color: Colors.LIGHT.black,
  height: 56,
  width: 280,
};
